$mobileLandScapeWidth: 1280px;

.root-class-design20 {
  .top-filter-mobile-dropdown-item {
    &-dark {
      &-text {
        color: white !important;
      }
      color: white !important;
      background: none !important;
      &:hover {
        background: #380000 !important;
      }

      &-ok {
        color: white !important;
        &:hover {
          background: #380000 !important;
        }
      }
    }

    &-normal {
      &-text {
        color: white !important;
      }
      color: white !important;
      background: none !important;
      &:hover {
        color: white !important;
        background: rgba(0,0,0,0.1) !important;
      }

      &-ok {
        color: white !important;
        background: none !important;
        &:hover {
          color: white !important;
          background: rgba(0,0,0,0.1) !important;
        }
      }
    }

    &-light {
      &-text {
        color: black !important;
      }
      color: black !important;
      background: none !important;
      &:hover {
        background: rgba(0,0,0,0.1) !important;
      }

      &-ok {
        color: black !important;
        background: none !important;
        &:hover {
          background: rgba(0,0,0,0.1) !important;
        }
      }
    }

  }

  .layout-mobile-subheader {
    display: none;
  }
  @media screen and (orientation : portrait) {
    .room-ended-modal-new {
      &-wrapper {
        height: calc(100vh - 300px);
        overflow-x: hidden;
        overflow-y: auto;
      }

      &-info-wrapper {
        // width: 100%;
        right: 0px;
        top: 0px;
      }
      &-info-image {
        right: 11px !important;
        left: unset !important;
        top: -37px !important;
        margin: auto !important;
        margin-right: 0px !important;
        // width: 8vw !important;
        // height: 8vw !important;
      }
      &-info-hover {
        right: 8vw !important;
        left: 1vw !important;
        width: auto !important;
        transform: translateY(-82%) !important;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.5), -2px 2px 5px rgba(0,0,0,0.3) !important;
      }

      &-button {
        display: inline-flex !important;
      }
    }

    .top-filter-dropdown {
      width: unset !important;
      top: 50% !important;
      left: 50% !important;

      transform: translate(-50%, -50%) !important;
      position: fixed;
    }

    .player-emotion-left {
      top: 0px;
    }
    .player-emotion-right {
      top: 0px;
    }
    // .player-firstperson-emotion {
    //   top: -195px;
    //   right: 30px !important;
    //   bottom: unset;
    // }

    .icon-popover {
      &-mobile-disable {
        display: none;
      }
    }
    .root-class-design20-fullscreen-menu {
      padding-left: 0;
      padding-right: 0;

      .game-bg {
        background-image: url('../../../images/redesign/mobile/background/game-lobby-dark-bg.jpg');
      }

      .lobby-bg-table {
        background-image: url('../../../images/redesign/mobile/background/game-table-bg.png');
        background-size: contain;
        bottom: 49px;
      }

      .lobby-bg-table+.row {
        margin: 0;

        .menu-background-bg {
          display: none;
        }
      }
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 6px;
      z-index: 10;

      .coin-img {
        display: block !important;
        position: fixed;
        top: 5px;
        left: 5px;
        width: 15px;
        height: 15px;
      }
      .coin-text {
        display: block !important;
        position: fixed;
        top: 5px;
        left: 23px;
        line-height: 15px;

        &-light {
          color: black;
        }
        &-normal {
          color: white;
        }
        &-dark {
          color: white;
        }
      }
      .rating-img {
        display: block !important;
        position: fixed;
        top: 25px;
        left: 5px;
        width: 15px;
        height: 15px;
      }
      .rating-text {
        display: block !important;
        position: fixed;
        top: 25px;
        left: 23px;
        line-height: 15px;

        &-light {
          color: black;
        }
        &-normal {
          color: white;
        }
        &-dark {
          color: white;
        }
      }

      &::before {
        content: '';
        flex: 1;
      }

      .logo-wrapper {
        flex: 1;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 3px;

        a {
          margin-left: 0;
          margin-top: 0;
        }
      }

      .top-settings {
        display: none;
      }

      .mobile-top-setting {
        display: flex !important;
        column-gap: 4px;

        .tutorial-link {
          .tutorial-link-image {
            width: 30px;
            height: 30px;
          }
        }

        .top-game-info {
          cursor: pointer;
          pointer-events: all;
          &-icon {
            width: 32px;
            height: 32px;
            margin-top: 3px;
          }
        }

        .chat-link {
          background-color: rgba($color: #000000, $alpha: 0.7);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .dropdown-menu-link {
          background-color: rgba($color: #000000, $alpha: 0.7);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .layout {
      display: none;
    }

    .layout-mobile {
      width: 100%;
      display: block !important;

      &-subheader {
        display: flex !important;
        position: fixed;
        z-index: 11;
        bottom: 46px;
        left: 10px;
        right: 10px;
        margin: 0 auto;
        background-color: #000;
        border-radius: 8px;
        padding: 9px 10px;
        justify-content: space-between;
        align-items: center;

        .layout-mobile-subheader-navmenu {
          display: flex;
          flex-direction: row;
          overflow-x: auto !important;
          overflow-y: hidden !important;
          justify-content: space-between;
          grid-column-gap: 13px;
          -webkit-column-gap: 13px;
          column-gap: 13px;
          
          &::-webkit-scrollbar {
            height: 0px;
          }

          &-link {
            position: relative;
            display: block;
            margin-right: 0;

            &-text {
              white-space: nowrap;
              display: flex;
              height: 36px;
              padding-left: 9px;
              padding-right: 9px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: rgba($color: #fff, $alpha: 0.5);

              &-active {
                color: #fff;
                border-bottom: none;
                border-bottom: 1px solid #FFC900;
              }
            }
          }

          & .top-sub-menu {
            &-filter {
              display: flex;
              flex-direction: column;
              min-width: 30%;
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              justify-content: center;

              &-title {
                font-size: var(--defaultFontSize);
                opacity: 0.8;
                width: 80% !important;
              }

              &-content {
                font-size: var(--defaultFontSize);
                width: 80% !important;
              }
            }

            &-millionaires {
              font-size: var(--defaultFontSize);
              font-weight: 400;
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              min-width: 25%;
              padding-right: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &-active {
              font-size: var(--defaultFontSize);
              font-weight: 400;
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              min-width: 33%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80% !important;

              &-title {
                font-size: var(--defaultFontSize);
                padding-left: 5px;
                opacity: 0.5;
              }

              // .tou-custom-checkbox + .custom-control-label::before
              & .top-sub-menu-active-title::before {
                border: #adb5bd solid 1px !important;
                width: 20px !important;
                height: 20px !important;
                background-color: transparent;
              }
            }

            &-search {
              display: flex;
              justify-content: center;
              align-items: center;

              & img {
                width: 16px;
                height: 16px;
              }
            }
          }

          & .top-sub-menu-filter::after {
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-bottom: 0;
            border-left: 0.4em solid transparent;
            margin-top: 0.4em;
            background: unset;
            background-repeat: unset;
            background-position: unset;
            vertical-align: middle;
            background-size: unset;
            margin-left: 0;
            margin-top: 0.8em;
            display: inline-block;
            content: "";
            width: 10px;
            position: absolute;
            right: 0px;
          }
        }


        .create-room-button-wrapper {
          padding-left: 0;
          padding-right: 0;

          button {
            padding: 6px 15px;
            font-size: var(--defaultFontSize);
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;

            &::before {
              margin-right: 13px;
            }

            &::after {
              margin-left: 13px;
            }
          }
        }

        .rooms-table-header-tools {
          padding-left: 0;
          padding-right: 5px;
          display: flex;
          column-gap: 16px;

          .filter-image {
            width: 14px;
            height: 14px;
          }

          .info-image {
            width: 18px;
            height: 18px;
          }
        }
      }

      .tooltip-body-mobile {

      }

      & .__floater__arrow {
        display: none ;
      }

      .layout-mobile-header {
        position: fixed;
        bottom: 0;
        width: 100%;
        // margin: auto;
        left: 15px;
        background: linear-gradient(180deg, #181818 0%, #000000 100%);
        border-radius: 8px 8px 0px 0px;
        z-index: 10;

        .layout-mobile-header-wrapper {
          display: flex;
          flex-direction: row;
          overflow-x: auto !important;
          overflow-y: hidden;
          justify-content: space-between;
          padding: 3px 0 6px 19px;
          column-gap: 13px;

          &-joyride {
            overflow: initial !important;
          }

          &::-webkit-scrollbar {
            height: 4px;
          }

          .layout-header-link {
            position: relative;
            display: block;
            margin-right: 0;
            &-notification::after {
              right: 1px;
              top: -3px;
            }

            &-text {
              white-space: nowrap;
              display: flex;
              height: 36px;
              padding-left: 9px;
              padding-right: 9px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                margin-right: 6px;
              }
            }

            &-text-active {
              color: #FFC900;
              border-bottom: none;
              background-color: #000;

              &::after,
              &::before {
                content: '';
                background-image: url('../../../images/redesign/mobile/icons/menu-shadow-dark-icon.jpg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 14px;
                height: 34px;
                position: absolute;
                left: -14px;
                top: 0;
              }

              &::after {
                transform: scaleX(-1);
                right: -14px;
                left: unset;
              }
            }
          }
        }
      }

      .layout-mobile-body-content {
        .layout-mobile-body-pane {
          .layout-mobile-body {
            .layout-mobile-body-top {
              height: 41px;

              .layout-subheader {
                display: none;
              }

              .layout-mobile-subheader {
                display: flex !important;
                position: fixed;
                z-index: 11;
                bottom: 46px;
                left: 10px;
                right: 10px;
                margin: 0 auto;
                background-color: #000;
                border-radius: 8px;
                padding: 9px 10px;
                justify-content: space-between;
                align-items: center;

                .layout-mobile-subheader-navmenu {
                  display: flex;
                  flex-direction: row;
                  overflow-x: auto !important;
                  overflow-y: hidden !important;
                  justify-content: space-between;
                  grid-column-gap: 13px;
                  -webkit-column-gap: 13px;
                  column-gap: 13px;
                  
                  &::-webkit-scrollbar {
                    height: 0px;
                  }

                  &-link {
                    position: relative;
                    display: block;
                    margin-right: 0;

                    &-text {
                      white-space: nowrap;
                      display: flex;
                      height: 36px;
                      padding-left: 9px;
                      padding-right: 9px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      color: rgba($color: #fff, $alpha: 0.5);

                      &-active {
                        color: #fff;
                        border-bottom: none;
                        border-bottom: 1px solid #FFC900;
                      }
                    }
                  }

                  & .top-sub-menu {
                    &-filter {
                      display: flex;
                      flex-direction: column;
                      min-width: 30%;
                      border-right: 1px solid rgba(255, 255, 255, 0.4);
                      justify-content: center;

                      &-title {
                        font-size: var(--defaultFontSize);
                        opacity: 0.8;
                        width: 80% !important;
                      }

                      &-content {
                        font-size: var(--defaultFontSize);
                        width: 80% !important;
                      }
                    }

                    &-millionaires {
                      font-size: var(--defaultFontSize);
                      font-weight: 400;
                      border-right: 1px solid rgba(255, 255, 255, 0.4);
                      min-width: 25%;
                      padding-right: 15px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }

                    &-active {
                      font-size: var(--defaultFontSize);
                      font-weight: 400;
                      border-right: 1px solid rgba(255, 255, 255, 0.4);
                      min-width: 33%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 80% !important;

                      &-title {
                        font-size: var(--defaultFontSize);
                        padding-left: 5px;
                        opacity: 0.5;
                      }

                      // .tou-custom-checkbox + .custom-control-label::before
                      & .top-sub-menu-active-title::before {
                        border: #adb5bd solid 1px !important;
                        width: 20px !important;
                        height: 20px !important;
                        background-color: transparent;
                      }
                    }

                    &-search {
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      & img {
                        width: 16px;
                        height: 16px;
                      }
                    }
                  }

                  & .top-sub-menu-filter::after {
                    border-top: 0.4em solid;
                    border-right: 0.4em solid transparent;
                    border-bottom: 0;
                    border-left: 0.4em solid transparent;
                    margin-top: 0.4em;
                    background: unset;
                    background-repeat: unset;
                    background-position: unset;
                    vertical-align: middle;
                    background-size: unset;
                    margin-left: 0;
                    margin-top: 0.8em;
                    display: inline-block;
                    content: "";
                    width: 10px;
                    position: absolute;
                    right: 0px;
                  }
                }


                .create-room-button-wrapper {
                  padding-left: 0;
                  padding-right: 0;

                  button {
                    padding: 6px 15px;
                    font-size: var(--defaultFontSize);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;

                    &::before {
                      margin-right: 13px;
                    }

                    &::after {
                      margin-left: 13px;
                    }
                  }
                }

                .rooms-table-header-tools {
                  padding-left: 0;
                  padding-right: 5px;
                  display: flex;
                  column-gap: 16px;

                  .filter-image {
                    width: 14px;
                    height: 14px;
                  }

                  .info-image {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }

            .layout-mobile-body-main {
              height: calc(100vh - 41px - 271px);
              min-height: calc(100vh - 41px - 280px);
              background-color: rgba($color: #000000, $alpha: 0.95);
              border-radius: 17px 17px 0 0;
              padding: 13px 8px 77px 3px;
              position: relative;
              z-index: 12;

              .layout-mobile-body-main-scrollarea {
                max-height: 100%;
                overflow-x: hidden;
                overflow-y: auto;

                .menu-table-row-wrapper {
                  padding-bottom: 6px;

                  .menu-table-row-inner-wrapper {
                    padding-left: 10px;
                    background-color: rgba(255, 255, 255, 0.03);
                  }
                }
              }
            }
          }
        }
      }

      .layout-mobile-footer {
        height: 77px;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.95);
        display: flex;
        margin: 0;
        justify-content: space-between;

        .layout-mobile-footer-bonus {
          padding-left: 20px;
          padding-right: 0;

          .layout-mobile-footer-bonus-link {
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: 5px;
            float: left;

            img.layout-mobile-footer-link-image {
              width: 100px;
              position: relative;
              left: 0;
              top: 0;
              z-index: 100;
            }

            .layout-mobile-footer-link-button {
              position: relative;
              left: 0;
              top: -17px;
              z-index: 99;
              font-size: var(--defaultFontSize);
              padding: 4px 9px;
              background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
              border: 1px solid #0C0C0C;
              border-radius: 99px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 1;
              white-space: nowrap;
              width: 100%;

              &::before,
              &::after {
                display: inline-block;
                content: ' ';
                background-image: url('../../../images/redesign/common/kreists.svg');
                background-size: 7px 7px;
                height: 7px;
                width: 7px;
              }

              &::before {
                margin-right: 7px;
              }

              &::after {
                margin-left: 7px;
              }
            }
          }
        }

        .layout-mobile-footer-shop {
          padding-left: 0;
          padding-right: 20px;

          .layout-mobile-footer-shop-link {
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: 7px;
            float: right;
            width: 100px;

                        img.layout-mobile-footer-link-image {
                            width: 50%;
                            position: relative;
                            left: 0;
                            top: 0;
                            z-index: 100;
                        }

            .layout-mobile-footer-link-button {
              position: relative;
              left: 0;
              top: -17px;
              z-index: 99;
              font-size: var(--defaultFontSize);
              padding: 4px 9px;
              background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
              border: 1px solid #0C0C0C;
              border-radius: 99px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 1;
              white-space: nowrap;
              width: 100%;

              &::before,
              &::after {
                display: inline-block;
                content: ' ';
                background-image: url('../../../images/redesign/common/kreists.svg');
                background-size: 7px 7px;
                height: 7px;
                width: 7px;
              }

              &::before {
                margin-right: 7px;
              }

              &::after {
                margin-left: 7px;
              }
            }
          }
        }

        .layout-mobile-footer-profile {
          .player-info {
            .player-info-background {
              display: none;
            }

            .player-info-player {
              .player-info-player-background {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;

                .player-info-player-image-wrapper {
                  position: relative;
                  left: 0;
                  top: 0;
                  display: inline-flex;
                  justify-content: center;

                  .player-edit-pencil {
                    position: absolute !important;
                    left: -15px !important;
                    top: 15px !important;
                    bottom: unset !important;
                    width: 28px !important;
                    height: 28px !important;
                    border-radius: 50% !important;
                    z-index: 200 !important;

                    img {
                      width: 16px;
                      height: 16px;
                    }
                  }

                  .player-info-player-image {
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 51px;
                    height: 51px;
                    box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
                    -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                    border-radius: 50%;

                  }

                  .player-info-help-icon {
                    position: absolute;
                    top: 4px;
                    left: 14px;
                    width: 70px;
                    height: 70px;
                    z-index: 200;
                  }

                  .player-info-player-image-emotion {
                    position: absolute;
                    top: -18px;
                    left: -18px;
                    width: 44px;
                    height: 44px;
                    z-index: 200;
                  }

                  .player-info-player-mark {
                    // background-color: #662B8D;
                    line-height: 160%;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    color: #FFFFFF;
                    border-radius: 2px;
                    font-size: var(--smallFontSize);
                    position: absolute;
                    padding-left: 3px;
                    padding-right: 3px;
                    z-index: 100;
                    top: 40px;
                    &-font {
                      font-size: var(--smallFontSize);
                    }
                  }
                }


                .player-info-player-name-wrapper {
                  background: #000000;
                  border-radius: 88px;
                  padding: 1px 12px;
                  margin-top: 3px;

                  .player-info-player-name {
                    font-size: var(--defaultFontSize);
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    color: #FFFFFF;
                    white-space: nowrap;
                  }
                }

              }
            }
          }
        }
      }
    }

    .online-stats {
      display: none;
    }

    .bottom-bar {
      display: none;
    }

    .game-clock {
      display: none;

      &-ingame {
        display: block !important;
        width: 200px !important;
        right: 27px !important;
        bottom: 3px !important;
      }
    }

    .modal {
      .mobile-dropdown-menu-modal {
        top: 0;
        margin: 0;
        display: block !important;

        .modal-content {
          .modal-header {
            display: none;
          }

          .modal-body {
            padding: 0 !important;
            position: relative;
            left: 0;
            top: 0;

            .dropdown-menu-modal-content {
              // margin: 33px 0 16px 0;
              background: rgba($color: #0C0C0C, $alpha: 0.95);
              box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
              border-radius: 0px 0px 17px 17px;

              .close-button {
                position: absolute;
                right: -39px;
                top: 4px;
                background-color: rgba(134, 134, 134, 0.5);
                border-radius: 50%;

                img {
                  width: 14px;
                  height: 14px;
                  margin: 11px;
                }
              }

              .navigation-links {
                background: rgba($color: #000000, $alpha: 0.95);

                padding-top: 33px;
                display: flex;
                flex-direction: column;
                row-gap: 18px;
                align-items: center;

                a {
                  span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: var(--defaultFontSize);
                    line-height: 160%;
                    color: rgba($color: #fff, $alpha: 0.7);
                  }
                }

                padding-bottom: 31px;
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
              }

              .theme-control-tools {
                background: rgba($color: #000000, $alpha: 0.95);
                margin: 0;
                padding-top: 29px;
                padding-bottom: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

                .theme-control-tool-section {
                  padding: 0;
                  padding-right: 23.5px;

                  .switch-select {
                    height: 32.5px;
                    padding: 2px;
                    border-radius: 53.625px;
                    background-color: rgba(134, 134, 134, 0.5);
                    display: flex;
                    align-items: center;
                    background-color: rgba($color: #868686, $alpha: 0.5);
                    flex-direction: row-reverse;

                    .switch-select-option {
                      padding: 0;
                      background: none;
                      box-shadow: none;
                      transition: background 0.2s;

                      .switch-select-option-img {
                        padding: 5px 10px;

                        .light-image {
                          width: 22.45px;
                          height: 22.45px;
                        }

                        .normal-image {
                          width: 26px;
                          height: 19.2px;
                        }

                        .dark-image {
                          width: 19.2px;
                          height: 19.2px;
                        }
                      }
                    }

                    .switch-select-option-active {
                      border-radius: 53.625px;
                      background-color: rgba($color: #868686, $alpha: 0.5);
                    }
                  }

                }

                .migrate-to-old-design-section {
                  padding-left: 23.5px;
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: rgba($color: #fff, $alpha: 0.5);
                  text-decoration: underline;
                  text-align: center;
                  cursor: pointer;
                }

                .logout-section {
                  text-align: center;
                  padding-right: 33.5px;
                  font-family: 'Open Sans';
                  font-size: var(--defaultFontSize);
                  font-style: normal;
                  font-weight: 400;
                  line-height: 160%;
                  color: rgba($color: #fff, $alpha: 0.5);
                  text-decoration: underline;
                  cursor: pointer;
                }
              }

              .website-setting-tools {
                background: rgba($color: #000000, $alpha: 0.95);
                display: flex;
                justify-content: space-between;
                padding: 30px 29px 29.5px 28.5px;
                box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
                border-radius: 0px 0px 17px 17px;

                .language-select {
                  display: flex;
                  column-gap: 22px;

                  .language-label {
                    img {
                      width: 26px;
                      height: 26px;
                      opacity: 0.5;
                      cursor: pointer;
                    }
                  }

                  .language-label-active {
                    img {
                      opacity: 1;
                    }
                  }
                }

                .extra-tools {
                  display: flex;
                  column-gap: 22px;
                  align-items: center;

                  .setting-section {
                    img {
                      opacity: 0.5;
                      height: 26px;
                      object-fit: contain;
                      cursor: pointer;
                    }
                  }

                  .font-setting {
                    display: none;
                    img {
                      object-fit: contain;
                      height: 26px;
                      cursor: pointer;
                    }
                  }

                  .sound-setting {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    .sound-button {
                      position: static;

                      img {
                        object-fit: contain;
                        height: 26px;
                        padding-left: 0;
                      }
                    }
                  }
                }
              }

              .mobile-online-stats {
                display: block;
                padding-top: 16px;
                padding-bottom: 16px;
                text-align: center;

                .mobile-online-stats-wrapper {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  padding: 7.5px 14px;
                  background: #000000;
                  border-radius: 88px;

                  .online-stats-text {
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 450;
                    font-size: var(--defaultFontSize);
                    line-height: 1.35;
                    color: rgba($color: #FFC900, $alpha: 0.7);
                    margin-right: 2px;
                  }

                  .online-stats-count {
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 450;
                    font-size: var(--largeFontSize);
                    line-height: 135%;
                    color: #FFFFFF;

                  }

                  .online-stats-divider {}

                  .online-stats-count {
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 450;
                    font-size: var(--largeFontSize);
                    line-height: 135%;
                    color: #FFFFFF;
                  }
                }
              }
            }
          }
        }

        img.mobile-close {
          display: none;
        }
      }
    }

    .game-result-body {
      & .default-modal-body {

      }

      &-content {
        max-height: calc(100vh - 320px);
        overflow: hidden auto;

        .notification-points-section-title {
          font-size: 20px;
          line-height: unset;
        }

        .notification-points-section-points {
          font-size: 36px;
          line-height: unset;
        }

        .notification-points-section-bottom {
          font-size: 20px;
          line-height: unset;
        }

        .notification-winners {
          min-height: 300px;
          transform: scale(0.6);
        }
      }
    }

    .game-result-body-pass {
      & .default-modal-body {
        height: unset !important;
        overflow: hidden;
        overflow-y: auto;
      }
    }

    .game-notification-modal {
      & .default-modal-body {
        // height: 70vh !important;
        overflow: hidden;
        overflow-y: auto;
      }
    }

    .game-result-modal {
      & .default-modal-body {
        height: 70vh !important;
        overflow: hidden;
        //overflow-y: auto;
      }
    }

    // & .modal {
    //   height: 100vh;
    //   max-height: 100vh !important;
    //   min-height: 100vh;

    //   & .modal-dialog {

    //     & .modal-content {
    //       min-height: 85vh !important;

    //       & .modal-body {
    //         padding: 20px 20px 0 20px !important;
    //         max-height: 65vh;

    //         & .new-user-party-modal {
    //           height: 58vh;
    //         }
    //       }

    //       & .mobile-close {
    //         bottom: -45px !important;
    //       }
    //     }
    //   }
    // }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .room-ended-modal-new {
      &-wrapper {
        height: 60vh;
        overflow-x: hidden;
        overflow-y: auto;
      }
      &-info-wrapper {
        // width: 100%;
        right: 0px;
        top: 0px;
      }
      &-info-image {
        right: 11px !important;
        left: unset !important;
        top: -30px !important;
        margin: auto !important;
        margin-right: 0px !important;
        // width: 4vw !important;
        // height: 4vw !important;
      }
      &-info-hover {
        right: 4vw !important;
        left: 0vw !important;
        width: auto !important;
        transform: translateY(-78%) !important;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.5), -2px 2px 5px rgba(0,0,0,0.3) !important;
      }
      &-button {
        display: inline-flex !important;
      }
    }

    .game-result-modal {
      width: 90vw !important;
      max-width: 90vw !important;
      top: -11px !important;

      & .default-modal-body {
        padding-top: 20px !important;
        padding-bottom: 0px;

      }
      .modal-footer {
        padding-top: 0.5vh;
        padding-bottom: 0.5vh;
      }
      .modal-close-img {
        top: 48px !important;
      }
    }
    .mobile-detector-modal-wrapper {
      max-width: unset;
      margin-top: -12px;

      .modal-body {
        padding-top: 20px !important;
        padding-bottom: 0px;
      }
    }
    .mobile-detector-modal {
      height: unset;
      min-height: unset;

      .mobile-icon {
        img {
          height: 40px;
        }
      }
      .mobile-content {
        p {
          line-height: 100%;
          margin-bottom: 5px;
        }
      }
      .download-content {
        p {
          margin-bottom: 5px;
        }
      }
    }
    .player-emotion-left {
      top: 0px;
    }
    .player-emotion-right {
      top: 0px;
    }
    // .player-firstperson-emotion {
    //   top: -60px;
    //   right: 53px !important;
    //   bottom: unset;
    // }
    .icon-popover {
      &-mobile-disable {
        display: none;
      }
    }
    .create-room-landscape-popover {
      //transform: translate3d(0px, 135px, 0px) !important;

      .popover-body {
        max-width: 200px !important;
      }
      .arrow {
        left: 15px !important;
      }
    }
    .top-filter-dropdown {
      width: 300px !important;
      top: 35% !important;
      left: 50% !important;

      transform: translate(-50%, -50%) !important;
      position: fixed;

      & .modal-content {
        min-height: unset !important;
        & .modal-header {
          & .web-close {
            display: none !important;
            // right: -46px !important;
            // top: 80px !important;
          }
        }

        & .modal-body {
          padding-top: 15px !important;
          border-radius: 16px 0px 0px 16px !important;
        }
      }

      & .modal-header {
        .modal-title {
          visibility: collapse;
        //display: none;
        }
      }

      & .mobile-close {
        //display: none;
      }

      & button {
        color: #fff ;
      }
    }

    .top-time-dropdown {
      // top: 67vh !important;
      // left: 50% !important;
      width: 236px !important;
      // transform: translate(-50%, -50%) !important;
      // position: fixed;

      & .modal-content {
        min-height: unset !important;

        & .modal-body {
          padding-top: 15px !important;
        }
      }

      & .modal-header {
        .modal-title {
          visibility: collapse;
          //display: none;
        }
      }

      & .mobile-close {
        //display: none;
      }

      & button {
        color: #fff !important;
      }
    }

    .top-search-dropdown {
      //top: 80vh !important;
      //left: 50% !important;
      width: 236px !important;
      //transform: translate(-50%, -50%) !important;
      //position: fixed;

      & .modal-content {
        min-height: unset !important;

        & .modal-body {
          padding-top: 15px !important;
        }
      }

      & .modal-title {
        display: none;
      }

      & .modal-header {
        // display: none;
      }

      & .mobile-close {
        display: none;
      }

      & .web-close {
        padding: 0px !important;
        right: -24px !important;
        top: 5px !important;
        width: 30px !important;
        height: 30px !important;
        background-size: 10px 10px !important;
        cursor: pointer;
      }

      & button {
        //color: #fff !important;
      }
    }
    .root-class-design20-fullscreen-menu {
      padding-left: 0;
      padding-right: 0;

      .game-bg {
        background-image: url('../../../images/redesign/mobile/background/game-lobby-dark-landscape-bg.jpg');
      }

      .lobby-bg-table {
        background-image: url('../../../images/redesign/mobile/background/game-table-landscape-bg.png');
        background-size: contain;
        bottom: 0;
      }

      .lobby-bg-table+.row {
        margin: 0;

        .menu-background-bg {
          display: none;
        }
      }
    }


    .page-wrapper {
      width: 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
      flex: unset !important;
    }

    .top-row {

      .coin-img {
        display: none;
      }
      .coin-text {
        display: none;
      }
      .rating-img {
        display: none;
      }
      .rating-text {
        display: none;
      }
      // display: none;
      .logo-wrapper {
        display: none;
      }
      .top-setting-menu-open-button {
        display: none !important;
      }

      .top-setting-menu-item-container {
        right: 14px;
        .top-setting-menu-leave-text {
          display: none;
        }

        &-holiday {
          z-index: 99999;
        }
      }
    }

    .layout {
      display: none;
    }

    .layout-mobile {
      width: 100%;
      display: block !important;
      position: relative;
      left: 0;
      right: 0;
      height: 100%;

      .layout-mobile-header {
        display: none;
      }

      .landscape-layout-mobile-header {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        display: block !important;
        z-index: 999;

        .landscape-layout-mobile-header-wrapper-joyride {
          width: 50px !important;
          max-width: 50px !important;
          // overflow: hidden !important;
          // pointer-events: none !important;

          &:focus,
          &:active,
          &:hover {
            width: 50px !important;
            max-width: 50px !important;
            // overflow: hidden !important;
            // pointer-events: none !important;
          }
        }
        .landscape-layout-mobile-header-wrapper {
          width: 50px;
          background: linear-gradient(90deg, #181818 0%, #000000 100%);
          border-radius: 8px 0px 0px 0px;//8px 0 0px 8px;
          padding: 10px 6px 0 6px !important;
          height: calc(100% - 72px);
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-y: auto;
          overflow-x: hidden;
          transition: all 0.4s ease;
          user-select: none;

          .bottom-part {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 50px;
            height: 72px;
            background: linear-gradient(90deg, #181818 0%, #000000 100%);
            border-radius: 0px 0px 0px 8px;
            transition: all 0.4s ease;
            padding: 0 6px 0 6px;
            overflow-x: hidden !important;
            border-top: 1px solid rgba(255,255,255,0.1);

            .coins-and-rating {
              height: 20px;

              .value-wrapper {
                width: 17px;
                display: inline-block;
                .value-image {
                  display: inline-block;
                  height: 17px;
                  vertical-align: middle;
                }
                .value-text {
                  display: none;
                  opacity: 0;
                }
              }
            }
          }

          .background-fade {
            position: fixed;
            background: rgba(30,30,30,0.7);
            //backdrop-filter: blur(3px); // test
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            opacity: 0;
            z-index: -1;
            transition: all 0.4s ease;
          }

          .nav-item-wrapper {
            transition: all 0.4s ease;
            margin-left: 3px;
            display: flex;
            width: 36px;

            .nav-item-img-14 {
              margin: 11px;
              width: 14px;
              height: 14px;
            }
            .nav-item-img-22 {
              margin: 7px;
              width: 22px;
              height: 22px;
            }
            .nav-item-img-28 {
              margin: 4px;
              width: 28px;
              height: 28px;
            }
            .nav-item-img-top-search {
              margin-left: 9px;
              margin-right: 9px;
              width: 18px;
              height: 28px;
            }
            .nav-item-img-21 {
              margin: 7.5px;
              width: 21px;
              height: 21px;
              padding: 0px;
            }
            .nav-item-img-20 {
              margin: 8px;
              width: 20px;
              height: 20px;
              padding: 0px;
            }

            .nav-item-text {
              margin-left: 2px;
              display: inline-flex;
              overflow-x: hidden;
              white-space: nowrap;
              line-height: 36px;
              font-family: Open Sans;
              font-size: 13px;
              font-weight: 600;
              &-light {
                color: rgba(0,0,0,0.5);
              }
              &-normal {
                color: white;
              }
              &-dark {
                color: white;
              }
              &-active {
                color: #FFC900 !important;
              }
            }

            &:hover {
              background: rgba(255,255,255,0.1);
              border-radius: 19px;
              .nav-item-text {
                color: #FFC900;
              }
            }
          }

          .create-room-button {
            
            transition: width 0.4s ease;
            width: 36px;
            height: 36px;
            max-height: 36px;
            display: flex;
            padding: 0px !important;
            margin-top: 4px;
            margin-bottom: 4px;

            &-icon {
              //display: inline-block;
              width: 16px;
              height: 16px;
              &-light {
                margin-bottom: 10px;
                margin-left: 10px;
                margin-top: 11px;
                margin-right: 9px;
              }
              &-normal,
              &-dark {
                margin-bottom: 11px;
                margin-left: 9px;
                margin-top: 9px;
                margin-right: 11px;
              }
            }

            &-text {
              display: inline-flex;
              overflow-x: hidden;
              white-space: nowrap;
              // height: 40px;
              line-height: 36px;
              // vertical-align: middle;
              font-family: Open Sans;
              font-weight: 600;
              color: black;
            }
          }

          &-expanded,
          &:hover {
            width: 213px !important;//204
            max-width: 213px !important;
            .create-room-button {
              width: 193px;//184
            }
            .nav-item-wrapper {
              width: 193px;//184
              cursor: pointer;
            }
            .logo {
              opacity: 0;
            }
            .logo-expanded {
              opacity: 1;
            }
            .background-fade {
              opacity: 1;
            }
            .bottom-part {
              width: 213px;//204

              .coins-and-rating {
                display: flex;
                justify-content: space-between;

                .value-wrapper {
                  width: auto;

                  .value-image {
                    display: inline-block;
                    height: 17px;
                    vertical-align: middle;
                  }
                  .value-text {
                    display: inline-block;
                    opacity: 1;

                    &-light {
                      color: black;
                    }
                    &-normal {
                      color: white;
                    }
                    &-dark {
                      color: white;
                    }

                    &-orange {
                      color: #FF8A1A;
                    }
                    &-red {
                      color: #D2110A;
                    }
                  }
                }
              }
            }
          }

          .logo-wrapper {
            width: 100%;
            height: 40px;
            min-height: 40px;
            padding-left: 5px;
            white-space: nowrap;
            cursor: pointer;
          }
          .logo {
            display: inline-block;
            transition: all 0.5s ease;
            opacity: 1;
            height: 40px;
          }
          .logo-expanded {
            display: inline-block;
            margin-left: -25px;
            transition: all 0.5s ease;
            opacity: 0;
            height: 40px;
          }

          .landscape-layout-mobile-header-links-wrapper-bottom {
            margin-top: auto;
            margin-left: auto;
            width: 213px;//204px;
          }
          .landscape-layout-mobile-header-links {
            margin-top: 2px;
            width: 100%;
            &-bottom {
              margin-top: auto;
            }

            .landscape-layout-mobile-header-link-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              // align-items: center;
              row-gap: 4px;
              float: left;

              .layout-header-link {
                width: 193px;//184px;
                padding: 6px 8px;// 8px 8px originally
                margin-right: 0;
                border-radius: 20px;
                transition: all 0.4s ease;

                &-medium {
                  padding: 2px 3px;
                }

                .medium-icon-wrapper {
                  width: 32px;
                  height: 36px;
                  display: flex;
                  justify-content: end;
                  align-items: center;
                }
                .settings-link-wrapper {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .profile-link-image {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                }
                .tutorial-link-image {
                  width: 32px;
                  height: 32px;
                  padding: 3.5px 4px;
                }
                .settings-link-image {
                  width: 32px;
                  height: 32px;
                  padding: 7px 5.5px;
                  //21x18
                }
                .chat-link-image,
                .shop-link-image,
                .daily-bonus-link-image {
                  width: 32px;
                  height: 32px;
                }
                &:hover {
                  background-color: rgba(255,255,255,0.1);
                  width: 193px;//184px;

                  .settings-link-wrapper {
                    background-color: rgba(0,0,0,0.3);
                  }

                  .item-wrapper {
                    .item-text {
                      color: #FFC900;
                    }

                    .item-img {
                      &-tournaments {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/tournaments-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/tournaments-menu-active-icon.svg');
                        }
                      }
                      &-myInfo {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/my-info-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/my-info-menu-active-icon.svg');
                        }
                      }
                      &-powerRankings {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/power-ranking-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/power-ranking-menu-active-icon.svg');
                        }
                      }
                      &-top {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/top-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/top-menu-active-icon.svg');
                        }
                      }
                      &-rooms {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/rooms-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/rooms-menu-active-icon.svg');
                        }
                      }

                    }
                  }
                  
                }
                .item-wrapper {
                  display: flex;
                  align-items: center;

                  .item-img {
                    background-size: 21px 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 21px;
                    height: 20px;

                    &-tournaments {
                      &-light {
                        background-image: url('../../../images/redesign/mobile/light-mode/icons/tournaments-menu-icon.svg');
                      }
                      &-normal,
                      &-dark {
                        background-image: url('../../../images/redesign/mobile/icons/tournaments-menu-icon.svg');
                      }

                      &-active {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/tournaments-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/tournaments-menu-active-icon.svg');
                        }
                      }
                    }
                    &-myInfo {
                      &-light {
                        background-image: url('../../../images/redesign/mobile/light-mode/icons/my-info-menu-icon.svg');
                      }
                      &-normal,
                      &-dark {
                        background-image: url('../../../images/redesign/mobile/icons/my-info-menu-icon.svg');
                      }

                      &-active {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/my-info-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/my-info-menu-active-icon.svg');
                        }
                      }
                    }
                    &-powerRankings {
                      &-light {
                        background-image: url('../../../images/redesign/mobile/light-mode/icons/power-ranking-menu-icon.svg');
                      }
                      &-normal,
                      &-dark {
                        background-image: url('../../../images/redesign/mobile/icons/power-ranking-menu-icon.svg');
                      }

                      &-active {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/power-ranking-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/power-ranking-menu-active-icon.svg');//
                        }
                      }
                    }
                    &-top {
                      &-light {
                        background-image: url('../../../images/redesign/mobile/light-mode/icons/top-menu-icon.svg');
                      }
                      &-normal,
                      &-dark {
                        background-image: url('../../../images/redesign/mobile/icons/top-menu-icon.svg');
                      }

                      &-active {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/top-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/top-menu-active-icon.svg');
                        }
                      }
                    }
                    &-rooms {
                      &-light {
                        background-image: url('../../../images/redesign/mobile/light-mode/icons/rooms-menu-icon.svg');
                      }
                      &-normal,
                      &-dark {
                        background-image: url('../../../images/redesign/mobile/icons/rooms-menu-icon.svg');
                      }

                      &-active {
                        &-light {
                          background-image: url('../../../images/redesign/mobile/light-mode/icons/rooms-menu-active-icon.svg');
                        }
                        &-normal,
                        &-dark {
                          background-image: url('../../../images/redesign/mobile/icons/rooms-menu-active-icon.svg');
                        }
                      }
                    }

                  }

                  .item-text {
                    margin-left: 15px;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow-x: hidden;

                    font-size: 16px;
                    &-light {
                      color: black;
                    }
                    &-normal {
                      color: white;
                    }
                    &-dark {
                      color: white;
                    }
                    
                    &-active {
                      color: #FFC900;
                    }

                    &-medium {
                      margin-left: 9px;
                    }
                  }

                  .gift-img {
                    position: absolute;
                    height: 34px;
                    width: auto;
                    right: 12px;
                  }

                  &:hover {


                  }
                }
              }

              .rooms-tab-collapse {
                .create-room-button {
                  background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
                  border-radius: 20px;
                  padding: 11px;
                  //margin-left: 4px;
                  transition: all 0.4s ease;
                  &:hover {
                    background: linear-gradient(180deg, #FAFF00 0%, #FF9900 100%);
                    box-shadow: 0px 4px 22px 0px rgba(255, 230, 0, 0.6);

                  }
                }

                .create-room-landscape-wrapper {
                  position: relative;
                  margin-left: 4px;

                  &:hover {
                    .create-room-popover {
                      display: block;
                    }
                  }

                  .create-room-popover {
                    overflow: visible;
                    display: none;
                    position: absolute;
                    top: 46px;  
                    width: 190px;
                    height: auto;
                    background-color: white;
                    max-width: auto;
                    border: none;
                    border-radius: 18px;
                    box-shadow: 0px 12px 22px 0px rgba(0, 0, 0, 0.7) !important;
                  
                    &-body {
                      width: 100%;
                      height: 100%;
                      color: black;
                      font-size: var(--defaultFontSize);
                      font-weight: 400;
                      font-family: Open Sans;
                      font-style: normal;
                      text-align: center;
                      padding: 10px 30px;
                    }
                    &-arrow {
                      position:absolute;
                      display: inline-block;
                      width: 0;
                      height: 0;
                      border-left: 8px solid transparent;
                      border-right: 8px solid transparent;
                      border-bottom: 8px solid white;
                      top: -7px;
                      left: 12px;
                    }
                  }
                }

                .nav-item {
                  &>img {
                    // margin: 8px auto;
                    // width: 14px;
                    // height: 14px;
                    width: 14px;
                    height: 14px;
                    margin-top: 13px;
                    margin-bottom: 13px;
                    margin-left: 14px;

                    cursor: pointer;
                  }
                }
              }

              .top-tab-collapse {
                .navbar-nav {
                  .nav-item {
                    display: flex;
                    // justify-content: center;
                    align-items: center;

                    &>img {
                      width: 22px;
                      height: 36px;
                      background: none;
                      margin-left: 9px;
                      cursor: pointer;
                    }
                  }

                  .nav-item:last-child {
                    &>img {
                      width: 18px;
                      opacity: 0.4;
                    }
                  }
                }
              }

              .my-info-tab-collapse {
                .navbar-nav {
                  .nav-item {
                    &>img {
                      width: 36px;
                      height: 36px;
                      background: none;
                      cursor: pointer;
                    }
                  }
                }
              }

              .power-rankings-tab-collapse {
                .navbar-nav {
                  .nav-item {
                    &>img {
                      width: 36px;
                      height: 36px;
                      background: none;
                      cursor: pointer;
                    }
                  }
                }
              }

              .tournaments-tab-collapse {
                .navbar-nav {
                  .nav-item {
                    &>img {
                      width: 39px;
                      height: 40px;
                      padding: 9px 8px;
                      cursor: pointer;
                    }

                    .tournament-tutorial {
                      // margin: 8px auto;
                      transform: scale(2.5);
                    }
                  }
                }


              }

            }

            .landscape-layout-mobile-header-link-item.landscape-layout-mobile-header-link-item-active {
              background: #000000;
              border-radius: 12px;
              padding-bottom: 4px;
              .layout-header-link {
                &:hover {
                  background-color: unset !important;
                }
              }
            }
          }

          .landscape-layout-mobile-header-bottom-line {
            width: 100%;
            height: 0;
            opacity: 0.1;
            border: 1px solid #FFFFFF;
            // margin-bottom: 8px;
          }
        }
      }

      .layout-mobile-body-content {
        //width: calc(100% - 145px - 52px);
        width: calc(100% - 105px - 92px);
        position: absolute;
        //left: 145px;
        left: 105px;
        height: 100%;

        .layout-mobile-body-pane {
          height: 100%;
          .layout-mobile-body {
            height: 100%;

            .layout-mobile-body-top {
              display: none;

              .layout-subheader {
                display: none;
              }

              .layout-mobile-subheader {
                display: flex !important;
                position: fixed;
                z-index: 11;
                bottom: 46px;
                left: 10px;
                right: 10px;
                margin: 0 auto;
                background-color: #000;
                border-radius: 8px;
                padding: 9px 10px;
                justify-content: space-between;
                align-items: center;

                .create-room-button-wrapper {
                  padding-left: 0;
                  padding-right: 0;

                  button {
                    padding: 6px 15px;
                    font-size: var(--defaultFontSize);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;

                    &::before {
                      margin-right: 13px;
                    }

                    &::after {
                      margin-left: 13px;
                    }
                  }
                }

                .rooms-table-header-tools {
                  padding-left: 0;
                  padding-right: 5px;
                  display: flex;
                  column-gap: 16px;

                  .filter-image {
                    width: 14px;
                    height: 14px;
                  }

                  .info-image {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }

            .layout-mobile-body-main {
              height: 100% !important;

              min-height: auto !important;
              // height: calc(100vh - 41px - 261px);
              // min-height: calc(100vh - 41px - 280px);
              background-color: rgba($color: #000000, $alpha: 0.95);
              border-radius: 17px !important;
              padding: 18px 9px 0 18px;
              position: relative;
              z-index: 12;

              .layout-mobile-body-title {
                display: block !important;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: var(--largeFontSize);
                line-height: 136%;
                color: #FFC900;
                margin-bottom: 6px;
                text-transform: uppercase;
              }

              .layout-mobile-body-main-scrollarea {
                //max-height: calc(100% - 100px) !important;
                //max-height: calc(100% - 142px) !important;
                overflow-x: hidden;
                overflow-y: auto;

                .menu-table-row-wrapper {
                  padding-bottom: 6px;
                  padding-left: 0;
                  padding-right: 8px !important;

                  .menu-table-row-inner-wrapper {
                    padding-left: 10px;
                    background-color: rgba(255, 255, 255, 0.03);
                  }
                }
              }
            }
          }
        }
      }

      .layout-mobile-footer {
        width: 130px;
        //display: flex;
        display: none;
        flex-direction: column;
        margin: 0;
        justify-content: space-between;

        .layout-mobile-footer-bonus {
          padding-left: 12px;
          padding-right: 0;

          .layout-mobile-footer-bonus-link {
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: 25px;

            img.layout-mobile-footer-link-image {
              width: 100px;
              position: relative;
              left: 0;
              top: 0;
              z-index: 100;
              cursor: pointer;
            }

            .layout-mobile-footer-link-button {
              position: relative;
              left: 0;
              top: -17px;
              z-index: 99;
              font-size: var(--defaultFontSize);
              padding: 4px 9px;
              background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
              border: 1px solid #0C0C0C;
              border-radius: 99px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 1;
              white-space: nowrap;
              width: 100%;

              &::before,
              &::after {
                display: inline-block;
                content: ' ';
                background-image: url('../../../images/redesign/common/kreists.svg');
                background-size: 7px 7px;
                height: 7px;
                width: 7px;
              }

              &::before {
                margin-right: 7px;
              }

              &::after {
                margin-left: 7px;
              }
            }
          }
        }

        .layout-mobile-footer-shop {
          padding-left: 0;
          padding-right: 20px;
          margin-top: 56px;

          .layout-mobile-footer-shop-link {
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100px;
            margin-left: 12px;

            img.layout-mobile-footer-link-image {
              width: 50%;
              position: relative;
              left: 0;
              top: 0;
              z-index: 100;
              cursor: pointer;
            }

            .layout-mobile-footer-link-button {
              position: relative;
              left: 0;
              top: -17px;
              z-index: 99;
              font-size: var(--defaultFontSize);
              padding: 4px 9px;
              background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
              border: 1px solid #0C0C0C;
              border-radius: 99px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 1;
              white-space: nowrap;
              width: 100%;

              &::before,
              &::after {
                display: inline-block;
                content: ' ';
                background-image: url('../../../images/redesign/common/kreists.svg');
                background-size: 7px 7px;
                height: 7px;
                width: 7px;
              }

              &::before {
                margin-right: 7px;
              }

              &::after {
                margin-left: 7px;
              }
            }
          }
        }

        .layout-mobile-footer-profile {
          width: auto;
          position: fixed;
          //right: 0;
          left: 71px;
          transform: translateX(-50%);
          bottom: 10px;

          .player-info {
            .player-info-background {
              display: none;
            }

            .player-info-player {
              .player-info-player-background {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;

                .player-info-player-image-wrapper {
                  position: relative;
                  left: 0;
                  top: 0;
                  display: inline-flex;
                  justify-content: center;

                  .player-edit-pencil {
                    position: absolute !important;
                    left: -15px !important;
                    top: 15px !important;
                    bottom: unset !important;
                    width: 28px !important;
                    height: 28px !important;
                    border-radius: 50% !important;
                    z-index: 200 !important;

                    img {
                      width: 16px;
                      height: 16px;
                    }
                  }

                  .player-info-player-image {
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 51px;
                    height: 51px;
                    box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
                    -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
                    border-radius: 50%;
                    margin-bottom: 16px;
                  }

                  .player-info-help-icon {
                    position: absolute;
                    top: 4px;
                    left: 14px;
                    width: 70px;
                    height: 70px;
                    z-index: 200;
                  }

                  .player-info-player-image-emotion {
                    position: absolute;
                    top: -18px;
                    left: -18px;
                    width: 44px;
                    height: 44px;
                    z-index: 200;
                  }

                  .player-info-player-mark {
                    // background-color: #662B8D;
                    line-height: 160%;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    color: #FFFFFF;
                    border-radius: 2px;
                    font-size: var(--smallFontSize);
                    position: absolute;
                    padding-left: 3px;
                    padding-right: 3px;
                    z-index: 100;
                    top: 49px;
                    &-font {
                      font-size: var(--smallFontSize);
                    }
                  }
                }


                .player-info-player-name-wrapper {
                  background: #000000;
                  border-radius: 88px;
                  padding: 1px 12px;
                  margin-top: 3px;

                  .player-info-player-name {
                    font-size: var(--defaultFontSize);
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    color: #FFFFFF;
                    white-space: nowrap;
                  }
                }

              }
            }
          }
        }
      }
    }

    .online-stats {
      display: none;
    }

    .bottom-bar {
      display: none;
    }

    .game-clock {
      //display: none;
      width: 89px !important;
      // background: black;
      text-align: center;
      right: 2px !important;
      
      //border-radius: 10px;

      &-ingame {
        margin-right: 0px !important;
        bottom: 387px !important;
      }
      span {
        // background: none;
        display: block;
        border-radius: 10px;
        padding: 0px;
      }
    }

    .modal {
      display: flex !important;
      align-items: center;

      .modal-dialog.mobile-dropdown-menu-modal {
        max-width: 100%;
        width: calc(100% - 160px);
        top: 0;
        margin: 0;
        display: block !important;

        .modal-content {
          .modal-header {
            display: none;
          }

          .modal-body {
            padding: 0 !important;
            position: relative;
            left: 25px;
            top: 0px;
            border-radius: 17px !important;

            .dropdown-menu-modal-content {
              // margin: 33px 0 16px 0;
              background: rgba($color: #0C0C0C, $alpha: 0.95);
              box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
              border-radius: 17px;

              .close-button {
                background-color: rgba($color: #000000, $alpha: 0.7);
                border-radius: 50%;
                width: 56px;
                height: 56px;
                position: absolute;
                display: block;
                right: -84px;
                top: 50%;
                padding: 37px;
                transform: translateY(-50%);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                img {
                  width: 18px;
                  height: 18px;
                }
              }

              .navigation-links {
                background: rgba($color: #000000, $alpha: 0.95);
                border-radius: 17px 17px 0 0;
                padding: 25.5px 25px 22.5px 25px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: 18px;
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

                a {
                  width: 33.33%;
                  flex: 33.33% 1 0;

                  span {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: var(--defaultFontSize);
                    line-height: 160%;
                    color: rgba($color: #fff, $alpha: 0.7);
                  }
                }


              }

              .theme-control-tools {
                background: rgba($color: #000000, $alpha: 0.95);
                margin: 0;
                padding-top: 12px;
                padding-bottom: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);

                .theme-control-tool-section {
                  padding: 0;

                  .switch-select {
                    height: 32.5px;
                    padding: 2px;
                    border-radius: 53.625px;
                    background-color: rgba(134, 134, 134, 0.5);
                    display: flex;
                    align-items: center;
                    background-color: rgba($color: #868686, $alpha: 0.5);
                    flex-direction: row-reverse;

                    .switch-select-option {
                      padding: 0;
                      background: none;
                      box-shadow: none;
                      transition: background 0.2s;

                      .switch-select-option-img {
                        padding: 5px 10px;

                        .light-image {
                          width: 22.45px;
                          height: 22.45px;
                        }

                        .normal-image {
                          width: 26px;
                          height: 19.2px;
                        }

                        .dark-image {
                          width: 19.2px;
                          height: 19.2px;
                        }
                      }
                    }

                    .switch-select-option-active {
                      border-radius: 53.625px;
                      background-color: rgba($color: #868686, $alpha: 0.5);
                    }
                  }

                }

                .migrate-to-old-design-section {
                  padding-left: 25px;
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: rgba($color: #fff, $alpha: 0.5);
                  text-decoration: underline;
                  text-align: center;
                  cursor: pointer;
                }

                .logout-section {
                  text-align: center;
                  padding-right: 24px;
                  font-family: 'Open Sans';
                  font-size: var(--defaultFontSize);
                  font-style: normal;
                  font-weight: 400;
                  line-height: 160%;
                  color: rgba($color: #fff, $alpha: 0.5);
                  text-decoration: underline;
                  cursor: pointer;
                }
              }

              .website-setting-tools {
                background: rgba($color: #000000, $alpha: 0.95);
                display: flex;
                justify-content: space-between;
                padding: 21px 24px 21px 25px;
                box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
                border-radius: 0px 0px 17px 17px;

                .language-select {
                  display: flex;
                  column-gap: 22px;

                  .language-label {
                    img {
                      width: 26px;
                      height: 26px;
                      opacity: 0.5;
                      cursor: pointer;
                    }
                  }

                  .language-label-active {
                    img {
                      opacity: 1;
                    }
                  }
                }

                .extra-tools {
                  display: flex;
                  column-gap: 22px;
                  align-items: center;

                  .setting-section {
                    img {
                      opacity: 0.5;
                      height: 26px;
                      object-fit: contain;
                      cursor: pointer;
                    }
                  }

                  .font-setting {
                    display: none;
                    img {
                      object-fit: contain;
                      height: 26px;
                      cursor: pointer;
                    }
                  }

                  .sound-setting {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    .sound-button {
                      position: static;

                      img {
                        object-fit: contain;
                        height: 26px;
                        padding-left: 0;
                      }
                    }
                  }
                }
              }

              .mobile-online-stats {
                padding-top: 11px;
                padding-bottom: 11px;
                text-align: center;

                .mobile-online-stats-wrapper {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  padding: 7.5px 14px;
                  background: #000000;
                  border-radius: 88px;

                  .online-stats-text {
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 450;
                    font-size: var(--defaultFontSize);
                    line-height: 1.35;
                    color: rgba($color: #FFC900, $alpha: 0.7);
                    margin-right: 2px;
                  }

                  .online-stats-count {
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 450;
                    font-size: var(--largeFontSize);
                    line-height: 135%;
                    color: #FFFFFF;

                  }

                  .online-stats-divider {}

                  .online-stats-count {
                    font-family: 'Futura PT';
                    font-style: normal;
                    font-weight: 450;
                    font-size: var(--largeFontSize);
                    line-height: 135%;
                    color: #FFFFFF;
                  }
                }
              }
            }

          }

          .modal-body::after {}
        }

        img.mobile-close {
          display: none;
        }
      }
    }

    .game-result-body {
      // top: -10px !important;
      // & .default-modal-body {
      //   padding-top: 22px !important;
      //   padding-bottom: 3px !important;
      //   flex: unset !important;
      // }

      // &-content {
      //   max-height: calc(100vh - 190px);
      //   overflow: hidden auto;

      //   .notification-points-section-title {
      //     font-size: 16px;
      //     line-height: unset;
      //   }

      //   .notification-points-section-points {
      //     font-size: 24px;
      //     line-height: unset;
      //   }

      //   .notification-points-section-bottom {
      //     font-size: 16px;
      //     line-height: unset;
      //   }

      //   .notification-points-section  {
      //     flex-direction: row !important;
      //     width: 50%;
      //     max-width: 90%;
      //     justify-content: space-evenly !important;
      //     transform: translateX(-50%);
      //     margin-left: 50%;

      //   }

        .notification-winners {
          min-height: auto;
          // height: 65px;

          .player-info-wrapper-section {
            transform: scale(0.8);
          }
        }
      //}
    }

    .game-result-body-pass {
      & .default-modal-body {
        height: unset !important;
        overflow: hidden;
        overflow-y: auto;
      }
    }

    // .modal {
    //   height: 100vh;
    //   min-height: 100vh !important;

    //   & .modal-dialog {
    //     height: unset !important;

    //     & .modal-content {
    //       min-height: 100vh !important;
          

    //       & .modal-body {
    //         border-top-right-radius: 0px !important;
    //         padding: 20px 20px 0 20px !important;
    //         max-height: 65vh;

    //         & .new-user-party-modal {
    //           height: 58vh;
    //         }
    //       }
  
          
    //     }
    //   }
    // }
  }
}

.root-is-app-frame {
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .layout-mobile {
      height: 100vh;
    }
  }
}
.root-is-not-app-frame {
  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .game-result-body {
      top: -10px !important;
      & .default-modal-body {
        padding-top: 22px !important;
        padding-bottom: 3px !important;
        flex: unset !important;
      }

      &-content {
        max-height: calc(100vh - 190px);
        overflow: hidden auto;

        .notification-points-section-title {
          font-size: 16px;
          line-height: unset;
        }

        .notification-points-section-points {
          font-size: 24px;
          line-height: unset;
        }

        .notification-points-section-bottom {
          font-size: 16px;
          line-height: unset;
        }

        .notification-points-section  {
          width: 50%;
          max-width: 90%;
          transform: translateX(-50%);
          margin-left: 50%;

        }

        .notification-winners {
          min-height: auto;
          height: 65px;

          .player-info-wrapper-section {
            transform: scale(0.3);
          }
        }
      }
    }
  }
}

.root-class-design20-normal {
  @media screen and (orientation : portrait) {
    .root-class-design20-fullscreen-menu {
      .game-bg {
        background-image: url('../../../images/redesign/mobile/background/game-lobby-normal-bg.jpg');
      }
    }

    .layout-mobile {

      &-subheader {
        color: white !important;
        background-color: #142255 !important;

        &-link-text {
          color: white !important;
          opacity: 0.7;
        }

        &-navmenu-link-text {
          color: white !important;
          opacity: 0.7;
        }
      }

      .layout-mobile-header {
        background: linear-gradient(180deg, #39477D 0%, #172148 100%);

        .layout-mobile-header-wrapper {
          .layout-header-link {
            &-text-active {
              background-color: #142255;

              &::after,
              &::before {
                content: '';
                background-image: url('../../../images/redesign/mobile/icons/menu-shadow-normal-icon.jpg');
              }
            }
          }
        }
      }

      .layout-mobile-body-content {
        .layout-mobile-body-pane {
          .layout-mobile-body {
            .layout-mobile-body-top {
              .layout-mobile-subheader {
                background-color: #142255 !important;

                .layout-mobile-subheader-navmenu {
                  background-color: #142255 !important;
                }
              }
            }

            .layout-mobile-body-main {
              background-color: rgba($color: #142255, $alpha: 0.95) !important;

              .layout-mobile-body-main-scrollarea {
                .menu-table-row-wrapper {
                  .menu-table-row-inner-wrapper {
                    background-color: rgba(255, 255, 255, 0.03);
                    box-shadow: none !important;
                  }
                }
              }
            }
          }
        }
      }

      .layout-mobile-footer {
        background-color: rgba($color: #142255, $alpha: 0.95);


        .layout-mobile-footer-shop {
          padding-left: 0;
          padding-right: 20px;

          .layout-mobile-footer-shop-link {
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: 7px;
            float: right;
            width: 100px;

            img.layout-mobile-footer-link-image {
              width: 50%;
              position: relative;
              left: 0;
              top: 0;
              z-index: 100;
            }

            .layout-mobile-footer-link-button {
              position: relative;
              left: 0;
              top: -17px;
              z-index: 99;
              font-size: var(--defaultFontSize);
              padding: 4px 9px;
              background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
              border: 1px solid #0C0C0C;
              border-radius: 99px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 1;
              white-space: nowrap;
              width: 100%;

              &::before,
              &::after {
                display: inline-block;
                content: ' ';
                background-image: url('../../../images/redesign/common/kreists.svg');
                background-size: 7px 7px;
                height: 7px;
                width: 7px;
              }

              &::before {
                margin-right: 7px;
              }

              &::after {
                margin-left: 7px;
              }
            }
          }
        }

        .layout-mobile-footer-profile {
          .player-info {
            .player-info-player {
              .player-info-player-background {
                .player-info-player-name-wrapper {
                  .player-info-player-name {
                    background: transparent !important;
                  }
                }

              }
            }
          }
        }
      }
    }

    .modal {
      .mobile-dropdown-menu-modal {
        .modal-content {
          .modal-body {
            .dropdown-menu-modal-content {
              background: rgba($color: #868686, $alpha: 0.95);

              .close-button {
                background-color: rgba($color: #000000, $alpha: 0.7);
                border-radius: 50%;

                img {}
              }

              .navigation-links {
                background: rgba($color: #142255, $alpha: 0.95);
              }

              .theme-control-tools {
                background: rgba($color: #142255, $alpha: 0.95);
              }

              .website-setting-tools {
                background: rgba($color: #142255, $alpha: 0.95);
              }

              .mobile-online-stats {
                .mobile-online-stats-wrapper {
                  background: rgba(255, 255, 255, 0.5);

                  .online-stats-text {
                    color: rgba($color: #000000, $alpha: 0.7);
                  }

                  .online-stats-count {
                    color: #000000;
                  }

                  .online-stats-count {
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }
    .top-filter-dropdown {
      & button {
        color: #fff ;
      }
      & button:hover {
        color: #000 !important;
      }
    }
    .top-time-dropdown {
      & button {
        color: #fff ;
      }
      & button:hover {
        color: #000 !important;
      }
    }
  }

  @media screen and (orientation : landscape) {
    .root-class-design20-fullscreen-menu {
      .game-bg {
        background-image: url('../../../images/redesign/mobile/background/game-lobby-normal-landscape-bg.jpg');
      }
    }

    .layout-mobile {
      .landscape-layout-mobile-header {
        .landscape-layout-mobile-header-wrapper {
          background: linear-gradient(90deg, #39477D 0%, #172148 100%);

          .bottom-part {
            background: linear-gradient(90deg, #39477D 0%, #172148 100%);
          }

          .landscape-layout-mobile-header-links {
            .landscape-layout-mobile-header-link-item.landscape-layout-mobile-header-link-item-active {
              background: #253678;
            }
          }
        }
      }

      .layout-mobile-body-content {
        .layout-mobile-body-pane {
          .layout-mobile-body {
            .layout-mobile-body-main {
              background-color: rgba($color: #142255, $alpha: 0.95);
            }
          }
        }
      }

      .layout-mobile-footer {
        .layout-mobile-footer-profile {
          .player-info {
            .player-info-player {
              .player-info-player-background {
                .player-info-player-name-wrapper {
                  background: #253678;
                }

              }
            }
          }
        }
      }
    }

    .modal {
      .modal-dialog.mobile-dropdown-menu-modal {
        .modal-content {
          .modal-body {
            .dropdown-menu-modal-content {
              background: rgba($color: #868686, $alpha: 0.95);

              .close-button {
                background-color: rgba($color: #142255, $alpha: 0.95);
              }

              .navigation-links {
                background: rgba($color: #142255, $alpha: 0.95);
              }

              .theme-control-tools {
                background: rgba($color: #142255, $alpha: 0.95);
              }

              .website-setting-tools {
                background: rgba($color: #142255, $alpha: 0.95);
              }

              .mobile-online-stats {
                padding-top: 11px;
                padding-bottom: 11px;
                text-align: center;

                .mobile-online-stats-wrapper {
                  background: rgba(255, 255, 255, 0.5);

                  .online-stats-text {
                    color: rgba($color: #000000, $alpha: 0.7);
                  }

                  .online-stats-count {
                    color: #000000;

                  }

                  .online-stats-count {
                    color: #000000;
                  }
                }
              }
            }

          }

        }
      }
    }
  }

  .top-filter-dropdown {
    & button {
      color: #fff ;
    }
    & button:hover {
      color: #000 !important;
    }
  }
  .top-time-dropdown {
    & button {
      color: #fff ;
    }
    & button:hover {
      color: #000 !important;
    }
  }
}

.root-class-design20-light {
  @media screen and (orientation : portrait) {
    .root-class-design20-fullscreen-menu {
      .game-bg {
        background-image: url('../../../images/redesign/mobile/background/game-lobby-light-bg.jpg');
      }
    }

    .top-row {
      .mobile-top-setting {
        .chat-link {
          background-color: rgba($color: #868686, $alpha: 0.2);
        }

        .dropdown-menu-link {
          background-color: rgba($color: #868686, $alpha: 0.2);
        }
      }
    }

    .layout-mobile {

      &-subheader {
        color: #000000 !important;
        background-color: #FFFFFF;

        &-link-text {
          color: #0c0c0c !important;
          opacity: 0.7;
        }

        &-navmenu-link-text {
          color: #0c0c0c !important;
          opacity: 0.7;
        }
      }

      .layout-mobile-header {
        background: linear-gradient(180deg, #878787 0%, #FFFFFF 100%);

        .layout-mobile-header-wrapper {
          .layout-header-link {
            .layout-header-link-text-active.layout-header-link-text {
              color: #000000 !important;
              background-color: #FFFFFF;

              &::after,
              &::before {
                content: '';
                background-image: url('../../../images/redesign/mobile/icons/menu-shadow-light-icon.jpg');
              }
            }

            &-text {
              color: #0C0C0C !important;
            }
          }
        }
      }

      .layout-mobile-body-content {
        .layout-mobile-body-pane {
          .layout-mobile-body {
            .layout-mobile-body-top {
              .layout-mobile-subheader {
                background-color: #fff !important;

                .layout-mobile-subheader-navmenu {
                  color: #000 !important;

                  .layout-mobile-subheader-navmenu-link-text {
                    color: #000 !important;
                  }

                  .layout-mobile-subheader-navmenu-link-text-active {
                    color: #000 !important;
                    border-bottom: 1px solid #FF7A00;
                  }
                }
              }
            }

            .layout-mobile-body-main {
              background-color: rgba($color: #ECECEC, $alpha: 0.95) !important;
            }
          }
        }
      }

      .layout-mobile-footer {
        background-color: rgba($color: #ECECEC, $alpha: 0.95);

        .layout-mobile-footer-bonus {
          .layout-mobile-footer-bonus-link {
            .layout-mobile-footer-link-button {
              border: 1px solid #ECECEC !important;
            }
          }
        }

        .layout-mobile-footer-shop {
          .layout-mobile-footer-shop-link {
            .layout-mobile-footer-link-button {
              border: 1px solid #ECECEC !important;
            }
          }
        }

        .layout-mobile-footer-profile {
          .player-info {
            .player-info-player {
              .player-info-player-background {
                .player-info-player-name-wrapper {
                  background: #fff !important;
                }

                .player-info-player-image-wrapper {
                  .player-info-help-icon {
                    top: 15px;
                    left: 27px;
                    width: 44px;
                    height: 44px;
                  }

                  .player-edit-pencil {
                    background: linear-gradient(180deg, #FFFFFF 0%, #DFDFDF 100%) !important;
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25) !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal {
      .mobile-dropdown-menu-modal {
        .modal-content {
          .modal-body {
            .dropdown-menu-modal-content {
              background: rgba($color: #ECECEC, $alpha: 0.95);

              .close-button {
                background-color: rgba($color: #ECECEC, $alpha: 0.7);
                border-radius: 50%;

                img {
                  filter: invert(100%);
                }
              }

              .navigation-links {
                background: rgba($color: #fff, $alpha: 0.95);

                a {
                  span {
                    color: #0C0C0C;
                  }
                }

                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
              }

              .theme-control-tools {
                background: rgba($color: #fff, $alpha: 0.95);
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                .theme-control-tool-section {
                  .switch-select {
                    .switch-select-option {
                      background: none !important;
                      box-shadow: none !important;
                    }

                    .switch-select-option-active {
                      background: rgba($color: #868686, $alpha: 0.5) !important;
                    }
                  }

                }

                .migrate-to-old-design-section {
                  color: rgba($color: #0C0C0C, $alpha: 0.5);
                  cursor: pointer;
                }

                .logout-section {
                  color: rgba($color: #0C0C0C, $alpha: 0.5);
                  cursor: pointer;
                }
              }

              .website-setting-tools {
                background: rgba($color: #fff, $alpha: 0.95);
                box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.7);

                .extra-tools {
                  .setting-section {
                    img {
                      opacity: 0.5;
                      filter: invert(100%);
                      cursor: pointer;
                    }
                  }

                  .sound-setting {
                    .sound-button {
                      img {
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }

              .mobile-online-stats {
                .mobile-online-stats-wrapper {
                  background: rgba($color: #fff, $alpha: 0.5);
                  border-radius: 88px;

                  .online-stats-text {
                    color: rgba($color: #000000, $alpha: 0.7);
                  }

                  .online-stats-count {
                    color: #000;

                  }

                  .online-stats-count {
                    color: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
    .top-filter-dropdown {
      & button {
        color: #000 !important;
      }
      & button:last-child {
        color: #fff !important;
      }
    }
    .top-time-dropdown {
      & button {
        color: #000 !important;
      }
      & button:last-child {
        color: #fff !important;
      }
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .root-class-design20-fullscreen-menu {
      .game-bg {
        background-image: url('../../../images/redesign/mobile/background/game-lobby-light-landscape-bg.jpg');
      }
    }

    .layout-mobile {
      .landscape-layout-mobile-header {
        .landscape-layout-mobile-header-wrapper {
          background: linear-gradient(90deg, #878787 0%, #ffffff 100%);

          .bottom-part {
            background: linear-gradient(90deg, #878787 0%, #ffffff 100%);
          }

          .landscape-layout-mobile-header-links {
            .landscape-layout-mobile-header-link-item {
              .navbar-collapse {
                .create-room-button {
                  border: none !important;
                }
              }
            }

            .landscape-layout-mobile-header-link-item.landscape-layout-mobile-header-link-item-active {
              background: #ECECEC;
            }
          }
        }
      }

      .layout-mobile-body-content {
        .layout-mobile-body-pane {
          .layout-mobile-body {
            .layout-mobile-body-main {
              background-color: rgba($color: #ECECEC, $alpha: 0.95);

              .layout-mobile-body-title {
                color: #FF8A1A;
              }

              .layout-mobile-body-main-scrollarea {
                .menu-table-row-wrapper {
                  .menu-table-row-inner-wrapper {
                    background-color: rgba(0, 0, 0, 0.03);
                  }
                }
              }
            }
          }
        }
      }

      .layout-mobile-footer {
        .layout-mobile-footer-profile {
          .player-info {


            .player-info-player {
              .player-info-player-background {
                .player-info-player-image-wrapper {
                  .player-edit-pencil {
                    background: linear-gradient(180deg, #FFFFFF 0%, #DFDFDF 100%) !important;
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25) !important;
                  }

                  .player-info-help-icon {
                    position: absolute;
                    top: 15px;
                    left: 26px;
                    width: 44px;
                    height: 44px;
                    z-index: 200;
                  }
                }


                .player-info-player-name-wrapper {
                  background: #ffffff;

                  .player-info-player-name {
                    color: #000 !important;
                  }
                }

              }
            }
          }
        }
      }
    }

    .modal {
      .modal-dialog.mobile-dropdown-menu-modal {
        .modal-content {
          .modal-body {
            .dropdown-menu-modal-content {
              background: rgba($color: #ECECEC, $alpha: 0.95);

              .close-button {
                background-color: rgba($color: #FFFFFF, $alpha: 0.7);

                img {
                  filter: invert(100%);
                }
              }

              .navigation-links {
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
                background: rgba($color: #FFFFFF, $alpha: 0.95);

                a {
                  span {
                    color: rgba($color: #0C0C0C, $alpha: 0.7);
                  }
                }


              }

              .theme-control-tools {
                background: rgba($color: #FFFFFF, $alpha: 0.95);
                border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

                .theme-control-tool-section {
                  .switch-select {
                    .switch-select-option {
                      background: none !important;
                      box-shadow: none !important;
                    }
                  }

                }

                .migrate-to-old-design-section {
                  color: rgba($color: #0C0C0C, $alpha: 0.5);
                  cursor: pointer;
                }

                .logout-section {
                  color: rgba($color: #0C0C0C, $alpha: 0.5);
                  cursor: pointer;
                }
              }

              .website-setting-tools {
                background: rgba($color: #FFF, $alpha: 0.95);

                .extra-tools {
                  .setting-section {
                    img {
                      filter: invert(100%);
                      cursor: pointer;
                    }
                  }

                  .sound-setting {
                    .sound-button {
                      img {
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }

              .mobile-online-stats {
                .mobile-online-stats-wrapper {
                  background: rgba(255, 255, 255, 0.5);

                  .online-stats-text {
                    color: rgba($color: #000000, $alpha: 0.7);
                  }

                  .online-stats-count {
                    color: #000000;

                  }

                  .online-stats-count {
                    color: #000000;
                  }
                }
              }
            }

          }

        }
      }
    }
    .top-filter-dropdown {
      & button {
        color: #000 ;
      }
      & button:last-child {
        color: #fff ;
      }
    }
    .top-time-dropdown {
      & button {
        color: #000 ;
      }
      & button:last-child {
        color: #fff ;
      }
    }
  }
}
