.admin-panel {
//  width: 1200px!important;

  &-nav-link {
    cursor: pointer;
    color: #fff !important;
  }
}

.admin-container {
  position: relative;
  min-height: 100vh;
}


.admin-statistics-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;

  &-coins-per-change {

  }

  &-subheaders {
    & th {
      border: 1px solid #FFFFFF;
      min-width: 100px;
    }
  }

  &-popover {
    width: 350px;
  }

  &-submenu {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    border-bottom: red;
  }

  & td {
    border: 1px solid #FFF;
    text-align: left;
    font-size: 12px;
  }
}

.frame-object {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: scale-down;
}

.texteditor-table-col {
  & .editor-readOnly {
    height: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.supicious-transactions-label {
  margin-left: 100px;
}

.table-id {
  width: 40px !important;
}

.hint-image {
  display: flex;
  flex-direction: row;
}

.LV-section {
  width: 33%;
  position: relative;
  & .event-banner-edit {
    position: absolute;
    top: 0px;
    right: 50px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-cancel {
    position: absolute;
    top: 0px;
    right: 20px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }

  & .event-banner-image {
    width: 86%;
    height: 150px;
    object-fit: contain;
    border-radius: 15px;
  }
}

.EN-section {
  width: 33%;
  position: relative;
  & .event-banner-edit {
    position: absolute;
    top: 0px;
    right: 50px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-cancel {
    position: absolute;
    top: 0px;
    right: 20px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-image {
    width: 86%;
    height: 150px;
    object-fit: contain;
    border-radius: 15px;
  }
}

.RU-section {
  width: 33%;
  position: relative;
  & .event-banner-edit {
    position: absolute;
    top: 0px;
    right: 50px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-cancel {
    position: absolute;
    top: 0px;
    right: 20px;
    left: unset;
    & img {
      width: 20px;
      height: 20px;
    }
  }
  & .event-banner-image {
    width: 86%;
    height: 150px;
    object-fit: contain;
    border-radius: 15px;
  }
}

.border-1 {
  border: 1px solid #fff;
}

.border-bottom-1 {
  border-bottom: 1px solid #fff;
}

.statistics-browser-popver {
  max-width: unset;
  width: auto;
}

.selected-row td{
  border: 2px solid #65aba1 !important;
}
