
.root-class-design20 {

  .user-settings {
    &-outer-row-wrapper {
      margin: 0 !important;

      &-normal {
        margin-left: 15px !important;
        margin-right: 15px !important;
      }
    }
    &-header {
      margin-bottom: 2vmin;
      font-weight: 600;
      font-size: var(--headerFontSize);
    }

    &-divider {
      margin-bottom: 12px;
      padding-bottom: calc(12px - 1.5vmin);
      margin-left: 15px;
      width: 68%;
      min-width: 200px;
      max-width: 270px;
      border-color: rgba($color: #fff, $alpha: 0.1);
    }

    &-row {
      margin-bottom: 1.5vmin;

      .user-settings-label {
        font-family: 'Open Sans';
          font-weight: 600;
          font-size: var(--headerFontSize);
          line-height: 140%;
          margin-left: 0;
      }
      .switch-text {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: var(--headerFontSize);
        line-height: 160%;
        &-small {
          font-size: var(--defaultFontSize);
        }
      }
      & .switch-text-inline {
        display: inline;
      }
      .switch-text-wrapper {
        padding-left: 6px;
        display: flex;
        align-items: center;

        .switch-text.disable {
          color: $darkDefaultTextColor;
        }
        .switch-text.active {
          color: $normalTextColour;
        }
      }
    }
  }
}
