$nameColor: #0c0c0c;
$bgColor: #fff;
$commentColor: #868686;
$fontSize: 1.2vmin;
$mobileXlSize: 1160px;
$mobileLgSize: 1012px;
$mobileMdSize: 900px;
$mobileSmSize: 574px;
$mobileSmHeight: 600px;

.root-class-design20 {
  .new-design-landing {
    width: 100% !important;
    height: auto !important;
    padding: 0px !important;

    &-header {
      z-index: 99999 !important;
      & .top-settings-new {
        &-section {
          &-fullscreen-button {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }

    &-mobile-header {
      display: none;
    }

    &-mobile-navigation {
      position: fixed;
      bottom: 0px;
      z-index: 100000;
      display: flex;
      width: 100%;
      height: 57px;
      display: none;

      & .mobile-navigation-logo {
        position: absolute;
        left: calc(50% - 32px);
        width: 63px;
        height: 78px;
        top: -20px;
        z-index: 999;
      }

      & .left-part {
        width: 50%;
        background: linear-gradient(180deg, #181818 0%, #000000 100%);
        border-radius: 8px 33px 0px 0px;
        height: 100%;

        & button {
          width: 118px;
          height: 30px;
          margin-left: 20px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: var(--defaultFontSize);
          line-height: 18px;
          text-align: center;
          text-transform: uppercase;
        }
      }

      & .right-part {
        width: 50%;
        background: linear-gradient(180deg, #181818 0%, #000000 100%);
        border-radius: 33px 8px 0px 0px;
        height: 100%;
        position: relative;

        & .return-home {
          position: absolute;
          right: 35px;
          width: 28px;
          height: 28px;
          cursor: pointer;
        }

        & img {
          margin-right: 20px;
          width: 18px;
          height: 16px;
        }

        @media only screen and (max-width: $mobileSmSize) {
          & img {
            margin-right: 32px;
          }
        }
      }
    }

    &-mobile-navigation-section {
      position: fixed;
      width: 100%;
      height: 600px;
      bottom: 140px;
      z-index: 99999;
      background-color: #000000;
      display: none;

      & .mobile-navigation-container {
        height: 100%;
        max-height: 568px;
        width: 100%;

        &-body {
          width: 100%;
          height: 100%;
          padding: 0px;

          & .mobile-navigation-container-body-area {
            height: 100%;
          }

          & .mobile-navigation-container-body-area-content {
            padding: 0;
            margin: 0;
            display: block !important;
          }

          & .mobile-navigation-link {
            background: #0c0c0c;
            box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.8);
            padding-top: 50px;
            margin-top: 70px;
          }

          & .top-settings-new-section {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: var(--headerFontSize);
            line-height: 22px;
            text-align: center;
            text-transform: uppercase;
            color: #acacac;
            margin-bottom: 30px;

            & .top-settings-new-divider {
              display: inline-block;
              background-image: url("../../../../images/redesign/common/karavs.svg");
              background-size: 12px 12px;
              background-repeat: no-repeat;
              background-position: center;
              width: 12px;
              vertical-align: middle;
              height: 12px;
              margin-bottom: 18px;
            }
          }

          & .top-settings-new-section:nth-of-type(6) {
            margin-bottom: 25px;
          }

          & .mobile-navigation-language {
            display: flex;
            align-items: center;
            width: 100%;

            & div {
              width: 33.3%;
            }

            & img {
              padding: 10px;
            }
          }
        }
      }
    }

    & .display-hide {
      display: none;
    }

    & .display-hide {
      display: flex;
    }

    & .no-landing-mobile-header {
      display: none;
    }

    @media only screen and (max-height: $mobileSmHeight) {
      &-mobile-navigation-section {
        display: flex;
        height: 300px;
        bottom: 50px !important;
      }
    }

    @media only screen and (max-width: $mobileSmSize) {
      &-header {
        display: none;
      }

      &-mobile-header {
        display: flex;
        width: 100%;
        height: 120px;
        top: 0px !important;

        & .logo-wrapper {
          width: 100%;
          height: 100%;

          & a {
            width: 100px;
            height: 55px;
          }
        }
      }

      & .no-landing-mobile-header {
        display: none;
      }


      &-mobile-navigation-section {
        display: flex;
        bottom: 50px !important;
      }

      &-mobile-navigation {
        display: flex;
      }
    }



    @media only screen and (orientation: landscape) and (max-width: 992px) {
      &-mobile-navigation {
        display: flex;
        right: 0;
        width: 100vh;
        rotate: -90deg;
        top: calc(50vh - 28.5px);
        margin-right: calc(-50vh + 28.5px);
        max-width: 420px;

        a {
          img.mobile-navigation-logo {
            rotate: 90deg;
          }

          img.mobile-landscape-navigation-logo {
            display: block !important;
            position: absolute;
            left: calc(50% - 29px);
            width: 63px;
            height: 78px;
            top: -23px;
            z-index: -1;
            rotate: 90deg;
          }
        }

        div.right-part {
          img {
            rotate: 90deg;
          }
        }

        div.left-part {
          .layout-highlighted-button {
            display: none !important;
          }

          .mobile-landscape-login-button {
            display: flex !important;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              rotate: 90deg;
            }
          }
        }
      }
    }

    & .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
      padding: 0px !important;
    }

    &-container {
      width: 100%;
      height: auto;
      max-width: 100vw !important;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0px;
      position: relative;
      justify-self: center;
      align-items: center;

      &-sign {
        width: 100%;
        min-height: 1012px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &-bg {
          background-image: url("../../../../images/redesign/landing-view/new_bg_1.webp");
          width: 100%;
          min-height: 1012px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          position: absolute;
          top: 0px;
          left: 0px;
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
          &-bg {
            background-position: initial;
            background-size: contain;
          }
        }

        @media screen and (max-width: $mobileSmSize) {
          &-bg {
            background-image: url("../../../../images/redesign/landing-view/new_bg_mobile_1.webp");
            background-size: contain;
            min-height: 450px;
          }
        }


        &-dg {
          width: 100%;
          min-height: 261px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          bottom: 0px;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          transform: matrix(1, 0, 0, -1, 0, 0);
        }

        &-body {
          position: absolute;
          top: 0px;
          width: 75%;
          min-height: 1012px;
          max-width: 1100px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          & .sign-row {
            width: 100%;
            margin-top: 220px;
            height: 60%;

            &-content {
              max-width: 500px;
              min-height: 400px;

              &-title {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: var(--xxxxLargeFontSize);
                line-height: 110%;
                color: #ffc900;
                margin-bottom: 20px;
              }

              &-description {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: var(--xxLargeFontSize);
                line-height: 140%;
                color: #ffffff;
              }

              &-button {
                height: 47px;
                min-width: 172px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 700;
                font-size: var(--headerFontSize);
                line-height: 23px;
                text-transform: uppercase;
                color: #000000;
              }

              &-register-button {
                flex-direction: column;
                padding: 12px 18px;
                gap: 10px;

                // width: 200px;
                // height: 47px;

                background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
                /* Light Black */

                border: 1px solid #0c0c0c;
                border-radius: 99px;
                position: relative;

                & label {
                  // width: 118px;
                  // height: 23px;
                  padding: 0 15px;
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: var(--headerFontSize);
                  line-height: 23px;
                  text-align: center;
                  text-transform: uppercase;
                  color: #ffffff;
                  margin-bottom: 0px;
                  cursor: pointer;
                }
              }

              &-register-button::after {
                position: absolute;
                right: 15px;
                content: " ";
                background-image: url("../../../../images/icons/tree-svg.svg");
                background-size: 8px 8px;
                height: 8px;
                width: 8px;
              }

              &-register-button::before {
                position: absolute;
                left: 15px;
                content: " ";
                background-image: url("../../../../images/icons/tree-svg.svg");
                background-size: 8px 8px;
                height: 8px;
                width: 8px;
              }
            }

            &-slide {
              min-height: 320px;
              max-width: 450px;
              min-width: 450px;
              margin-top: -50px;
              position: relative;
              width: 100%;

              & .splide__slide {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              & .splide__slide img {
                width: 100%;
                height: 100%;
                padding-left: 20px;
                padding-right: 20px;
              }

              @media only screen and (max-width: $mobileSmSize) {
                & .splide__slide img {
                  padding-left: 0;
                  padding-right: 0;
                }
              }

              & .splide__arrow--prev {
                left: -1em;
              }

              & .splide__arrow--next {
                right: -1em;
              }

              & .splide__pagination {
                background: rgba(0, 0, 0, 0.5);
                width: auto;
                padding: 5px;
                border-radius: 30px;
                bottom: -0.5rem;

                & .is-active {
                  background-image: url("../../../../images/redesign/landing-view/slidePaginationActive.svg") !important;
                }
              }

              & .splide__pagination__page {
                background-image: url("../../../../images/redesign/landing-view/slidePagination.svg") !important;
                width: 11px;
                height: 13px;
                background: transparent;
              }

              &-content {
                position: absolute;
                top: 40px;
                left: 50px;

                &-title {
                  max-width: 250px;
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--xxLargeFontSize);
                  line-height: 140%;
                  color: #000000;
                }

                &-description {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--headerFontSize);
                  line-height: 160%;
                  color: #0c0c0c;
                  max-width: 225px;
                }

                &-footer {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--smallFontSize);
                  line-height: 160%;
                  color: #0c0c0c;
                }
              }
            }

            &-banner {
              // position: absolute;
              // bottom: -100px;
              z-index: 9998;
              width: 100%;
              height: 40%;
              margin-top: 100px;

              &-slide {
                min-height: 370px;
                margin-top: -50px;
                position: relative;

                & .splide__list {
                  li {
                    margin-right: 20px !important;
                  }
                }

                & .splide__arrow--prev {
                  left: -2em;
                }

                & .splide__arrow--next {
                  right: -2em;
                }

                & .splide__pagination {
                  background: rgba(0, 0, 0, 0.5);
                  width: auto;
                  padding: 5px;
                  border-radius: 30px;
                  bottom: -2.5em;

                  & .is-active {
                    background-image: url("../../../../images/redesign/landing-view/slidePaginationActive.svg") !important;
                  }
                }

                & .splide__pagination__page {
                  background-image: url("../../../../images/redesign/landing-view/slidePagination.svg") !important;
                  width: 11px;
                  height: 13px;
                  background: transparent;
                  margin-left: 5px;
                  margin-right: 5px;
                }

                & .home-game-slide {
                  width: 100%;
                  // max-width: 340px;
                  min-height: 320px;
                  position: relative;

                  & .slide-img {
                    width: 100%;
                    height: 100%;
                    min-height: 320px;
                    // max-width: 340px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 0px 22px 22px 22px;
                  }

                  & .splide-img-dg {
                    width: 100%;
                    height: 100%;
                    border-radius: 0px 22px 22px 22px;
                    border: 12px solid rgba(255, 255, 255, 0.1);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  }
                }

                &-content {
                  position: absolute;
                  top: 50px;
                  left: 50px;

                  &-title {
                    max-width: 227px;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: var(--xxxLargeFontSize);
                    line-height: 140%;
                    color: #000000;
                  }

                  &-description {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--headerFontSize);
                    line-height: 160%;
                    color: #0c0c0c;
                    max-width: 175px;
                  }

                  &-footer {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--smallFontSize);
                    line-height: 160%;
                    color: #0c0c0c;
                  }
                }
              }
            }
          }

          @media only screen and (max-width: $mobileLgSize) {
            & .sign-row {
              margin-top: 160px;
            }
          }

        }

        @media only screen and (max-width: $mobileLgSize) {
          & .new-design-landing-container-sign-body {
            & .sign-row-start-game {
              justify-content: center !important;
              align-items: center !important;
            }

            & .sign-row-content {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: column;
            }
          }
        }

        @media only screen and (max-width: $mobileSmSize) {
          & .new-design-landing-container-sign-body {
            width: 100% !important;

            & .sign-row-content {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: column;
              min-height: 270px !important;

              & .sign-row-content-title {
                font-size: var(--xxLargeFontSize) !important;
              }

              & .sign-row-content-description {
                font-size: var(--xLargeFontSize) !important;
                text-align: center;
              }
            }

            & .sign-row-slide {
              min-height: 267px !important;
              min-width: 375px !important;

              & .splide__arrows {
                display: none !important;
              }

              & .splide__pagination {
                bottom: -0.5rem;
              }

              & .sign-row-slide-content-title {
                font-size: var(--xxLargeFontSize);
              }

              & .sign-row-slide-content-description {
                font-size: var(--largeFontSize);
              }
            }

            & .sign-row-banner {
              & .splide__arrows {
                display: none !important;
              }

              & .splide__pagination {
                bottom: -2.5rem !important;
              }
            }
          }
        }

        @media only screen and (orientation: landscape) and (max-width: 992px) {
          & .new-design-landing-container-sign-body {
            width: 85%;
            & .sign-row {
              margin-top: 0;
            }
            & .sign-row-content {
              align-items: flex-start;
              & .sign-row-content-title {
                font-size: var(--xxxLargeFontSize) !important;
              }
              & .sign-row-content-description {
                font-size: var(--xLargeFontSize) !important;
              }
            }
            & .sign-row-slide {
              min-height: 100%;
              min-width: 100%;
              & .sign-row-slide-content {
                left: 30px;
                top: 10px;
                & .sign-row-slide-content-title {
                  font-size: var(--xLargeFontSize);
                  max-width: 140px;
                }
                & .sign-row-slide-content-description {
                  font-size: var(--largeFontSize);
                  max-width: 140px;
                }
                & .sign-row-slide-content-button {
                  margin-top: 0 !important;
                }
                & .sign-row-slide-content-footer {
                  margin-top: 0px !important;
                  max-width: 170px;
                  line-height: 1;
                }
              }
            }
            & .sign-row-banner {
              margin-top: 0;
            }
          }
        }
      }

      &-top {
        width: 100%;
        min-height: 980px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &-bg {
          background-image: url("../../../../images/redesign/landing-view/game_screen_bg.webp");
          width: 100%;
          min-height: 980px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          top: 0px;
          left: 0px;
        }

        &-dg {
          width: 100%;
          min-height: 373px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          top: 0px;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        }

        &-bg-table {
          width: 100%;
          min-height: 850px;
          max-width: 1100px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 0px;
          background-image: url("../../../../images/redesign/landing-view/table_2.webp");
        }

        &-list {
          margin-top: 400px;
          z-index: 9999;
          position: relative;
          width: 75%;
          max-width: 1100px;

          & .menu-table-row-filler-out {
            height: 20px;
            border-radius: 17px;
          }

          & .layout-body-background {
            border-radius: 17px;
          }

          & .top-table-row {
            height: 100%;

            & .landing-top-list-body-position {
              font-family: "Futura PT";
              font-weight: 450;
              font-size: var(--largeFontSize);
              opacity: 0.5;
              font-style: normal;
              min-width: 50px;
              width: 7%;
            }

            & .landing-top-list-body-player {
              width: 22%;
              min-width: 180px;

              & .top-table-row-player {
                display: flex;
                align-items: center;

                & .top-table-row-text {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--defaultFontSize);
                  line-height: 160%;
                  color: #ffffff;
                  line-height: 1;
                  height: auto;
                }
              }
            }

            & .landing-top-list-body-level {
              min-width: 160px;
              width: 14%;
            }

            & .menu-table-row-player-icon-wrapper-landing {
              display: inline-block;
              width: 20%;
            }

            & .landing-top-list-body-column-rating {
              min-width: 76px;
              width: 14%;

              & .menu-table-row-player-icon-wrapper {
                & img {
                  height: 21px;
                  width: 29.08px;
                  padding: 0;
                  margin-right: 1px;
                }

                & .menu-table-row-player-icon-text {
                  font-style: normal;
                  color: #fff;
                  font-weight: 600;
                  line-height: 160%;
                }
              }
            }

            & .landing-top-list-body-column-balance {
              min-width: 110px;
              width: 14%;

              & .menu-table-row-player-icon-wrapper {
                & img {
                  width: 23.58px;
                  height: 16.79px;
                  padding: 0;
                  margin-right: 1px;
                }
              }
            }

            & .landing-top-list-body-column-points {
              width: 12%;
              min-width: 56px;
              font-style: normal;
              font-weight: 600;
              line-height: 160%;
              color: #fff;
            }

            & .landing-top-list-body-column-rounds {
              width: 12%;
              min-width: 100px;
              font-style: normal;
              font-weight: 600;
              line-height: 160%;
              color: #fff;
            }

            & .landing-top-list-body-column-rounds {
              width: 13%;
              min-width: 70px;
            }

            & .landing-top-list-body-column-extra {
              width: 13%;
              min-width: 70px;
              font-style: normal;
              line-height: 160%;
            }

            & .landing-top-list-body-column-btn {
              min-width: auto;

              & button {
                width: 38px;
                height: 38px;
                background: linear-gradient(180deg, #333333 0%, #121212 100%);
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
                border: none;
              }
            }

            .top-table-row-mobile-text-group {
              display: none;
            }
            @media only screen and (max-width: $mobileMdSize) {
              & .landing-top-list-body-column-extra {
                display: none;
              }

              &>.landing-top-list-body-player {
                width: 100%;
              }

              &>.landing-top-list-body-player {
                & .top-table-row-player {
                  >.top-table-row-text {
                    display: none;
                  }
                }
              }

              &>.landing-top-list-body-level,
              &>.landing-top-list-body-column-rating,
              &>.landing-top-list-body-column-balance,
              &>.landing-top-list-body-column-points,
              &>.landing-top-list-body-column-rounds {
                display: none;
              }

              & .landing-top-list-body-column-points-md,
              & .landing-top-list-body-column-rounds-md {
                display: inline-block;
                width: 80px;
                font-style: normal;
                font-weight: 600;
                line-height: 160%;
                color: #fff;
              }

              & .menu-table-row-player-icon-wrapper-landing {
                width: 80px;
              }
              & .landing-top-list-body-column-extra-md {
                display: inline-block;
                width: 100px;
              }

              .top-table-row-player {
                display: flex;
              }

              .top-table-row-mobile-text-group {
                width: 100%;
                display: flex;
                flex-direction: column;

                .top-table-row-mobile-first-line {
                  display: flex;
                  align-self: flex-start;
                  align-items: center;
                  column-gap: 8px;
                }

                .top-table-row-mobile-second-line-landing {
                  width: 100%;
                }

                .top-table-row-mobile-second-line {
                  display: flex;
                  column-gap: 13px;

                  .landing-top-list-body-column-points,
                  .landing-top-list-body-column-rounds {
                    width: auto;
                    min-width: auto;
                    font-weight: 400;
                    line-height: 150%;
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }

          }

          & .layout-body {
            background-color: rgba($color: #000, $alpha: 0.95);

            &-ok-button {
              background-color: #171717 !important;
            }

            &-radio {          
              &-outer-circle {
                float: right;
                margin: 3px;
                width: 14px;
                height: 14px;
                border: 1px solid #7D7D7D;
                border-radius: 50%;
              }
          
              &-inner-circle {
                margin: 3px;
                width: 6px;
                height: 6px;
                background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
                border-radius: 50%;
              }
            }

            &-sorting-arrow {
              margin: 5px;
              border: solid #7D7D7D;
              border-width: 0 3px 3px 0;
              padding: 3px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              float: right;
            }

            &-sorting-arrow-active {
              margin: 5px;
              border: solid red;
              border-width: 0 3px 3px 0;
              padding: 3px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              float: right;
            }

            & .menu-table-row-wrapper {
              background-color: rgba(0, 0, 0, 0.01);
              min-height: auto !important;
            }

            &-list-header {
              width: 100%;
              height: 48px;
              position: absolute;
              top: -32px;
              z-index: 999;

              &-title {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: var(--xxLargeFontSize);
                line-height: 140%;
                text-align: center;
                color: #000000;
                background-color: #ffffff;
                border-radius: 88px;
                padding: 7px 20px;
                position: relative;
              }

              &-title:before {
                position: absolute;
                left: -70px;
                top: 0px;
                content: " ";
                background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
                background-size: 65px 65px;
                height: 65px;
                width: 65px;
              }

              &-title:after {
                position: absolute;
                right: -70px;
                top: 0px;
                content: " ";
                background-image: url("../../../../images/redesign/components/modal/modal_stars.png");
                background-size: 65px 65px;
                height: 65px;
                width: 65px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
              }
            }

            &-top {
              height: auto;

              & .layout-subheader {
                margin-left: 15px !important;
                margin-right: 15px !important;
                padding-top: 50px !important;

                & .switch-select {
                  & .switch-select-option {
                    padding: 9px 15px;
                  }
                }
              }
            }

            &-main {
              box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);

              // pointer-events: none;
              & .landing-top-list-header {
                margin-top: 25px;
                margin-bottom: 16px;

                & .landing-top-list-header-position,
                & .landing-top-list-header-player,
                & .landing-top-list-header-level,
                & .landing-top-list-header-mobile,
                & .landing-top-list-header-column-rating,
                & .landing-top-list-header-balance,
                & .landing-top-list-header-points,
                & .landing-top-list-header-rounds {
                  font-family: "Futura PT";
                  font-style: normal;
                  font-weight: 450;
                  font-size: var(--largeFontSize);
                  line-height: 18px;
                  color: #fff;
                }

                & .landing-top-list-header-extra {
                  font-family: "Futura PT";
                  font-style: normal;
                  font-size: var(--largeFontSize);
                  line-height: 18px;
                }

                & .landing-top-list-header-mobile {
                  display: none;
                }

                & .landing-top-list-header-position {
                  width: 7%;
                  min-width: 50px;
                }

                & .landing-top-list-header-player {
                  width: 22%;
                  min-width: 180px;
                }

                & .landing-top-list-header-level {
                  width: 14%;
                  min-width: 160px;
                }

                & .landing-top-list-header-column-rating {
                  width: 14%;
                  min-width: 76px;
                }

                & .landing-top-list-header-balance {
                  width: 14%;
                  min-width: 110px;
                }

                & .landing-top-list-header-extra {
                  width: 14%;
                  min-width: 70px;
                }

                & .landing-top-list-header-points {
                  width: 12%;
                  min-width: 56px;
                }

                & .landing-top-list-header-rounds {
                  width: 13%;
                  min-width: 70px;
                }
              }

              & .menu-table-row-wrapper {
                padding-left: 34px !important;
                padding-right: 34px !important;
                padding-bottom: 1px !important;

                & .menu-table-row-inner-wrapper {
                  background-color: rgba($color: #fff, $alpha: 0.03);
                }
              }

              @media only screen and (max-width: $mobileSmSize) {
                & .menu-table-row-wrapper {
                  padding-left: 20px !important;
                  padding-right: 20px !important;

                  & .menu-table-row-inner-wrapper {
                    padding-left: 3px;
                    padding-right: 2px;
                  }
                }

              }

              & .layout-subheader-filterTab {
                & .switch-select {
                  display: block;
                }

                & .custom-dropdown {
                  display: none;
                }
              }

              & .top-table-scrollarea {
                & .menu-table-row-wrapper {

                  & .top-table-row-player-image-frame {
                    width: 36px;
                    height: 36px;
                    margin: unset;

                    & img {
                      width: 36px;
                      height: 36px;
                    }
                  }

                  @media only screen and (max-width: $mobileSmSize) {
                    & .top-table-row-player-image-frame {
                      margin-right: 5px !important;
                    }
                  }
                }
              }
            }
          }

          & .layout-body::before {
            position: absolute;
            left: -200px;
            bottom: 0px;
            content: " ";
            background-image: url("../../../../images/redesign/landing-view/landing_modal_stars.svg");
            background-size: 300px 300px;
            height: 300px;
            width: 300px;
          }

          & .layout-body::after {
            position: absolute;
            right: -200px;
            bottom: 0px;
            content: " ";
            background-image: url("../../../../images/redesign/landing-view/landing_modal_stars.svg");
            background-size: 300px 300px;
            height: 300px;
            width: 300px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }

        &-footer {
          min-height: 250px;
          width: 100%;
          background-color: #0a0a0a;
          position: absolute;
          bottom: 0px;
        }

        &-footer::before {
          content: "";
          position: absolute;
          min-height: 261px;
          width: 100%;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          top: -261px;
          transform: matrix(1, 0, 0, -1, 0, 0);
        }
      }
      @media only screen and (max-width: $mobileMdSize) {
        & .landing-top-list-header-mobile {
          display: flex !important;
          width: auto;
        }

        & .landing-top-list-header-position {
          opacity: 0;
        }

        & .landing-top-list-header-player {
          width: 36px !important;
          min-width: 36px !important;
          margin-right: 0.5rem;
          overflow: hidden;
          white-space: nowrap;
          opacity: 0;
        }

        & .landing-top-list-header-level,
        & .landing-top-list-header-column-rating,
        & .landing-top-list-header-column-balance,
        & .landing-top-list-header-balance,
        & .landing-top-list-header-points,
        & .landing-top-list-header-rounds,
        & .landing-top-list-header-extra {
          display: none !important;
        }

        & .landing-top-list-header-column-rating-md,
        & .landing-top-list-header-column-balance-md,
        & .landing-top-list-header-column-points-md,
        & .landing-top-list-header-column-rounds-md {
          width: 80px !important;
          display: inline-block !important;
        }

        & .landing-top-list-header-column-extra-md {
          width: 20% !important;
          display: inline-block !important;
          color: #8D8E90;
        }

      }

      @media only screen and (max-width: $mobileXlSize) {
        &-top {
          &-list {
            margin-top: 160px;
            width: 100%;

            & .layout-body {
              &-top {

                & .layout-subheader {
                  &-filterType {
                    width: 100% !important;
                    margin-bottom: 10px !important;

                    & button {
                      width: 100%;
                      opacity: 1;
                      font-family: "Open Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: var(--defaultFontSize);
                      line-height: 16px;
                    }
                  }

                  &-filterTab {
                    width: 100% !important;
                    padding-left: 0px !important;
                    margin-bottom: 10px !important;
                    height: 38px !important;

                    & .custom-dropdown {
                      display: block;
                    }

                    & button {
                      width: 100%;
                      opacity: 1;
                      font-family: "Open Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: var(--defaultFontSize);
                      line-height: 16px;
                    }
                  }
                }
              }

              &-main {
                & .top-table-scrollarea {
                  & .top-table-scrollarea-body {
                    height: 475px;

                    &-header {

                      display: flex;
                      flex-wrap: unset !important;
                      word-wrap: break-word;
                      overflow: unset;
                      position: relative;
                      
                      & .landing-top-list-header-column {
                        font-weight: 450;
                        font-size: var(--defaultFontSize);
                        display: flex;
                        align-items: center;
                      }
                    }

                    & .top-table-row {
                      position: relative;

                      & .landing-top-list-body-player {
                        position: absolute;
                        left: 20px;

                        & .top-table-row-player {
                          & .top-table-row-text {
                            position: absolute;
                            left: 46px;
                            top: 0px;
                            font-size: var(--defaultFontSize);
                            color: #ffffff;
                          }
                        }
                      }

                      & .landing-top-list-body-level {
                        position: absolute;
                        left: 175px;
                        top: 3px;
                        font-size: var(--smallFontSize);
                        color: #ffffff;
                        margin-left: 20px;

                        & .my-info-ratings-step {
                          height: 18px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }
                      }

                      & .landing-top-list-body-column {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        width: auto;
                        min-width: unset;
                        font-size: var(--defaultFontSize);
                        color: #ffffff;
                        min-width: 75px;

                        &-rating {
                          margin-left: 50px;
                        }

                        & .menu-table-row-player-icon {
                          padding-left: 0px;
                          padding-right: 0px;
                          width: 15px;
                          height: 15px;
                        }

                        & .menu-table-row-player-icon-text {
                          font-size: var(--defaultFontSize);
                        }
                      }
                    }
                  }
                }

                & .landing-top-list-body-position {
                  min-width: 50px;
                  width: 7%;
                  color: #ffffff;
                  font-size: var(--smallFontSize);

                  @media screen and (max-width: $mobileSmSize) {
                    .layout-divider {
                      display: none;
                    }
                  }
                }
              }

              & .landing-body-top-main {
                width: auto;
                overflow-x: auto;
                overflow-y: hidden;
                display: flex;
              }
            
            }
          }
        }
      }

      &-blog {
        background-color: #0a0a0a;
        position: relative;
        width: 100%;

        &-list {
          width: 75%;
          margin-top: 100px;
          position: relative;
          max-width: 1100px;

          &-title {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: var(--xxLargeFontSize);
            line-height: 140%;
            color: #ffffff;
            margin-bottom: 100px !important;
          }

          @media only screen and (max-width: $mobileSmSize) {
            &-title {
              margin-bottom: 75px !important;
            }
          }

          &-body {
            & .splide {
              margin-top: -50px;
              position: relative;

              & .splide__track#splide04-track {
                padding: 0 10px;
              }

              & .splide__list {
                min-height: 680px;

                & .splide__slide {
                  position: relative;

                  & .slide-img-section {
                    width: calc(100% - 20px);
                    min-height: 240px;
                    position: relative;

                    & img {
                      width: 100%;
                      height: 100%;
                      min-height: 240px;
                      object-fit: cover;
                      object-position: center;
                      border-radius: 0px 22px 22px 22px;
                    }

                    & .splide-img-dg {
                      width: 100%;
                      height: 100%;
                      border-radius: 0px 22px 22px 22px;
                      border: 12px solid rgba(255, 255, 255, 0.1);
                      position: absolute;
                      top: 0px;
                      left: 0px;
                    }
                  }

                  & .splide-slide-content {
                    position: absolute;
                    max-width: 340px;
                    top: 280px;

                    &-title {
                      font-family: "Open Sans";
                      font-style: normal;
                      font-weight: 600;
                      font-size: var(--xxLargeFontSize);
                      line-height: 140%;
                      color: #ffffff;
                    }

                    &-description {
                      font-family: "Open Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: var(--largeFontSize);
                      line-height: 160%;
                      color: #ffffff;
                      height: 200px;
                      overflow: hidden;
                    }

                    &-footer {
                      font-family: "Open Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: var(--smallFontSize);
                      line-height: 160%;
                      color: #0c0c0c;

                      & button {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 6px 15px;
                        gap: 10px;

                        width: 160px;
                        height: 30px;

                        background: rgba(0, 0, 0, 0.4);
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        border-radius: 99px;
                        position: relative;
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 18px;
                        text-transform: uppercase;
                      }

                      & button::after {
                        position: absolute;
                        right: 10px;
                        content: " ";
                        background-image: url("../../../../images/redesign/landing-view/slidePaginationActive.svg");
                        background-size: 8px 8px;
                        height: 8px;
                        width: 8px;
                      }

                      & button::before {
                        position: absolute;
                        left: 10px;
                        content: " ";
                        background-image: url("../../../../images/redesign/landing-view/slidePaginationActive.svg");
                        background-size: 8px 8px;
                        height: 8px;
                        width: 8px;
                      }
                    }
                  }

                  @media only screen and (max-width: $mobileSmSize) {
                    & .splide-slide-content {
                      top: 245px;
                    }
                  }
                }
              }

              @media only screen and (max-width: $mobileSmSize) {
                & .splide__list {
                  min-height: 580px;
                }
              }

              // @media only screen and (max-width: $mobileSmSize) {
              //   & .splide__list {
              //     min-height: 482px;
              //   }
              // }
              & .splide__arrow--prev {
                left: -36px;
                top: 32.8%;
                background-color: #000;
                opacity: 0.3;
                width: 31px;
                height: 31px;

                & svg {
                  fill: #fff;
                }
              }

              & .splide__arrow--next {
                right: -36px;
                top: 32.8%;
                background-color: #000;
                opacity: 0.3;
                width: 31px;
                height: 31px;

                & svg {
                  fill: #fff;
                }
              }

              & .splide__pagination {
                background: rgba(0, 0, 0, 0.5);
                width: auto;
                padding: 5px;
                border-radius: 30px;

                & .is-active {
                  background-image: url("../../../../images/redesign/landing-view/slidePaginationActive.svg") !important;
                }
              }

              & .splide__pagination__page {
                background-image: url("../../../../images/redesign/landing-view/slidePagination.svg") !important;
                width: 11px;
                height: 13px;
                background: transparent;
              }
            }
          }
        }

        @media only screen and (max-width: $mobileSmSize) {
          &-list {
            width: 100%;
            margin-top: 0px;

            &-body {
              & .splide__arrows {
                display: none;
              }

              & .slide-img-section {
                // max-width: 100%;
                min-height: 240px;
                position: relative;
                width: 100% !important;

                & img {
                  width: 100%;
                  height: 100%;
                  min-height: 240px;
                  max-width: 100%;
                  object-fit: cover;
                  object-position: center;
                }

                & .splide-img-dg {
                  width: 100%;
                  height: 100%;
                  border-radius: 0px 22px 22px 22px;
                  border: 12px solid rgba(255, 255, 255, 0.1);
                  position: absolute;
                  top: 0px;
                  left: 0px;
                }
              }
            }
          }
        }
      }

      @media only screen and (orientation: landscape) and (max-width: 992px) {
        &-blog {
          &-list {
            width: 80%;
          }
        }
      }

      &-mobile {
        position: relative;
        width: 100%;
        background-image: url("../../../../images/redesign/landing-view/game_screen_bg.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &-bg {
          min-height: 770px;
          width: 100%;
          background-image: url("../../../../images/redesign/landing-view/table_1.webp");
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;
          margin-top: -80px;
        }

        &-bd {
          background: linear-gradient(180deg, #0a0a0a 0%, rgba(0, 0, 0, 0) 100%);
          min-height: 155px;
          position: absolute;
          top: 0px;
          width: 100%;
        }

        &-list {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          height: 100%;
          backdrop-filter: blur(8px);
          flex-direction: column;

          &-body {
            width: 75%;
            height: 100%;
            max-width: 1100px;

            &-row {
              width: 100%;
              height: 100%;

              &-content {
                max-width: 363px;

                & .title {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--xxxLargeFontSize);
                  line-height: 140%;
                  color: #ffc900;
                }

                & .description {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: var(--xxLargeFontSize);
                  line-height: 140%;
                  color: #ffffff;
                }

                & .footer {
                  display: flex;
                  flex-direction: column;

                  & .header {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: var(--headerFontSize);
                    line-height: 22px;
                    color: #ffc900;
                  }

                  @media only screen and (max-width: $mobileSmSize) {
                    & .header {
                      text-align: center;
                    }
                  }

                  & .bottom {
                    display: flex;
                    flex-direction: row;

                    & img {
                      width: 120px;
                      height: 35px;
                    }
                  }
                }
              }

              // &-adv {
              //   width: 100%;
              //   background-image: url('../../../../images/redesign/landing-view/mobile_bg_1.webp');
              //   background-repeat: no-repeat;
              //   background-position: center;
              //   background-size: cover;
              //   min-height: 655px;
              // }
            }
          }
        }

        &-adv {
          width: 100%;
          height: 100%;

          & img {
            object-fit: cover;
            width: 80%;
          }
        }
      }

      @media only screen and (max-width: $mobileSmSize) {
        &-mobile {
          min-height: 1200px;

          &-bg {
            width: 100%;
            margin-top: -200px;
          }

          &-list {
            &-body {
              width: 100%;
              min-height: 1100px;

              &-row {
                & .content-section {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: -350px;
                }

                & .adv-section {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: -500px;
                }

                @media only screen and (max-width: $mobileSmSize) {
                  & .adv-section {
                    margin-top: -970px;
                    pointer-events: none;
                  }
                }


                &-content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  position: relative;

                  & .title {
                    font-size: var(--xxLargeFontSize);
                  }

                  & .description {
                    font-size: var(--xxLargeFontSize);
                    text-align: center;
                  }

                  & .footer {
                    position: absolute;
                    bottom: -440px;
                  }
                }

                &-adv {
                  transform: rotate(-50deg);
                }
              }
            }
          }
        }
      }

      &-footer {
        width: 100%;
        min-height: 400px;
        flex-direction: column;
        background-color: #000;
        height: 500px;
        z-index: 15;
        // margin-top: -20px;

        &-first {
          width: 100%;
          // height: 40%;
          background: #000;
          z-index: 11;
          position: relative;
          flex-direction: column;
          margin-top: 90px !important;

          & .zole-logo {
            width: 100%;

            // height: 60%;
            img {
              width: 54px;
              height: 83px;
            }
          }

          & .support {
            width: 100%;
            // height: 40%;
            flex-direction: column;
            margin: 38px 0;

            & .support-content {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: var(--smallFontSize);
              line-height: 160%;
              text-align: center;
              color: #ffffff;
              width: 100%;
              margin-bottom: 4px;
              // height: 10%;
            }

            & .support-by {
              flex-direction: row;

              & img {
                width: 120px;
                height: 34px;
              }

              & .about-us-modal-third-row-img-left {
                margin-right: 3.5px;
              }

              & .about-us-modal-third-row-img {
                margin-left: 3.5px;
              }

              & .mobile-support-store {
                margin-left: -5px;
              }
            }
          }
        }

        @media only screen and (max-width: $mobileSmSize) {
          &-first {
            margin-top: 45px !important;

            & .support {
              margin: 10px 0;

              & .support-by {
                & .mobile-support-store {
                  margin-left: 0;
                  margin-bottom: 8px;
                }

                & .about-us-modal-third-row-img-left {
                  margin-right: 0;
                }

                & .about-us-modal-third-row-img {
                  margin-left: 0;
                }
              }
            }
          }
        }

        &-footer::before {
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          opacity: 0.7;
          -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
          transform: matrix(1, 0, 0, -1, 0, 0);
          min-height: 102px;
          width: 100%;
          content: " ";
          top: -102px;
          position: absolute;
        }

        & .help-menu {
          width: 100%;
          height: 10%;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: var(--mSmallFontSize);
          line-height: 15px;
          text-decoration-line: underline;
        }

        & .help-develop {
          width: 100%;
          height: 20%;
          flex-direction: column;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: var(--smallFontSize);
          line-height: 160%;
          text-align: center;
          color: #ffffff;

          &-content {
            font-size: var(--defaultFontSize);
            line-height: 160%;
            text-align: center;
            color: #ffc900;
          }
        }

        & .help-manufacture {
          width: 100%;
          height: 30%;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: var(--smallFontSize);
          line-height: 160%;
          text-align: center;
          color: #868686;
        }
      }

      @media only screen and (max-width: $mobileSmSize) {
        &-footer {
          display: none !important;
        }
      }

      & .zole-rules-container {
        background-color: #0c0c0c;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        & .rdw-editor-main {
          color: #fff;
        }
      }

      & .terms-of-use-container {
        background-color: #0c0c0c;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .zole-rules-page {
        height: auto;
        max-height: initial;
        margin-top: 115px;
        width: 80%;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;

        @media only screen and (max-width: $mobileSmSize) {
          &-mobile-header {
            display: none;
          }
        }

        &-title {
          height: 45px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: var(--xxLargeFontSize);
          line-height: 136%;
          color: #ffffff;
          margin-left: 50px;
        }

        & .page-title {
          height: 45px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: var(--xxxLargeFontSize);
          line-height: 140%;
          color: #ffffff;
        }

        @media only screen and (max-width: $mobileSmSize) {
          & .page-title {
            font-size: var(--xxLargeFontSize);
          }
        }


        & .zole-rules-page-header {
          height: calc(100% - 53px);
          overflow: auto;

          & .zole-rules-page-header-tab {
            margin-bottom: 90px;

            .zole-rules-page-tab-child {
              a {
                margin-left: 15px;
              }
            }

            .zole-rules-page-header-link {
              padding-left: 10px;
            }

            & .contact-support-chat-body-scroll-area {
              width: 100%;
            }

            & .scrollArea-buttonDown {
              right: 19px !important;
            }

            & .scrollArea-buttonUp {
              right: 19px !important;
            }
          }
        }

        & .zole-rules-page-content {
          // height: calc(100% - 53px);
          // overflow: auto;
          position: relative;
          margin-bottom: 20px;

          & .help-content {
            display: flex;
            height: 100%;
          }

          & .mobile-help-content {
            display: none;
          }
        }
      }

      & .weekly-status-container {
        height: 75vh;
        max-height: 75vh;
        margin-top: 115px;
        width: 100%;
      }

      @media only screen and (max-width: $mobileSmSize) {
        & .zole-rules-page {
          // height: 1200px;
          min-height: 100vh;
          width: 100% !important;
          margin-top: 14px;
          display: inline;
          margin-bottom: 100px;

          & .zole-rules-page-header {
            height: calc(50% - 50px);
            width: 90%;
            margin-bottom: 50px;
            display: none;
          }

          & .zole-rules-page-content {
            height: calc(50% - 50px);
            width: 90%;
            margin-top: 10px;

            & .help-content {
              display: none;
            }

            & .mobile-help-content {
              display: flex;
              height: auto !important;
            }
          }

          &-title {
            margin-left: 5px;
          }

          &-sub-title {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: var(--xLargeFontSize);
            line-height: 135%;
            color: #fff;
            margin-left: 5px;
          }

          &-mobile-header {
            display: flex;
            width: 100%;
            height: 49px;
            margin-left: 5px;

            & .zole-rules-page-header-tab {
              width: 100%;

              & .landing-help-page-dropdown-list {
                width: 100%;
                top: 0 !important;
              }

              & .landing-version-history-dropdown-list {
                width: 100%;
                top: 0px !important;
              }
            }

            & button {
              width: 100%;
              font-size: var(--defaultFontSize);
              color: #ffffff;
              opacity: 1;
            }
          }
        }
      }
    }

    &-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      width: 100%;
      max-width: 100%;
      position: relative;

      &-background-1 {
        width: 100%;
        height: 100%;
        background-image: url("../../../../images/redesign/landing-view/new_bg_1.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

.scrollarea .scrollbar-container.horizontal {
  pointer-events: auto;
}

.landing-top-list-body-level {
  .my-info-ratings-step-wrapper {
    justify-content: flex-start;
  }
}

@media only screen and (orientation: landscape) and (max-width: 992px) {
  .new-design-landing-container-mobile-list-body-row {
    height: auto !important;
  }
  .new-design-landing-container-footer {
    // display: none !important;
  }

  .new-design-landing-container-mobile-bg {
    margin-top: 0 !important;
  }
  .new-design-landing-container-mobile-list-body-row-adv {
    img {
      // rotate: -45deg;
    }
  }
  .new-design-landing-container-mobile-bg {
    min-height: 420px !important;
  }
}