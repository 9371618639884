

$cardGap: 4vmin;
$cardGapHeight: $cardGap / 2;

$cardWidth: 12vmin;
$cardHeight: $cardWidth * 1.52;

$cardWidthMax: 12vmax;
$cardHeightMax: $cardWidthMax * 1.52;

$rotatePercent: 7.7;

.root-class-design20 {


  .cards2 {
  //  z-index: 870!important;
  //  left: 43%!important;
  //  bottom: 0!important;

    left: 0%!important;
    top: -11.2vh!important;
    position: relative!important;

  //  margin-top: 7vmin;

    @media all and (orientation: portrait) {
      left: -10%!important;
    }

    &-wrapper {
      position: relative;
      z-index: 10;
    //  width: 10vw;
      height: 1px;
    //  width: calc(100vw / 8.5);
      min-width: 110px;
      margin-left: auto;
      margin-right: auto;
    }

    .card2 {

    //  width: $cardWidth;
    //  height: $cardHeight;

    //  width: calc(100% - 5px);
    //  height: calc(100% - 5px);

      width: 100%;
      height: 100%;

      background-color: transparent;
    //  float: left;
    //  margin-right: -58px;
      border: none;
      transition: 0.3s ease;
      cursor: pointer;
      position: absolute;
      transform-origin: center;
      z-index: 990;

    //  background-size: $cardWidth $cardHeight;
      background-size: 100% 100%;

      background-position: top center;
      background-repeat: no-repeat;
    //  bottom: 0;
      top: unset;
      bottom: 0;

      padding-right: 5px;
      padding-bottom: 5px;

      @media all and (orientation: portrait) {
        width: $cardWidth;
        height: $cardHeight;

        background-size: 100% 100%;

        min-width: 80px;
        min-height: 121px;

      //  background-size: $cardWidth $cardHeight;
      }

      &-innerWrapper {
        width: 100%;
        height: 100%;
        position: relative;

        @media all and (orientation: portrait) {
          width: $cardWidth;
        //  height: $cardHeight;

          margin-left: auto;
          margin-right: auto;

        }
      }

      &-innerWrapper2 {
        width: 100%;
        height: 100%;
        position: relative;
      }

      &-wrapper {
        width: $cardWidth;
        height: $cardHeight;

        min-width: 95px;
        min-height: 143px;

        bottom: 0;
        background-color: transparent;
      //  float: left;
        border: none;
        transition: 0.3s ease;
        cursor: pointer;
        position: absolute;
        transform-origin: center;
        z-index: 990;

      //  background-size: $cardWidthMax $cardHeightMax;
      //  background-position: top center;
      //  background-repeat: no-repeat;

        @media all and (orientation: portrait) {
          width: $cardWidthMax;
          height: $cardHeightMax;

        //  background-size: $cardWidthMax $cardHeightMax;
        }
      }

      &-♠︎-9{background-image: url('../../../../../images/cards-white-fill/♠︎-9.png');}
      &-♠︎-10{background-image: url('../../../../../images/cards-white-fill/♠︎-10.png');}
      &-♠︎-A{background-image: url('../../../../../images/cards-white-fill/♠︎-A.png');}
      &-♠︎-J{background-image: url('../../../../../images/cards-white-fill/♠︎-J.png');}
      &-♠︎-Q{background-image: url('../../../../../images/cards-white-fill/♠︎-Q.png');}
      &-♠︎-K{background-image: url('../../../../../images/cards-white-fill/♠︎-K.png');}

      &-♣︎-9{ background-image: url('../../../../../images/cards-white-fill/♣︎-9.png');}
      &-♣︎-10{ background-image: url('../../../../../images/cards-white-fill/♣︎-10.png');}
      &-♣︎-A{ background-image: url('../../../../../images/cards-white-fill/♣︎-A.png');}
      &-♣︎-J{ background-image: url('../../../../../images/cards-white-fill/♣︎-J.png');}
      &-♣︎-Q{ background-image: url('../../../../../images/cards-white-fill/♣︎-Q.png');}
      &-♣︎-K{ background-image: url('../../../../../images/cards-white-fill/♣︎-K.png');}

      &-♥-9{ background-image: url('../../../../../images/cards-white-fill/♥-9.png');}
      &-♥-10{ background-image: url('../../../../../images/cards-white-fill/♥-10.png');}
      &-♥-A{ background-image: url('../../../../../images/cards-white-fill/♥-A.png');}
      &-♥-J{ background-image: url('../../../../../images/cards-white-fill/♥-J.png');}
      &-♥-Q{ background-image: url('../../../../../images/cards-white-fill/♥-Q.png');}
      &-♥-K{ background-image: url('../../../../../images/cards-white-fill/♥-K.png');}

      &-♦︎-7{ background-image: url('../../../../../images/cards-white-fill/♦︎-7.png');}
      &-♦︎-8{ background-image: url('../../../../../images/cards-white-fill/♦︎-8.png');}
      &-♦︎-9{ background-image: url('../../../../../images/cards-white-fill/♦︎-9.png');}
      &-♦︎-10{ background-image: url('../../../../../images/cards-white-fill/♦︎-10.png');}
      &-♦︎-A{ background-image: url('../../../../../images/cards-white-fill/♦︎-A.png');}
      &-♦︎-J{ background-image: url('../../../../../images/cards-white-fill/♦︎-J.png');}
      &-♦︎-Q{ background-image: url('../../../../../images/cards-white-fill/♦︎-Q.png');}
      &-♦︎-K{ background-image: url('../../../../../images/cards-white-fill/♦︎-K.png');}

      &-0 {
        left: calc(#{$cardGap} * -4);
        top: calc(#{$cardGapHeight} * 2.5);
      //  bottom: calc(#{$cardGapHeight} * 0);
        transform: rotate(calc(-5deg * #{$rotatePercent}));
      }

      &-1 {
        left: calc(#{$cardGap} * -3);
        top: calc(#{$cardGapHeight} * 1.7);
      //  bottom: calc(#{$cardGapHeight} * 0.8);
        transform: rotate(calc(-4deg * #{$rotatePercent}));
      }

      &-2 {
        left: calc(#{$cardGap} * -2);
        top: calc(#{$cardGapHeight} * 0.8);
      //  bottom: calc(#{$cardGapHeight} * 1.7);
        transform: rotate(calc(-3deg * #{$rotatePercent}));
      }

      &-3 {
        left: calc(#{$cardGap} * -1);
        top: calc(#{$cardGapHeight} * 0.1);
      //  bottom: calc(#{$cardGapHeight} * 2.4);
        transform: rotate(calc(-2deg * #{$rotatePercent}));
      }

      &-4 {
        left: calc(#{$cardGap} * 0);
        top: calc(#{$cardGapHeight} * -0.55);
      //  bottom: calc(#{$cardGapHeight} * 3.05);
        transform: rotate(calc(-1deg * #{$rotatePercent}));
      }

      &-5 {
        left: calc(#{$cardGap} * 1);
        top: calc(#{$cardGapHeight} * -0.5);
      //  bottom: calc(#{$cardGapHeight} * 3);
        transform: rotate(calc(0deg * #{$rotatePercent}));
      }

      &-6 {
        left: calc(#{$cardGap} * 2);
        top: calc(#{$cardGapHeight} * 0);
      //  bottom: calc(#{$cardGapHeight} * 2.5);
        transform: rotate(calc(1deg * #{$rotatePercent}));
      }

      &-7 {
        left: calc(#{$cardGap} * 3);
        top: calc(#{$cardGapHeight} * 0.7);
      //  bottom: calc(#{$cardGapHeight} * 1.8);
        transform: rotate(calc(2deg * #{$rotatePercent}));
      }

      &-8 {
        left: calc(#{$cardGap} * 4);
        top: calc(#{$cardGapHeight} * 1.5);
      //  bottom: calc(#{$cardGapHeight} * 1);
        transform: rotate(calc(3deg * #{$rotatePercent}));
      }

      &-9 {
        left: calc(#{$cardGap} * 5);
        top: calc(#{$cardGapHeight} * 2.3);
      //  bottom: calc(#{$cardGapHeight} * 0.2);
        transform: rotate(calc(4deg * #{$rotatePercent}));
      }
    }

    &-choose {
      margin-top: 0;
    }


    &-allowed {
      margin-top: 3vmin;
    }


    .blocked {
      margin-top: 1px;

      &-overlay {
        position: relative;
        width: 100%!important;
        height: 100%!important;

      //  width: $cardWidth!important;
      //  height: $cardHeight!important;

        margin-top: 0;
        margin-left: 3px;
        margin-right: 5px;
        padding-right: 10px;

        border-radius: 5px;

        cursor: default;

        background: rgba(255, 255, 255, 0.8),
      }
    }

    .hovered {
      .allowed2 {
      //  top: -34px!important;
      //  top: -26px;
      //  margin-top: -30px;
      //  top: unset!important;
        margin-top: -30px!important;
      }
    }

    .hoveredNeighbour {
      .allowed2 {
      //  top: -24px!important;
      //  top: -19px;
      //  margin-top: 0;

      //  top: unset!important;
        margin-top: -20px!important;
      }
    }

    .allowed2 {
      margin-top: -10px;

      &:hover{
      //  margin-top: -20px!important;
      }
    }

    .selected {
      margin-top: -20px!important;
      transition: none !important;
    }
  }
}
