


.root-class-design20 {

  .player-info {


    &-modal {
      font-size: var(--defaultFontSize);
    //  width: 29vmin;
      position: absolute;
      border: 0;
      z-index: 9999 !important;
      border-radius: 20px;
      padding: 5px;

      background: transparent;
      opacity: 1;
      box-shadow: none;

      & .player-info-row {
        height: auto;

        & > div {
          padding-right: 0;
        }
      }

      &-right {
        min-width: 300px !important;
        min-height: 130px !important;
        width: 38.25vmin !important;
        height: 15.8vmin !important;

        border-radius: 15px 50% 50% 15px;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 300px !important;
          min-height: 130px !important;
          width: 38.25vmin !important;
          height: 15.8vmin !important;

          border-radius: 15px 15.3vmin 15.3vmin 15px;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: left;
          }
        }
      }

      &-left {
        min-width: 300px !important;
        min-height: 120px !important;
        width: 38.25vmin !important;
        height: 15.3vmin !important;

        border-radius: 15.3vmin 15px 15px 15.3vmin;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 300px !important;
          min-height: 120px !important;
          width: 38.25vmin !important;
          height: 15.3vmin !important;

          border-radius: 15.3vmin 15px 15px 15.3vmin;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: right;
          }
        }
      }

      &-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        pointer-events: all;
        // background-color: #333;
        // opacity: .8;
        // filter: blur(5px);
        // z-index: 999;

      }

      &-player-left {
      //  top: -40px;
      //  left: 120px;

        top: 100px;
        left: 20px;
      }

      &-player-right {
      //  top: -40px;
      //  right: 120px;

        top: 0;
        right: 0;
      }

      &-player-firstperson {
        z-index: 1000;
        top: -120px;
        left: 120px;
      }

      &-player-top {
        top: 0px;
        left: 135px;
      }

      &-player-sittingOut {
        top: 0px;
        left: 90px;
      }
    }

    &-new-modal {
      font-size: var(--defaultFontSize);
      width: 810px !important;
      height: 605px !important;
      position: absolute;
      border: 0;
      z-index: 1001 !important;
      border-radius: 20px;
      padding: 5px;

      background: transparent;
      opacity: 1;
      box-shadow: none;

      & .player-info-row {
        height: auto;

        & > div {
          padding-right: 0;
        }
      }

      &-right {
        min-width: 810px !important;
        min-height: 650px !important;
        width: 810px !important;
        height: 650px !important;

        border-radius: 15px 50% 50% 15px;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 810px !important;
          min-height: 650px !important;
          width: 810px !important;
          height: 650px !important;

          border-radius: 15px 15.3vmin 15.3vmin 15px;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: left;
          }
        }
      }

      &-left {
        min-width: 810px !important;
        min-height: 650px !important;
        width: 810px !important;
        height: 650px !important;

        border-radius: 15.3vmin 15px 15px 15.3vmin;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 810px !important;
          min-height: 650px !important;
          width: 810px !important;
          height: 650px !important;

          border-radius: 15.3vmin 15px 15px 15.3vmin;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: right;
          }
        }
      }

      &-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #333;
        opacity: .8;
        filter: blur(5px);
        z-index: 999;

      }

      &-player-left {
        // top: 60px;
        // left: 247px;
        transform: translate(-50%, -50%);
        left: 50% !important;
        top: 50% !important;
      }

      &-player-right {
        // top: -40px;
        // right: 120px;
        transform: translate(-50%, -50%);
        left: 50% !important;
        top: 50% !important;
      }

      &-player-firstperson {
        z-index: 1000;
        // top: -465px;
        // left: -332px;
        transform: translate(-50%, -50%);
        left: 50% !important;
        top: 50% !important;
      }

      &-player-top {
        // top: 165px;
        // left: -174px;
        transform: translate(-50%, -50%);
        left: 50% !important;
        top: 50% !important;
      }

      &-player-sittingOut {
        // top: 165px;
        // left: -174px;
        transform: translate(-50%, -50%);
        left: 50% !important;
        top: 50% !important;
      }
    }

    &-body {
      font-size: var(--defaultFontSize);
      width: 810px;
      height: 605px;
    //  position: absolute;
      border: 0;
      border-radius: 20px;
      text-align: left;
      padding: 0.5vmin;

      padding-top: 2.5vmin;
      background: $modalBodyBackground;
      color: $modalBodyColor;
      opacity: 1;
      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
      border-radius: 17px 17px 17px 17px;
      z-index: 10;
    }

    &-header {
      position: relative;
      top: 10px;
      z-index: 20;

      font-size: var(--largeFontSize);

      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &-title {
        position: relative;

        &:before {
          position: absolute;
          left: -70px;
          top: -17px;
          content: ' ';
          background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
          background-size: 65px 65px;
          height: 65px;
          width: 65px;
        }

        &:after {
          position: absolute;
          right: -70px;
          top: -17px;
          content: ' ';
          background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
          background-size: 65px 65px;
          height: 65px;
          width: 65px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        &-wrapper {
          width: auto;
          background: transparent;
          color: $modalTitleColor;
          border-radius: 50px;
          border: 0;
          margin-left: auto;
          margin-right: auto;
          z-index: 500;
          padding: 2px 12px;
          cursor: default;

        }
      }

      & img {
        position: relative;
        top: -8px;
        right: -37px;
        cursor: pointer;
      }

      & .modal-title {
        width: auto;
        background: $modalTitleBackground;
        color: $modalTitleColor;
        border-radius: 50px;
        border: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 500;
        padding: 2px 12px;
        cursor: default;

        & .default-modal-title {
          position: relative;

          &:before {
            position: absolute;
            left: -70px;
            top: -17px;
            content: ' ';
            background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
            background-size: 65px 65px;
            height: 65px;
            width: 65px;
            pointer-events: none;
          }

          &:after {
            position: absolute;
            right: -70px;
            top: -17px;
            content: ' ';
            background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
            background-size: 65px 65px;
            height: 65px;
            width: 65px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            pointer-events: none;
          }
        }
      }
    }
  }

  .player-info-new {


    &-modal {
      font-size: var(--defaultFontSize);
      //  width: 29vmin;
      position: absolute;
      border: 0;
      z-index: 1001 !important;
      border-radius: 20px;
      padding: 5px;

      background: transparent;
      opacity: 1;
      box-shadow: none;
      pointer-events: all;

      & .player-info-row {
        height: auto;

        & > div {
          padding-right: 0;
        }
      }

      & .player-info-new-statistics {
        display: flex;
        margin-bottom: 30px;

        &-points {
          font-size: var(--defaultFontSize);
        }

        &-points-light label {
          color: #FF7A00 !important;
        }
        &-points-normal label {
          color: #FFC900 !important;
        }
        &-points-dark label {
          color: #FFC900 !important;
        }

        &-points label {
          &-light {
            color: #FF7A00 !important;
          }
          &-normal {
            color: #FFC900 !important;
          }
          &-dark {
            color: #FFC900 !important;
          }
        }
         
        &-percent {
          font-size: var(--smallFontSize);
          color: #a69d9d;
        }
        &-percent label {
          font-size: var(--defaultFontSize);
          color: #ffffff;
        }
      }
      & .giftsHistory-row {
        height: 70px;
        display: flex;
        margin-bottom: 30px;
      }

      & .giftsHistory-row-circle {
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        border: 1px solid #ffffff;
        text-align: center;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      & .giftsHistory-row div {
        background-size: contain, auto;
        background-repeat: no-repeat;
        background-position: center center;
        width: 70px;
        height: 70px;
        padding-right: 9px;
        margin-top: -9px;
      }

      & .player-info-new-text {
        font-size: var(--mLargeFontSize);
        font-weight: bold;
        margin-bottom: 30px;
      }

      &-right {
        min-width: 810px !important;
        min-height: 650px !important;
        width: 810px !important;
        height: 605px !important;

        border-radius: 15px 50% 50% 15px;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 810px !important;
          min-height: 650px !important;
          width: 810px !important;
          height: 605px !important;

          border-radius: 15px 15.3vmin 15.3vmin 15px;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: left;
          }
        }
      }

      &-left {
        min-width: 810px !important;
        min-height: 650px !important;
        width: 810px !important;
        height: 605px !important;

        border-radius: 15.3vmin 15px 15px 15.3vmin;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 810px !important;
          min-height: 650px !important;
          width: 810px !important;
          height: 605px !important;

          border-radius: 15.3vmin 15px 15px 15.3vmin;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: right;
          }
        }
      }

      &-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background-color: #333;
        // opacity: .8;
        // filter: blur(5px);
        // z-index: 999;

      }

      &-player-left {
        top: 20px;
        left: 52px;
      }

      &-player-right {
        top: 73px;
        right: 34px;
      }

      // &-player-firstperson {
      //   z-index: 1000;
      //   top: -493px;
      //   left: -325px;
      //   .player-info-new-header {
      //     .player-info-new-player-reg-date {
      //       position: absolute;
      //       right: 17px;
      //       top: 47px;
      //       font-family: 'Open Sans';
      //       font-style: normal;
      //       font-weight: 400;
      //       font-size: var(--defaultFontSize);
      //       line-height: 160%;
      //       opacity: 0.5;
      //       color: rgba($color: #fff, $alpha: 0.5);
      //     }
      //   }
      // }

      &-player-sittingOut {
        top: 0px;
        left: 90px;
      }
    }

    &-new-modal {
      font-size: var(--defaultFontSize);
      width: 810px !important;
      height: 650px !important;
      position: absolute;
      border: 0;
      z-index: 1800 !important;
      border-radius: 20px;
      padding: 5px;

      background: transparent;
      opacity: 1;
      box-shadow: none;

      & .player-info-row {
        height: auto;

        & > div {
          padding-right: 0;
        }
      }

      &-right {
        min-width: 810px !important;
        min-height: 650px !important;
        width: 810px !important;
        height: 650px !important;

        border-radius: 15px 50% 50% 15px;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 810px !important;
          min-height: 650px !important;
          width: 810px !important;
          height: 650px !important;

          border-radius: 15px 15.3vmin 15.3vmin 15px;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: left;
          }
        }
      }

      &-left {
        min-width: 810px !important;
        min-height: 650px !important;
        width: 810px !important;
        height: 650px !important;

        border-radius: 15.3vmin 15px 15px 15.3vmin;

        z-index: 1!important;
        padding: 0;

        & .player-info-body {
          min-width: 810px !important;
          min-height: 650px !important;
          width: 810px !important;
          height: 650px !important;

          border-radius: 15.3vmin 15px 15px 15.3vmin;
          padding: 0.5vmin;

          &-content {
            width: 60%;
            float: right;
          }
        }
      }

      &-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background-color: #333;
        // opacity: .8;
        // filter: blur(5px);
        // z-index: 999;
      }

      &-player-left {
        top: 60px;
        left: 247px;
      }

      &-player-right {
        top: -40px;
        right: 120px;
      }

      &-player-firstperson {
        z-index: 1000;
        top: -465px;
        left: -332px;


      }

      &-player-top {
        top: 165px;
        left: -174px;
      }

      &-player-sittingOut {
        top: 0px;
        left: 90px;
      }
    }

    &-body {
      font-size: var(--defaultFontSize);
      width: 810px;
      height: 685px;
    //  position: absolute;
      border: 0;
      border-radius: 20px;
      text-align: left;
      padding: 0.5vmin;

      padding-top: 2.5vmin;
      background: $modalBodyBackground;
      color: $modalBodyColor;
      opacity: 1;
      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
      border-radius: 17px 17px 17px 17px;
      z-index: 10;

      &-content {
        margin-top: 150px;
      }
    }

    &-header {
      position: relative;
      top: 51px;
      z-index: 20;

      font-size: var(--largeFontSize);

      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &-title {
        position: relative;

        &:before {
          position: absolute;
          left: -70px;
          top: -17px;
          content: ' ';
          background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
          background-size: 65px 65px;
          height: 65px;
          width: 65px;
        }

        &:after {
          position: absolute;
          right: -70px;
          top: -17px;
          content: ' ';
          background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
          background-size: 65px 65px;
          height: 65px;
          width: 65px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        &-wrapper {
          width: auto;
          background: transparent;
          color: $modalTitleColor;
          border-radius: 50px;
          border: 0;
          margin-left: auto;
          margin-right: auto;
          z-index: 500;
          padding: 2px 12px;
          cursor: default;
          flex: 1;
        }
      }

      & .modal-title {
        width: auto;
        background: $modalTitleBackground;
        color: $modalTitleColor;
        border-radius: 50px;
        border: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 500;
        padding: 2px 12px;
        cursor: default;

        & .default-modal-title {
          position: relative;

          &:before {
            position: absolute;
            left: -70px;
            top: -17px;
            content: ' ';
            background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
            background-size: 65px 65px;
            height: 65px;
            width: 65px;
            pointer-events: none;
          }

          &:after {
            position: absolute;
            right: -70px;
            top: -17px;
            content: ' ';
            background-image: url('../../../../../../images/redesign/components/modal/modal_stars.png');
            background-size: 65px 65px;
            height: 65px;
            width: 65px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            pointer-events: none;
          }
        }
      }

      &-close {
        position: absolute;
        top: -23px;
        right: -40px;
        cursor: pointer;
        pointer-events: all;
      }
    }

    // & .player {
    //   position: absolute;
    //   width: 100%;
    //   height: 29vh;

    //   &-background {
    //     width: 100%;
    //     height: 100%;

    //     background-image: url('../../../images/redesign/player/chair-back 1.png');
    //     background-size: 100% 100%;
    //     background-repeat: no-repeat;
    //     background-position: center bottom;

    //     &-shadow {
    //       position: absolute;
    //       top: 0;


    //       background-image: url('../../../images/redesign/player/player-shadow.png');
    //       background-size: 100% 100%;
    //       background-repeat: no-repeat;
    //       width: 100%;
    //     //  margin-left: 25%;
    //     //  margin-right: 25%;
    //       height: 100%;
    //       pointer-events: none
    //     }

    //     &-shadow2 {
    //       position: absolute;
    //       top: -20%;


    //       background-image: url('../../../images/redesign/player/player-shadow2.png');
    //       background-size: 100% 100%;
    //       background-repeat: no-repeat;
    //       width: 130%;
    //       margin-left: -10%;
    //       height: 100%;
    //       pointer-events: none
    //     }
    //   }

      &-player {
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;

      //  display: none;

        &-background {
          position: relative;
        }

        &-image {
        //  position: relative;

          background-size: cover;
          background-repeat: no-repeat;
        //  width: 100%;
        //  height: 100%;

          box-shadow: inset 0px -12px 12px rgba(0, 0, 0, 0.75), inset 0px 12px 22px rgba(255, 255, 255, 0.7);
          filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));

          position: absolute;
          top:      0;
          left:     0;
          bottom:   0;
          right:    0;

          margin: 12px;
          border-radius: 50%;

          @media screen and (max-height: 750px) {
            margin: 10px;
          }

          @media screen and (max-height: 650px) {
            margin: 8px;
          }

          @media screen and (max-height: 550px) {
            margin: 6px;
          }

          &-background {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('../../../../../../images/redesign/player/UserFrame.png');
            background-size: cover;
            background-repeat: no-repeat;
            top: 0;
          }

          &-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url('../../../../../../images/redesign/player/UserOverlay.png');
            background-size: cover;
            background-repeat: no-repeat;
            top: 0;
          }

          &-wrapper {
            position: relative;
            width: 50%;
            min-width: 85px;
            max-width: 87px;
            margin-left: auto;
            margin-right: auto;

            &:before {
              content: "";
              display: block;
              padding-top: 100%; /* initial ratio of 1:1*/
            }
          }


        }

        &-name {
          display: inline-block;
          justify-content: center;
          align-items: center;
          padding: 7px 20px;
          font-size: var(--xLargeFontSize);
          font-weight: bold;
          /* Black */
          color: $buttonPrimaryColour;
          background: $buttonSecondaryColour;
          border-radius: 88px;
          position: relative;

          @media screen and (max-height: 650px) {
            padding: 5px 16px;
          }

          @media screen and (max-height: 550px) {
            padding: 2px 10px;
          }

          &-wrapper {
            position: relative;
            text-align: center;
            font-size: var(--headerFontSize);
            line-height: $headerFontSize;

            &-friend {
              position: absolute;
              right: 0px;
              top: 65px;
              z-index: 1;

              @media screen and (orientation : portrait) {
                top: 70px;
              }
            }
          }

        }

        &-info-wrapper {
          position: relative;
          text-align: center;
          font-size: var(--headerFontSize);
          margin-left: auto;
          margin-right: auto;
        }

        &-wrapper {
          position: relative;
          text-align: center;
          font-size: var(--headerFontSize);
          margin-left: auto;
          margin-right: auto;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }
        }

        &-balance {
          display: inline-block;
          position: relative;
          text-align: center;
          font-size: var(--largeFontSize);
        //  float: left;
          top: 10px !important;
          right: 10px !important;
          color: $buttonPrimaryColour;
          padding-right: 3px;
        //  width: 6vmin;
        //  background: $buttonSecondaryColour;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }
          &-text {
            font-size: var(--smallFontSize);
            line-height: $smallFontSize;

            &-orange {
              color: #FF8A1A;
            }

            &-red {
              color: #D2110A;
            }
          }

          &-icon {
            display: inline-block;
            text-align: center;
            width: 40px;
            height: 40px;
            padding-left: 3px;
            padding-right: 3px;
          //  float: left;
            top: 10px !important;
            right: 17px !important;
            @media screen and (max-height: 650px) {
              height: $smallFontSize;
            }
          }
        }

        &-rating {
          display: inline-block;
          position: relative;
          text-align: center;
          font-size: var(--largeFontSize);
        //  float: left;
          top: 10px !important;
          right: -7px !important;
          color: $buttonPrimaryColour;
        //  width: 6vmin;
        //  background: $buttonSecondaryColour;

          @media screen and (max-height: 650px) {
            font-size: var(--smallFontSize);
          }

          &-icon {
            display: inline-block;
            text-align: center;
            width: 40px;
            height: 40px;
            padding-left: 3px;
            padding-right: 3px;
          //  float: left;
            top: 10px !important;
            right: 0px !important;
            @media screen and (max-height: 650px) {
              height: $smallFontSize;
            }
          }
        }
      }
    // }
    &-tabs {
      padding-bottom: 10px;
    }

    &-tab {
      padding-left: 5px;
      padding-right: 5px;

      text-align: left;

      margin: 0px;
      margin-bottom: 8px;
      padding-top: 20px;

      position: relative;

      &-link {
        display: inline-block;
        margin-left: 15px;
        margin-bottom: -1px;
        font-size: var(--headerFontSize);

        &-text {
          color: $headerTextColour;
          opacity: $headerInactiveOpacity;
          margin: 0px 6px;
          padding: 6px 0px;
          cursor: pointer;
          text-decoration: none;

          &-active {
            &-light {
              opacity: 1;
              border-bottom: 1px solid #d2110a;
            }
            &-normal {
              opacity: 1;
              border-bottom: 1px solid $headerActiveBorderColour;
            }
            &-dark {
              opacity: 1;
              border-bottom: 1px solid $headerActiveBorderColour;
            }
          }
          &-trophy {
            position: relative;
            left: 0;
            top: 0;
            &-bage {
              position: absolute;
              font-size: var(--smallFontSize);
              right: -18px;
              top: 0px;
            }
          }
          &-gifts {
            position: relative;
            left: 0;
            top: 0;
            &-bage {
              position: absolute;
              font-size: var(--smallFontSize);
              right: -15px;
              top: 0px;
            }
          }
        }

        &:hover {
          color: $headerTextColour;
        }

        &:active {
          color: $headerTextColour;
        }

        &:focus {
          color: $headerTextColour;
        }
      }

      & .active {
        color: #fff;
        background-color: #380000;
        border-color: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  .giftsHistory-tab-new-row {
    height: 318px;
    width: 100%;
    position: relative;
    &-scrollarea {
      width: 100%;
      height: 100%;
    }

    & .giftsHistory-new-item {
      background-size: contain, auto;
      background-repeat: no-repeat;
      background-position: center center;
      width: 70px;
      height: 70px;
      padding-right: 10px;
    }
  }
}
