$mobileLandScapeWidth: 1280px;

@media screen and (orientation : portrait) {
    .root-class-design20 {
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;

        .landing-overflow-manage-redesign {
            max-width: 100vw !important;
            width: 100vw !important;
            overflow-x: hidden !important;

            .container-fluid {
                width: 100vw !important;
                max-width: 100vw !important;
                padding: 0;
                margin: 0;

                .game-bg {
                    background-image: url('../../../../images//redesign//layout/game\ screen\ bg.webp');
                }

                .new-design-landing-header {
                    display: none !important;
                }

                .new-design-landing-mobile-header {
                    padding: 0;
                    position: absolute;
                    top: 30px !important;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 100;
                    display: block;

                    .logo-wrapper {
                        a {
                            width: 98px;
                            height: 55px;
                        }
                    }
                }

                .mobile-landscape-navigation-logo-wrapper {
                    position: fixed;
                    z-index: 102;
                    display: block !important;
                    bottom: 48px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-image: url('../../../../images/redesign/mobile/icons/logo-attach-mark-portrait.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 53px;
                    height: 34px;

                }

                .new-design-landing-mobile-navigation {
                    position: fixed;
                    bottom: 0;
                    z-index: 103;
                    width: 100vw;
                    height: 57px;
                    display: flex;

                    .mobile-navigation-logo {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 90px;
                        height: auto;
                        object-fit: contain;
                        top: -35px;
                        z-index: 102;
                    }

                    .left-part {
                        background: linear-gradient(180deg, #181818 0%, #000000 100%) !important;
                        width: 50%;
                        height: 100%;
                        border-radius: 8px 33px 0px 0px;

                        &>button:first-of-type {
                            font-size: 13px;
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            text-transform: uppercase;
                            line-height: normal;
                            color: #000000;
                            padding: 6px 15px;
                            width: auto;
                            height: auto;
                            margin-left: 21px;
                            min-width: auto;
                            border-radius: 99px;
                            background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%) !important;
                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset !important;

                            &::after {
                                margin-left: 13px;
                            }

                            &::before {
                                margin-right: 13px;
                            }
                        }
                    }

                    .right-part {
                        background: linear-gradient(180deg, #181818 0%, #000000 100%) !important;
                        width: 50%;
                        height: 100%;
                        border-radius: 33px 8px 0px 0px;

                        img {
                            margin-right: 20px;
                            width: 18px;
                            height: auto;
                            object-fit: contain;
                        }
                    }
                }

                .new-design-landing-mobile-navigation-section {
                    height: calc(100vh - 57px);
                    top: 0;
                    bottom: 0 !important;
                    z-index: 100;
                    border-radius: 8px 8px 0px 0px;
                    background: #0C0C0C !important;
                    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.80);
                    width: 100vw;
                    left: 0;
                    right: 0;

                    .mobile-navigation-container {
                        height: 100%;
                        max-height: unset;
                        width: 100%;
                        margin-left: 0;
                        margin-right: 0;

                        .mobile-navigation-container-body {
                            .mobile-navigation-container-body-area {

                                .mobile-navigation-container-body-area-content {
                                    overflow: auto;
                                    height: calc(100% + 20px);

                                    .mobile-navigation-link {
                                        margin-top: 50px;
                                        padding-top: 0;
                                        background: transparent !important;
                                        box-shadow: unset !important;

                                        .top-settings-new {
                                            display: flex;
                                            flex-direction: column;
                                            row-gap: 28px;

                                            .top-settings-new-section {
                                                color: rgba($color: #fff, $alpha: 0.5) !important;
                                                font-family: 'Open Sans';
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 21.79px;
                                                text-transform: uppercase;
                                                margin-bottom: 0;
                                            }

                                            .top-settings-new-section:nth-of-type(7) {
                                                display: none;
                                            }

                                            .top-settings-new-section:nth-of-type(8) {
                                                margin-top: 8px;

                                                .top-settings-new-divider {
                                                    margin-bottom: 28px;
                                                }

                                                .mobile-navigation-language {
                                                    display: flex;
                                                    column-gap: 13px;
                                                    justify-content: center;

                                                    &>div {
                                                        width: 18px;
                                                        height: 18px;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            padding: 0;
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }

                                    .mobile-navigation-footer {
                                        .new-design-landing-container-footer-first {
                                            margin-top: 28px !important;
                                            background: #000 !important;

                                            .support {
                                                margin-top: 49px;
                                                margin-bottom: 10px;

                                                .support-content {
                                                    color: #FFF;
                                                    font-family: 'Open Sans';
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 160%;
                                                    margin-bottom: 0;
                                                }

                                                .support-by {
                                                    display: flex !important;
                                                    flex-direction: column !important;
                                                    align-items: center !important;
                                                    justify-content: unset !important;
                                                    row-gap: 7px;

                                                    a {
                                                        img {
                                                            width: 87px;
                                                            object-fit: contain;
                                                            margin-bottom: 0;
                                                            height: auto;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .help-develop {
                                            display: none !important;
                                        }

                                        .help-manufacture {
                                            color: #868686;
                                            font-family: 'Open Sans';
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 160%;
                                            margin-top: 2px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .new-design-landing-container {
                    .new-design-landing-container-sign {
                        min-height: auto;

                        .new-design-landing-container-sign-bg {
                            width: 100%;
                            height: calc(100vw * 459/375);
                            min-height: auto;
                            background-image: url('../../../../images/redesign/landing-view/new_bg_mobile_1.png') !important;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: unset;
                            filter: none;
                        }

                        .new-design-landing-container-sign-dg {
                            top: calc(100vw * 459/375);
                            bottom: unset;
                            min-height: auto;
                            height: calc(311/375 * 100vw);
                            background: #0C0C0C !important;
                        }

                        .new-design-landing-container-sign-body {
                            position: static;
                            min-height: auto;
                            max-width: unset;
                            width: 100vw !important;

                            .sign-row {
                                margin-top: 164px;
                                width: 100vw;
                                height: auto;
                                margin-left: 0;
                                margin-right: 0;

                                .sign-row-start-game {
                                    padding: 0;

                                    .sign-row-content {
                                        min-height: auto !important;
                                        max-width: unset;
                                        padding-left: 20px;
                                        padding-right: 20px;

                                        .sign-row-content-title {
                                            color: #FFC900;
                                            font-size: 30px !important;
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 40.85px;
                                            text-align: center;
                                        }

                                        .sign-row-content-description {
                                            margin-top: 12px;
                                            margin-bottom: 12px;
                                            color: #FFF;
                                            font-size: 20px !important;
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 27.24px;
                                            text-align: center;
                                        }

                                        .sign-row-content-button {
                                            border-radius: 99px;
                                            background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%) !important;
                                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset !important;
                                            padding: 12px 18px;
                                            color: var(--black, #000);
                                            font-size: 17px;
                                            font-family: 'Open Sans';
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: 23.15px;
                                            text-transform: uppercase;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            height: auto;
                                            width: auto;
                                            min-width: auto;
                                            margin-bottom: 62px;

                                            &::after {
                                                margin-left: 15px;
                                            }

                                            &::before {
                                                margin-right: 15px;
                                            }
                                        }
                                    }
                                }

                                .sign-row-start-game+div {
                                    padding-left: 0;
                                    padding-right: 0;

                                    .sign-row-slide {
                                        margin-top: 0;
                                        min-width: auto !important;
                                        min-height: auto !important;
                                        max-width: unset;
                                        width: 100%;
                                        position: relative;

                                        &::after {
                                            content: '';
                                            background-color: red;
                                        }

                                        .splide {
                                            .splide__arrows {
                                                display: none !important;
                                            }

                                            .splide__track {
                                                .splide__list {
                                                    li {
                                                        width: 100vw !important;
                                                        @media screen and (min-width: 768px) {
                                                            width: 50vw !important;
                                                        }

                                                        img {
                                                            width: 100%;
                                                            height: auto;
                                                            object-fit: contain;
                                                        }

                                                        .sign-row-slide-content {
                                                            position: absolute;
                                                            left: 20px;
                                                            top: 24px;

                                                            .sign-row-slide-content-title {
                                                                color: #000;
                                                                font-size: 24px;
                                                                font-family: 'Open Sans';
                                                                font-style: normal;
                                                                font-weight: 600;
                                                                line-height: 32.68px;
                                                            }

                                                            .sign-row-slide-content-description {
                                                                margin-top: 6px;
                                                                color: #0C0C0C;
                                                                font-size: 15px;
                                                                font-family: 'Open Sans';
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                line-height: 160%;
                                                                max-width: 185px;
                                                            }

                                                            .sign-row-slide-content-button {
                                                                margin-top: 0 !important;
                                                                margin-bottom: 5px;

                                                                button {
                                                                    padding: 12px 18px;
                                                                    border-radius: 99px;
                                                                    border: 1px solid var(--light-black, #0C0C0C) !important;
                                                                    background: linear-gradient(180deg, rgba(255, 0, 0, 0.80) 0%, rgba(132, 0, 0, 0.80) 100%) !important;

                                                                    label {
                                                                        padding: 0 15px;
                                                                        color: #fff;
                                                                        text-align: center;
                                                                        font-size: 17px;
                                                                        font-family: 'Open Sans';
                                                                        font-style: normal;
                                                                        font-weight: 700;
                                                                        line-height: 23.15px;
                                                                        text-transform: uppercase;
                                                                    }

                                                                    &::before {
                                                                        left: 4px;
                                                                    }

                                                                    &::after {
                                                                        right: 4px;
                                                                    }

                                                                }
                                                            }

                                                            .sign-row-slide-content-footer {
                                                                color: #0C0C0C;
                                                                font-size: 12px;
                                                                font-family: 'Open Sans';
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                line-height: 160%;
                                                                max-width: 150px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .splide__pagination {
                                                bottom: -6px;
                                                border-radius: 10.5px;
                                                background: rgba($color: #000000, $alpha: 0.3);
                                                padding: 4px 9px;
                                                column-gap: 10px;

                                                li {
                                                    button {
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }

                            .sign-row-banner {
                                margin-top: 45px;
                                margin-left: 0;
                                margin-right: 0;
                                width: 100%;
                                height: auto;
                                z-index: 99;

                                .sign-row-banner-slide {
                                    padding: 0;
                                    margin-top: 0;
                                    min-height: auto;

                                    .splide {
                                        .splide__arrows {
                                            display: none !important;
                                        }

                                        .splide__track {
                                            overflow: visible;

                                            .splide__list {
                                                column-gap: 20px;

                                                li {
                                                    margin-right: 0 !important;
                                                    width: auto !important;

                                                    .home-game-slide {
                                                        min-height: auto;
                                                        width: 267px;
                                                        height: 251px;

                                                        img {
                                                            min-height: auto;
                                                            object-fit: contain;
                                                            border-radius: 0px 22px 22px 22px;
                                                            box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.70);
                                                        }

                                                        .splide-img-dg {
                                                            border: 12px solid rgba(255, 255, 255, 0.10);
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .splide__pagination {
                                            bottom: -40px !important;
                                            border-radius: 10.5px;
                                            background: rgba($color: #000000, $alpha: 0.3);
                                            padding: 4px 9px;
                                            column-gap: 10px;

                                            li {
                                                button {
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .new-design-landing-container-top {
                        min-height: auto;

                        .new-design-landing-container-top-bg {
                            display: none;
                        }

                        .new-design-landing-container-top-dg {
                            display: none;
                        }

                        .new-design-landing-container-top-bg-table {
                            display: none;
                        }

                        .new-design-landing-container-top-list {
                            margin-top: 98px;
                            z-index: 99;
                            max-width: unset;

                            .layout-body {
                                border-radius: 17px;
                                height: auto;

                                &::before {
                                    display: none;
                                }

                                &::after {
                                    display: none;
                                }

                                .layout-body-list-header {
                                    z-index: 100;
                                    height: auto;
                                    top: -16px;

                                    .layout-body-list-header-title {
                                        color: #000;
                                        font-size: 16px;
                                        font-family: 'Open Sans';
                                        font-weight: 600;
                                        line-height: 21.79px;
                                        text-align: center;
                                        background-color: #fff;
                                        border-radius: 88px;
                                        padding: 5px 9px;

                                        &::before {
                                            top: -12px;
                                            left: -65px;
                                        }

                                        &::after {
                                            top: -12px;
                                            right: -65px;
                                        }
                                    }
                                }

                                .layout-body-top {
                                    min-height: auto;
                                    border-radius: unset;
                                    height: auto;

                                    .layout-subheader {
                                        padding-top: 30px !important;
                                        margin-left: 0 !important;
                                        margin-right: 0 !important;
                                        text-align: unset;
                                        margin-bottom: 0;
                                        border-bottom: 0;

                                        .layout-subheader-mobile {
                                            margin-bottom: 0;
                                            padding-left: 20px;
                                            padding-right: 20px;

                                            .layout-subheader-filterType {
                                                width: 100% !important;
                                                margin-bottom: 12px !important;
                                                float: unset;
                                                display: block;

                                                .custom-dropdown {
                                                    .custom-dropdown-toggle {
                                                        color: #FFF;
                                                        font-size: 12px;
                                                        font-family: 'Open Sans';
                                                        font-weight: 400;
                                                        line-height: 16.34px;
                                                        border-radius: 25px;
                                                        border: 1px solid rgba(255, 255, 255, 0.20) !important;
                                                        height: auto;
                                                        text-align: unset;
                                                        padding: 11px 17px;
                                                        min-width: auto;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;

                                                        &::after {
                                                            margin-top: 0;
                                                            border-top: 5px solid;
                                                            border-right: 6px solid transparent;
                                                            border-left: 6px solid transparent;
                                                        }
                                                    }

                                                    .custom-dropdown-menu {
                                                        background: rgba($color: #000000, $alpha: 0.95);
                                                        width: calc(100vw - 40px);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .layout-body-main {
                                    box-shadow: none;
                                    height: auto;
                                    min-height: auto;

                                    .landing-top-table-scrollarea-body {
                                        max-height: unset;
                                        overflow: visible;

                                        .landing-top-scrollarea-body {
                                            margin-bottom: 24px;
                                            padding-left: 20px;
                                            padding-right: 20px;

                                            .landing-top-list-header {
                                                margin-top: 0;
                                                margin-bottom: 3px;
                                                flex-wrap: nowrap;
                                                white-space: nowrap;
                                                padding-left: 0;
                                                padding-right: 0;

                                                &>.landing-top-list-header-position,
                                                &>.landing-top-list-header-column-rating,
                                                &>.landing-top-list-header-player,
                                                &>.landing-top-list-header-level,
                                                &>.landing-top-list-header-balance,
                                                &>.landing-top-list-header-points,
                                                &>.landing-top-list-header-rounds {
                                                    display: none !important;
                                                }

                                                &>.landing-top-list-header-mobile {
                                                    display: flex !important;
                                                    padding-left: calc(100vw * 70/375);

                                                    &>div {
                                                        color: rgba($color: #FFF, $alpha: 0.5);
                                                        font-family: 'Futura PT';
                                                        font-size: 12px;
                                                        font-style: normal;
                                                        font-weight: 450;
                                                        line-height: 15.38px;
                                                    }

                                                    .landing-top-list-header-column-rating-md {
                                                        width: calc(62/375 * 100vw) !important;
                                                    }

                                                    .landing-top-list-header-column-balance-md {
                                                        width: calc(80/375 * 100vw) !important;
                                                    }

                                                    .landing-top-list-header-column-points-md {
                                                        width: calc(55/375 * 100vw) !important;
                                                    }

                                                    .landing-top-list-header-column-rounds-md {
                                                        width: calc(60/375 * 100vw) !important;
                                                    }

                                                }
                                            }

                                            .menu-table-row-wrapper {
                                                padding: 0 !important;
                                                margin-bottom: 1px;

                                                .menu-table-row-inner-wrapper {
                                                    padding: 4px 2px 4px 3px;
                                                    box-shadow: none !important;

                                                    .top-table-row {
                                                        .landing-top-list-body-position {
                                                            min-width: calc(26/375 * 100vw);
                                                            width: calc(26/375 * 100vw);
                                                            color: #FFF;
                                                            font-family: 'Futura PT';
                                                            font-size: 11px;
                                                            font-style: normal;
                                                            font-weight: 450;
                                                            line-height: 14.1px;

                                                            .layout-divider {
                                                                display: none;
                                                            }
                                                        }

                                                        .landing-top-list-body-player {
                                                            width: auto;
                                                            min-width: auto;

                                                            .top-table-row-player {
                                                                max-width: unset;
                                                                min-height: auto;

                                                                .top-table-row-player-image-frame {
                                                                    margin-right: 5px !important;
                                                                    width: 36px;
                                                                    height: 36px;
                                                                    min-width: auto;
                                                                    min-height: auto;

                                                                    img {
                                                                        width: 36px;
                                                                        height: 36px;
                                                                        min-width: auto;
                                                                        min-height: auto;
                                                                    }
                                                                }

                                                                &>.top-table-row-text {
                                                                    display: none;
                                                                }

                                                                .top-table-row-mobile-text-group {
                                                                    width: auto;
                                                                    display: flex;
                                                                    flex-direction: column;

                                                                    .top-table-row-mobile-first-line {
                                                                        display: flex;
                                                                        align-self: flex-start;
                                                                        align-items: center;
                                                                        column-gap: 8px;

                                                                        .top-table-row-text {
                                                                            color: #fff;
                                                                            font-family: 'Open Sans';
                                                                            font-size: 13px;
                                                                            font-weight: 400;
                                                                            line-height: 160%;
                                                                        }

                                                                        .my-info-ratings-step-wrapper {
                                                                            width: auto;
                                                                            height: auto;

                                                                            .my-info-ratings-step-active {
                                                                                color: #FFF;
                                                                                font-family: 'Open Sans';
                                                                                font-size: 12px;
                                                                                font-style: normal;
                                                                                font-weight: 700;
                                                                                line-height: 160%;
                                                                                text-transform: uppercase;
                                                                                padding: 0 6px;
                                                                                border-radius: 4px;
                                                                            }
                                                                        }
                                                                    }

                                                                    .top-table-row-mobile-second-line-landing {
                                                                        width: auto;
                                                                        display: flex;

                                                                        .menu-table-row-player-icon-wrapper-landing:first-of-type {
                                                                            width: calc(62/375 * 100vw);
                                                                            display: flex;
                                                                            align-items: center;

                                                                            img {
                                                                                width: 15px;
                                                                                object-fit: contain;
                                                                                height: auto;
                                                                                padding-right: 3px;
                                                                                padding-left: 0;
                                                                            }

                                                                            .menu-table-row-player-icon-text {
                                                                                font-family: 'Open Sans';
                                                                                font-size: 13px;
                                                                                font-style: normal;
                                                                                font-weight: 600;
                                                                                line-height: 160%;
                                                                                color: #fff;
                                                                                padding: 0;
                                                                            }
                                                                        }

                                                                        .menu-table-row-player-icon-wrapper-landing:nth-of-type(2) {
                                                                            width: calc(80/375 * 100vw);
                                                                            display: flex;
                                                                            align-items: center;

                                                                            img {
                                                                                width: 14.7px;
                                                                                object-fit: contain;
                                                                                height: auto;
                                                                                padding-left: 0;
                                                                                padding-right: 1.66px;
                                                                            }

                                                                            .menu-table-row-player-icon-text {
                                                                                font-family: 'Open Sans';
                                                                                font-size: 13px;
                                                                                font-style: normal;
                                                                                font-weight: 600;
                                                                                line-height: 160%;
                                                                                padding: 0;
                                                                                color: #fff;
                                                                            }
                                                                        }

                                                                        .landing-top-list-body-column-points-md {
                                                                            width: calc(55/375 * 100vw);
                                                                            font-family: 'Open Sans';
                                                                            font-size: 13px;
                                                                            font-style: normal;
                                                                            font-weight: 600;
                                                                            line-height: 160%;
                                                                            color: #fff;
                                                                        }

                                                                        .landing-top-list-body-column-rounds-md {
                                                                            width: calc(60/375 * 100vw);
                                                                            font-family: 'Open Sans';
                                                                            font-size: 13px;
                                                                            font-style: normal;
                                                                            font-weight: 600;
                                                                            line-height: 160%;
                                                                            color: #fff;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .landing-top-list-body-level,
                                                        .landing-top-list-body-column-rating,
                                                        .landing-top-list-body-column-balance,
                                                        .landing-top-list-body-column-points,
                                                        .landing-top-list-body-column-rounds,
                                                        .landing-top-list-body-column-btn {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .menu-table-row-filler-out {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .new-design-landing-container-top-footer {
                            background-color: #0C0C0C !important;
                            min-height: 20px;
                        }
                    }

                    .new-design-landing-container-blog {
                        background: #0C0C0C !important;
                        width: 100vw;
                        padding-bottom: 10px;

                        .new-design-landing-container-blog-list {
                            width: 100%;
                            max-width: unset;
                            margin-top: 41px;

                            &>div {
                                padding-left: 20px;
                                padding-right: 20px;

                                .new-design-landing-container-blog-list-title {
                                    font-family: 'Open Sans';
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 140%;
                                    color: #fff;
                                    margin-bottom: 22px !important;
                                }

                                .new-design-landing-container-blog-list-body {
                                    &>.splide {
                                        margin-top: 0;

                                        .splide__track {
                                            .splide__list {
                                                min-height: auto;
                                                width: 100%;

                                                li {
                                                    width: 100% !important;

                                                    .slide-img-section {
                                                        width: 100% !important;
                                                        min-height: auto;

                                                        img {
                                                            height: auto;
                                                            min-height: auto;
                                                            border-radius: 0px 12px 12px 12px;
                                                            object-fit: contain;
                                                        }

                                                        .splide-img-dg {
                                                            border-radius: 0px 12px 12px 12px;
                                                            border: 6px solid rgba(255, 255, 255, 0.1);
                                                        }
                                                    }

                                                    .splide-slide-content {
                                                        position: static;
                                                        max-width: unset;

                                                        .splide-slide-content-title {
                                                            font-family: 'Open Sans';
                                                            font-size: 16px;
                                                            font-style: normal;
                                                            font-weight: 600;
                                                            line-height: 21.79px;
                                                            color: #fff;
                                                            padding-top: 6px;
                                                            padding-bottom: 6px;
                                                            margin-bottom: 0 !important;
                                                        }

                                                        .splide-slide-content-description {
                                                            font-family: 'Open Sans';
                                                            font-size: 14px;
                                                            font-style: normal;
                                                            font-weight: 400;
                                                            line-height: 160%;
                                                            color: #fff;
                                                            height: 130px;
                                                            overflow: hidden;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .new-design-landing-container-mobile {
                        width: 100vw;
                        position: relative;
                        display: block;
                        background: none !important;
                        min-height: auto;

                        .new-design-landing-container-mobile-bg {
                            margin-top: 0;
                            min-height: 740px;
                            background-image: url('../../../../images/redesign/mobile/background/portrait-landing-footer-table.png');
                            background-size: contain;
                            background-position-y: 70px;
                            filter: blur(8px);
                        }

                        .new-design-landing-container-mobile-bd {
                            min-height: 400px;
                            background: linear-gradient(180deg, #0a0a0a 0%, rgba(0, 0, 0, 0) 100%) !important;
                        }

                        .new-design-landing-container-mobile-list {
                            width: 100%;
                            height: auto;
                            position: absolute;
                            top: 0px;
                            backdrop-filter: unset;
                            flex-direction: column;

                            .new-design-landing-container-mobile-list-body {
                                min-height: auto;
                                max-width: unset;

                                .new-design-landing-container-mobile-list-body-row {
                                    margin-left: 0;
                                    margin-right: 0;

                                    .content-section {
                                        margin-top: 0;

                                        .new-design-landing-container-mobile-list-body-row-content {
                                            max-width: unset;
                                            margin-top: 44px;

                                            .title {
                                                color: #FFC900;
                                                font-family: 'Open Sans';
                                                font-size: 24px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 32.68px;
                                                margin-bottom: 3px !important;
                                            }

                                            .description {
                                                color: #FFF;
                                                font-family: 'Open Sans';
                                                font-size: 20px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 27.24px;
                                                margin-bottom: 0 !important;
                                            }

                                            .footer {
                                                top: 500px;
                                                bottom: unset;

                                                .header {
                                                    font-family: 'Open Sans';
                                                    font-size: 15px;
                                                    font-style: normal;
                                                    font-weight: 600;
                                                    line-height: 20.43px;
                                                    color: #FFC900;
                                                    margin-bottom: 7.25px !important;
                                                }

                                                .bottom {
                                                    column-gap: 7px;

                                                    a:first-of-type {
                                                        img {
                                                            object-fit: contain;
                                                            height: 30px;
                                                            margin-right: 0 !important;
                                                            width: auto;
                                                        }
                                                    }

                                                    a:nth-of-type(2) {
                                                        img {
                                                            width: auto;
                                                            object-fit: contain;
                                                            height: 30px;
                                                            margin-right: 0 !important;

                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .adv-section {
                                        margin-top: 0;

                                        .new-design-landing-container-mobile-list-body-row-adv {
                                            transform: translateX(20px) rotate(-50deg);

                                            img {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    #root .root-class-design20 {
        width: 100vw;
        max-width: 100vw !important;
        min-width: 100vw !important;

        .landing-overflow-manage-redesign {
            max-width: 100% !important;
            width: 100%;
            overflow-x: hidden !important;

            .container-fluid {
                width: 100vw !important;
                max-width: 100vw !important;
                padding: 0;
                margin: 0;

                .game-bg {
                    background-image: url('../../../../images/redesign/landing-view/game_screen_bg.webp');
                }

                .new-design-landing-header {
                    // display: none !important;
                }

                .new-design-landing-mobile-header {
                    top: 28px !important;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 100;
                    display: block;

                    .logo-wrapper {
                        a {
                            width: 56px;
                            height: 31px;
                            margin-left: 0;
                            margin-top: 0;
                        }
                    }
                }

                .mobile-landscape-navigation-logo-wrapper {
                    position: fixed;
                    z-index: 102;
                    display: none !important;
                    // bottom: 48px;
                    // left: 50%;
                    // transform: translateX(-50%);
                    top: calc(50vh - 28px);
                    right: 51px;
                    background-image: url('../../../../images/redesign/mobile/icons/logo-attach-mark-landscape.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 22px;
                    height: 53px;


                }

                .new-design-landing-mobile-navigation {
                    z-index: 103;
                    width: 100vh;
                    rotate: -90deg;
                    top: calc(50vh - 31px);
                    margin-right: calc(-50vh + 31px);
                    max-width: unset;
                    height: 62px;

                    img.mobile-navigation-logo {
                        z-index: 102;
                        height: auto;
                        width: 90px;
                        top: -34px;
                        left: calc(50% - 48px);
                        object-fit: contain;
                        rotate: 90deg;
                    }

                    .left-part {
                        background: linear-gradient(180deg, #181818 0%, #000000 100%) !important;
                        width: 50%;
                        border-radius: 8px 33px 0px 0px;

                        &>button:first-of-type {
                            display: none !important;
                        }

                        &>button:nth-of-type(2) {
                            width: 36px;
                            height: 36px;
                            border-radius: 99px;
                            background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset;
                            margin-left: 13px;
                            rotate: 90deg;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                rotate: none;
                                width: 12px;
                                object-fit: contain;
                            }
                        }
                    }

                    .right-part {
                        background: linear-gradient(180deg, #181818 0%, #000000 100%) !important;
                        width: 50%;
                        border-radius: 33px 8px 0px 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            rotate: 90deg;
                            width: 18px;
                            object-fit: contain;
                            height: auto;
                        }
                    }
                }

                .new-design-landing-mobile-navigation-section {
                    top: 14px;
                    bottom: unset !important;
                    z-index: 100;
                    border-radius: 8px 8px 0px 0px;
                    background: #0C0C0C !important;
                    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.80);
                    width: calc(100% - 103px);
                    left: 20px;
                    right: 83px;
                    height: auto;

                    .mobile-navigation-container {
                        height: auto;
                        max-height: unset;
                        width: 100%;
                        margin-left: 0;
                        margin-right: 0;

                        .mobile-navigation-container-body {
                            height: auto;

                            .mobile-navigation-container-body-area {
                                height: auto;

                                .mobile-navigation-container-body-area-content {
                                    height: calc(100vh - 40px);
                                    overflow: auto;
                                    margin-left: 9px !important;
                                    margin-right: 9px;
                                    margin-top: 16px !important;
                                    margin-bottom: 16px;


                                    &::-webkit-scrollbar {
                                        background-color: transparent;
                                    }

                                    .mobile-navigation-link {
                                        margin-top: 36px;
                                        padding-top: 0;
                                        background: transparent !important;
                                        box-shadow: unset !important;

                                        .top-settings-new {
                                            display: flex;
                                            flex-direction: column;
                                            row-gap: 28px;

                                            .top-settings-new-section {
                                                color: rgba($color: #fff, $alpha: 0.5);
                                                font-family: 'Open Sans';
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 21.79px;
                                                text-transform: uppercase;
                                                margin-bottom: 0;
                                            }

                                            .top-settings-new-section:nth-of-type(7) {
                                                display: none;
                                            }

                                            .top-settings-new-section:nth-of-type(8) {
                                                margin-top: 8px;

                                                .top-settings-new-divider {
                                                    margin-bottom: 28px;
                                                }

                                                .mobile-navigation-language {
                                                    display: flex;
                                                    column-gap: 13px;
                                                    justify-content: center;

                                                    &>div {
                                                        width: 18px;
                                                        height: 18px;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            padding: 0;
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }

                                    .mobile-navigation-footer {
                                        .new-design-landing-container-footer-first {
                                            margin-top: 28px !important;
                                            background: #000 !important;

                                            .support {
                                                margin-top: 49px;
                                                margin-bottom: 10px;

                                                .support-content {
                                                    color: #FFF;
                                                    font-family: 'Open Sans';
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 160%;
                                                    margin-bottom: 0;
                                                }

                                                .support-by {
                                                    display: flex !important;
                                                    flex-direction: column !important;
                                                    align-items: center !important;
                                                    justify-content: unset !important;
                                                    row-gap: 7px;

                                                    a {
                                                        img {
                                                            width: 87px;
                                                            object-fit: contain;
                                                            margin-bottom: 0;
                                                            height: auto;
                                                            margin-left: 0;
                                                            margin-right: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .help-develop {
                                            display: none !important;
                                        }

                                        .help-manufacture {
                                            color: #868686;
                                            font-family: 'Open Sans';
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 160%;
                                            margin-top: 2px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .new-design-landing-container {
                    width: 100vw;
                    max-width: unset !important;
                    display: block;
                    height: auto;

                    .new-design-landing-container-sign {
                        min-height: auto;

                        .new-design-landing-container-sign-bg {
                            min-height: auto;
                            background-image: url('../../../../images/redesign/landing-view/new_bg_1.webp') !important;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: unset;
                            width: 100%;
                            height: calc(100vw * 375/667);
                            filter: none;
                        }

                        .new-design-landing-container-sign-dg {
                            top: calc(100vw * 971/667);
                            bottom: unset;
                            min-height: auto;
                            height: calc(325/667 * 100vw);
                            background: #0C0C0C !important;
                        }

                        .new-design-landing-container-sign-body {
                            position: static;
                            min-height: auto;
                            max-width: unset;
                            width: 100vw;

                            .sign-row {
                                margin-top: 76px;
                                width: 100vw;
                                height: auto;
                                margin-left: 0;
                                margin-right: 0;
                                padding-right: 81px;

                                .sign-row-start-game {
                                    padding-left: 20px;
                                    padding-right: 20px;

                                    .sign-row-content {
                                        max-width: unset;
                                        min-height: auto;

                                        .sign-row-content-title {
                                            font-family: 'Open Sans';
                                            font-size: 30px !important;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 40.85px;
                                            color: #FFC900;
                                        }

                                        .sign-row-content-description {
                                            margin-top: 12px;
                                            margin-bottom: 12px;
                                            font-family: 'Open Sans';
                                            font-size: 20px !important;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 27.24px;
                                            color: #fff;
                                        }

                                        .sign-row-content-button {
                                            color: #000 !important;
                                            font-family: 'Open Sans';
                                            font-size: 17px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: 23.15px;
                                            text-transform: uppercase;
                                            padding: 12px 18px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            border-radius: 99px;
                                            background: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%) !important;
                                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset !important;

                                            &::before {
                                                margin-right: 15px;
                                            }

                                            &::after {
                                                margin-left: 15px;
                                            }
                                        }
                                    }
                                }

                                .sign-row-start-game+div {
                                    padding-left: 20px;
                                    padding-right: 0;

                                    .sign-row-slide {
                                        margin-top: 0;
                                        min-height: auto;
                                        min-width: auto;
                                        max-width: unset;

                                        .splide {
                                            .splide__arrows {
                                                display: none;
                                            }

                                            .splide__track {
                                                .splide__list {
                                                    li {
                                                        img {
                                                            padding-left: 0;
                                                            padding-right: 0;
                                                        }

                                                        .sign-row-slide-content {
                                                            top: 18px;
                                                            left: 13px;

                                                            .sign-row-slide-content-title {
                                                                color: #000;
                                                                font-family: 'Open Sans';
                                                                font-size: 20px;
                                                                font-style: normal;
                                                                font-weight: 600;
                                                                line-height: 27.24px;
                                                                max-width: 200px;
                                                                margin-bottom: 11px;
                                                            }

                                                            .sign-row-slide-content-description {
                                                                color: #0C0C0C;
                                                                font-family: 'Open Sans';
                                                                font-size: 14px;
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                line-height: 160%;
                                                                max-width: 185px;
                                                            }

                                                            .sign-row-slide-content-button {
                                                                button {
                                                                    color: #FFF;
                                                                    text-align: center;
                                                                    font-family: 'Open Sans';
                                                                    font-size: 17px;
                                                                    font-style: normal;
                                                                    font-weight: 700;
                                                                    line-height: 23.15px;
                                                                    text-transform: uppercase;
                                                                    padding: 12px 18px;
                                                                    border-radius: 99px;
                                                                    border: 1px solid #0C0C0C;
                                                                    background: linear-gradient(180deg, rgba(255, 0, 0, 0.80) 0%, rgba(132, 0, 0, 0.80) 100%);
                                                                }
                                                            }

                                                            .sign-row-slide-content-footer {
                                                                margin-top: 7px !important;
                                                                color: #0C0C0C;
                                                                font-family: 'Open Sans';
                                                                font-size: 12px;
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                line-height: 160%;
                                                                max-width: 210px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .splide__pagination {
                                                bottom: -11px;
                                                padding: 4px 8px;
                                                column-gap: 10px;

                                                li {
                                                    button {
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .sign-row-banner {
                                z-index: 99;
                                margin-top: 60px;
                                height: auto;

                                .sign-row-banner-slide {
                                    min-height: auto;
                                    margin-top: 0;
                                    padding-left: 0;
                                    padding-right: 0;

                                    .splide {
                                        .splide__arrows {
                                            display: none;
                                        }

                                        .splide__track {
                                            overflow: visible;

                                            .splide__list {
                                                column-gap: 20px;

                                                li {
                                                    margin-right: 0 !important;

                                                    .home-game-slide {
                                                        min-height: auto;
                                                        box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.70);

                                                        img {
                                                            width: 100%;
                                                            height: auto;
                                                            min-height: auto;
                                                            border-radius: 0px 12px 12px 12px;
                                                        }

                                                        .splide-img-dg {
                                                            border-radius: 0px 12px 12px 12px;
                                                            border: 8px solid rgba(255, 255, 255, 0.10);
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .splide__pagination {
                                            bottom: -47px;
                                            padding: 4px 7px;
                                            column-gap: 10px;

                                            li {
                                                button {
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .new-design-landing-container-top {
                        min-height: auto;

                        .new-design-landing-container-top-bg {
                            display: none;
                        }

                        .new-design-landing-container-top-dg {
                            display: none;
                        }

                        .new-design-landing-container-top-bg-table {
                            display: none;
                        }

                        .new-design-landing-container-top-list {
                            margin-top: 94px;
                            z-index: 99;
                            max-width: unset;

                            &>div {
                                padding-left: 20px !important;
                                padding-right: 81px !important;

                                .layout-body {
                                    border-radius: 17px;
                                    height: auto;

                                    &::before {
                                        display: none;
                                    }

                                    &::after {
                                        display: none;
                                    }

                                    .layout-body-list-header {
                                        z-index: 100;
                                        height: auto;
                                        top: -16px;

                                        .layout-body-list-header-title {
                                            color: #000;
                                            font-size: 16px;
                                            font-family: 'Open Sans';
                                            font-weight: 600;
                                            line-height: 21.79px;
                                            text-align: center;
                                            background-color: #fff;
                                            border-radius: 88px;
                                            padding: 5px 9px;

                                            &::before {
                                                top: -12px;
                                                left: -65px;
                                            }

                                            &::after {
                                                top: -12px;
                                                right: -65px;
                                            }
                                        }

                                    }

                                    .layout-body-top {
                                        min-height: auto;
                                        border-radius: unset;
                                        height: auto;

                                        .layout-subheader {
                                            padding-top: 30px !important;
                                            margin-left: 0 !important;
                                            margin-right: 0 !important;
                                            text-align: unset;
                                            margin-bottom: 0;
                                            border-bottom: 0;

                                            .layout-subheader-mobile {
                                                margin-bottom: 0;
                                                padding-left: 17px;
                                                padding-right: 17px;

                                                .layout-subheader-filterType {
                                                    width: 100% !important;
                                                    margin-bottom: 16px !important;
                                                    float: unset;
                                                    display: block;

                                                    .custom-dropdown {
                                                        .custom-dropdown-toggle {
                                                            color: #FFF;
                                                            font-size: 12px;
                                                            font-family: 'Open Sans';
                                                            font-weight: 400;
                                                            line-height: 16.34px;
                                                            border-radius: 25px;
                                                            border: 1px solid rgba(255, 255, 255, 0.20) !important;
                                                            height: auto;
                                                            text-align: unset;
                                                            padding: 11px 17px;
                                                            min-width: auto;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;

                                                            &::after {
                                                                margin-top: 0;
                                                                border-top: 5px solid;
                                                                border-right: 6px solid transparent;
                                                                border-left: 6px solid transparent;
                                                            }
                                                        }

                                                        .custom-dropdown-menu {
                                                            background: rgba($color: #000000, $alpha: 0.95);
                                                            width: 100%
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .layout-body-main {
                                        box-shadow: none;
                                        height: auto;
                                        min-height: auto;

                                        .landing-top-table-scrollarea-body {
                                            max-height: unset;
                                            overflow: visible;

                                            .landing-top-scrollarea-body {
                                                margin-bottom: 20px;
                                                padding-left: 17px;
                                                padding-right: 17px;

                                                .landing-top-list-header {
                                                    margin-top: 0;
                                                    margin-bottom: 3px;
                                                    flex-wrap: nowrap !important;
                                                    white-space: nowrap;
                                                    padding-left: 0;
                                                    padding-right: 0;

                                                    &>.landing-top-list-header-position,
                                                    &>.landing-top-list-header-column-rating,
                                                    &>.landing-top-list-header-player,
                                                    &>.landing-top-list-header-level,
                                                    &>.landing-top-list-header-balance,
                                                    &>.landing-top-list-header-points,
                                                    &>.landing-top-list-header-rounds {
                                                        display: none !important;
                                                    }

                                                    &>.landing-top-list-header-mobile {
                                                        display: flex !important;
                                                        padding-left: calc(100vw * 70/667);

                                                        &>div {
                                                            color: rgba($color: #FFF, $alpha: 0.5);
                                                            font-family: 'Futura PT';
                                                            font-size: 12px;
                                                            font-style: normal;
                                                            font-weight: 450;
                                                            line-height: 15.38px;
                                                        }

                                                        .landing-top-list-header-column-rating-md {
                                                            width: calc(62/667 * 100vw) !important;
                                                        }

                                                        .landing-top-list-header-column-balance-md {
                                                            width: calc(80/667 * 100vw) !important;
                                                        }

                                                        .landing-top-list-header-column-points-md {
                                                            width: calc(55/667 * 100vw) !important;
                                                        }

                                                        .landing-top-list-header-column-rounds-md {
                                                            width: calc(60/667 * 100vw) !important;
                                                        }

                                                    }
                                                }

                                                .menu-table-row-wrapper {
                                                    padding: 0 !important;
                                                    margin-bottom: 1px;

                                                    .menu-table-row-inner-wrapper {
                                                        padding: 4px 2px 4px 3px;
                                                        box-shadow: none !important;

                                                        .top-table-row {
                                                            .landing-top-list-body-position {
                                                                min-width: calc(26/667 * 100vw);
                                                                width: calc(26/667 * 100vw);
                                                                color: #FFF;
                                                                font-family: 'Futura PT';
                                                                font-size: 11px;
                                                                font-style: normal;
                                                                font-weight: 450;
                                                                line-height: 14.1px;

                                                                .layout-divider {
                                                                    display: none;
                                                                }
                                                            }

                                                            .landing-top-list-body-player {
                                                                width: auto;
                                                                min-width: auto;

                                                                .top-table-row-player {
                                                                    max-width: unset;
                                                                    min-height: auto;

                                                                    .top-table-row-player-image-frame {
                                                                        margin-right: 5px !important;
                                                                        width: 36px;
                                                                        height: 36px;
                                                                        min-width: auto;
                                                                        min-height: auto;

                                                                        img {
                                                                            width: 36px;
                                                                            height: 36px;
                                                                            min-width: auto;
                                                                            min-height: auto;
                                                                        }
                                                                    }

                                                                    &>.top-table-row-text {
                                                                        display: none;
                                                                    }

                                                                    .top-table-row-mobile-text-group {
                                                                        width: auto;
                                                                        display: flex;
                                                                        flex-direction: column;

                                                                        .top-table-row-mobile-first-line {
                                                                            display: flex;
                                                                            align-self: flex-start;
                                                                            align-items: center;
                                                                            column-gap: 8px;

                                                                            .top-table-row-text {
                                                                                color: #fff;
                                                                                font-family: 'Open Sans';
                                                                                font-size: 13px;
                                                                                font-weight: 400;
                                                                                line-height: 160%;
                                                                            }

                                                                            .my-info-ratings-step-wrapper {
                                                                                width: auto;
                                                                                height: auto;

                                                                                .my-info-ratings-step-active {
                                                                                    color: #FFF;
                                                                                    font-family: 'Open Sans';
                                                                                    font-size: 12px;
                                                                                    font-style: normal;
                                                                                    font-weight: 700;
                                                                                    line-height: 160%;
                                                                                    text-transform: uppercase;
                                                                                    padding: 0 6px;
                                                                                    border-radius: 4px;
                                                                                }
                                                                            }
                                                                        }

                                                                        .top-table-row-mobile-second-line-landing {
                                                                            width: auto;
                                                                            display: flex;

                                                                            .menu-table-row-player-icon-wrapper-landing:first-of-type {
                                                                                width: calc(62/667 * 100vw);
                                                                                display: flex;
                                                                                align-items: center;

                                                                                img {
                                                                                    width: 15px;
                                                                                    object-fit: contain;
                                                                                    height: auto;
                                                                                    padding-right: 3px;
                                                                                    padding-left: 0;
                                                                                }

                                                                                .menu-table-row-player-icon-text {
                                                                                    font-family: 'Open Sans';
                                                                                    font-size: 13px;
                                                                                    font-style: normal;
                                                                                    font-weight: 600;
                                                                                    line-height: 160%;
                                                                                    color: #fff;
                                                                                    padding: 0;
                                                                                }
                                                                            }

                                                                            .menu-table-row-player-icon-wrapper-landing:nth-of-type(2) {
                                                                                width: calc(80/667 * 100vw);
                                                                                display: flex;
                                                                                align-items: center;

                                                                                img {
                                                                                    width: 14.7px;
                                                                                    object-fit: contain;
                                                                                    height: auto;
                                                                                    padding-left: 0;
                                                                                    padding-right: 1.66px;
                                                                                }

                                                                                .menu-table-row-player-icon-text {
                                                                                    font-family: 'Open Sans';
                                                                                    font-size: 13px;
                                                                                    font-style: normal;
                                                                                    font-weight: 600;
                                                                                    line-height: 160%;
                                                                                    padding: 0;
                                                                                    color: #fff;
                                                                                }
                                                                            }

                                                                            .landing-top-list-body-column-points-md {
                                                                                width: calc(55/667 * 100vw);
                                                                                font-family: 'Open Sans';
                                                                                font-size: 13px;
                                                                                font-style: normal;
                                                                                font-weight: 600;
                                                                                line-height: 160%;
                                                                                color: #fff;
                                                                            }

                                                                            .landing-top-list-body-column-rounds-md {
                                                                                width: calc(60/667 * 100vw);
                                                                                font-family: 'Open Sans';
                                                                                font-size: 13px;
                                                                                font-style: normal;
                                                                                font-weight: 600;
                                                                                line-height: 160%;
                                                                                color: #fff;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            .landing-top-list-body-level,
                                                            .landing-top-list-body-column-rating,
                                                            .landing-top-list-body-column-balance,
                                                            .landing-top-list-body-column-points,
                                                            .landing-top-list-body-column-rounds,
                                                            .landing-top-list-body-column-btn {
                                                                display: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .menu-table-row-filler-out {
                                            display: none;
                                        }
                                    }
                                }

                            }
                        }

                        .new-design-landing-container-top-footer {
                            display: none;
                        }
                    }

                    .new-design-landing-container-blog {
                        background-color: #0C0C0C !important;
                        padding-bottom: 10px;

                        .new-design-landing-container-blog-list {
                            width: 100%;
                            max-width: unset;
                            margin-top: 13px;

                            &>div {
                                padding-left: 20px;
                                padding-right: 20px;

                                .new-design-landing-container-blog-list-title {
                                    color: #FFF;
                                    font-family: 'Open Sans';
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 140%;
                                    margin-bottom: 22px !important;
                                }

                                .new-design-landing-container-blog-list-body {
                                    .splide {
                                        margin-top: 0;

                                        .splide__track {
                                            .splide__list {
                                                min-height: auto;
                                                width: 100%;
                                                column-gap: 18px;

                                                li {
                                                    .slide-img-section {
                                                        width: 100% !important;
                                                        min-height: auto;

                                                        img {
                                                            height: auto;
                                                            min-height: auto;
                                                            border-radius: 0px 12px 12px 12px;
                                                            object-fit: contain;
                                                        }

                                                        .splide-img-dg {
                                                            border-radius: 0px 12px 12px 12px;
                                                            border: 6px solid rgba(255, 255, 255, 0.1);
                                                        }
                                                    }

                                                    .splide-slide-content {
                                                        position: static;
                                                        max-width: unset;

                                                        .splide-slide-content-title {
                                                            font-family: 'Open Sans';
                                                            font-size: 16px;
                                                            font-style: normal;
                                                            font-weight: 600;
                                                            line-height: 21.79px;
                                                            color: #fff;
                                                            padding-top: 6px;
                                                            padding-bottom: 6px;
                                                            margin-bottom: 0 !important;
                                                        }

                                                        .splide-slide-content-description {
                                                            font-family: 'Open Sans';
                                                            font-size: 14px;
                                                            font-style: normal;
                                                            font-weight: 400;
                                                            line-height: 160%;
                                                            color: #fff;
                                                            height: 130px;
                                                            overflow: hidden;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .new-design-landing-container-mobile {
                        background: none;
                        height: calc(100vw * 331/667);
                        overflow: hidden;

                        .new-design-landing-container-mobile-bg {
                            height: calc(100vw * 331/667);
                            min-height: auto !important;
                        }

                        .new-design-landing-container-mobile-bd {
                            background: linear-gradient(180deg, #0a0a0a 0%, rgba(0, 0, 0, 0) 100%) !important;
                        }

                        .new-design-landing-container-mobile-list {
                            .new-design-landing-container-mobile-list-body {
                                width: 100%;
                                height: auto;
                                max-width: unset;

                                .new-design-landing-container-mobile-list-body-row {
                                    margin-left: 0;
                                    margin-right: 0;

                                    .content-section {
                                        padding-left: 30px;
                                        padding-right: 0;

                                        .new-design-landing-container-mobile-list-body-row-content {
                                            max-width: unset;

                                            .title {
                                                color: #FFC900;
                                                font-family: 'Open Sans';
                                                font-size: 24px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 32.68px;
                                                margin-bottom: 0 !important;
                                            }

                                            .description {
                                                color: #FFF !important;
                                                font-family: 'Open Sans';
                                                font-size: 20px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 27.24px;
                                                margin-bottom: 24px !important;
                                            }

                                            .footer {
                                                .header {
                                                    font-family: 'Open Sans';
                                                    font-size: 15px;
                                                    font-style: normal;
                                                    font-weight: 600;
                                                    line-height: 20.43px;
                                                    color: #FFC900;
                                                    margin-bottom: 7.25px !important;
                                                }

                                                .bottom {
                                                    column-gap: 7px;

                                                    a:first-of-type {
                                                        img {
                                                            object-fit: contain;
                                                            height: 30px;
                                                            margin-right: 0 !important;
                                                            width: auto;
                                                        }
                                                    }

                                                    a:nth-of-type(2) {
                                                        img {
                                                            width: auto;
                                                            object-fit: contain;
                                                            height: 30px;
                                                            margin-right: 0 !important;

                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .adv-section {
                                        margin-top: 72px;

                                        .new-design-landing-container-mobile-list-body-row-adv {
                                            img {
                                                width: 90% !important;
                                                object-fit: contain;
                                                rotate: -45deg;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .new-design-landing-container-footer {
                        // display: none !important;
                    }
                }
            }
        }
    }
}
