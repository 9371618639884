
.root-class-design20 {

  .select-type {
    position: relative;
    top: 15%;
    left: 27%;
    width: 46%;
    z-index: 99;
    max-height: 450px;

    padding-bottom: 50px;
    pointer-events: none;

    &-header {
      pointer-events: all;
      position: relative;
      top: 10px;
      font-size: var(--headerFontSize);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      & img {
        position: relative;
        top: -8px;
        right: -37px;
        cursor: pointer;
      }

      & .modal-title {
        width: auto;
        background: $modalTitleBackground;
        color: $modalTitleColor;
        border-radius: 50px;
        border: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 500;
        padding: 2px 12px;
        cursor: default;

        & .default-modal-title {
          position: relative;

          &:before {
            position: absolute;
            left: -70px;
            top: -17px;
            content: ' ';
            background-image: url('../../../../../images/redesign/components/modal/modal_stars.png');
            background-size: 65px 65px;
            height: 65px;
            width: 65px;
            pointer-events: none;
          }

          &:after {
            position: absolute;
            right: -70px;
            top: -17px;
            content: ' ';
            background-image: url('../../../../../images/redesign/components/modal/modal_stars.png');
            background-size: 65px 65px;
            height: 65px;
            width: 65px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            pointer-events: none;
          }
        }
      }
    }

    &-body {
      pointer-events: all;
      padding-top: 45px;
      background: #000;
      color: #FFF;
      opacity: 1;
      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
      border-radius: 17px 17px 17px 17px;
      border: 0;
      border-bottom: 0;
      z-index: 40;
    }

    .modal-backdrop {
      display: none;
    }

    .modal-dialog {
      top: 15%;
    }

    &-text {
      text-align: center;
      font-size: var(--defaultFontSize);
      color: $primaryTextColour;
    }

    &-button {
      // width: 50%;
      // height: 4vmin;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;
      font-size: var(--defaultFontSize);
    }

    &-button::before,
    &-button::after {
      margin-bottom: 0px !important;
    }
  }
}
