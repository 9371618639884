.previous-round {
  position: absolute;
  bottom: 600px;
  right: 20px;

  &-row {
    height: 75px;
  }

  &-image {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &-points {
    text-align: center;
    font-size: var(--defaultFontSize);
    cursor: default;

    p {
      margin-bottom: 2px;
    }
  }

  &-header {
    text-align: center;
    text-transform: uppercase;
  //  border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: var(--headerFontSize);
    // font-size: 28px;
    line-height: 38px;

    &-image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    &-text {
      display: inline-block;
    }
  }

  &-modal {
    height: 450px;
    top: 15px !important;

  //  height: 470px;
  //  top: 0px !important;
  //  margin-top: 20px;

    & .modal-content {
      border-radius: 25px;
    }

    &-header {
      padding-left: 0px;
      padding-right: 0px;
      border-bottom: 1px solid #fff!important;

      &-close {
        width: 21px;
        height: 21px;
        color: #fed65f;
        cursor: pointer;
        margin-top: 5px;
        margin-right: 10px;
      }
    }

    &-body {
      height: 570px;
      padding: 0px;
    }

    &-footer {
    //  padding: 10px;

      &-button {
        width: 20%;
        margin: auto;
        background-color: #2c5955;
        border-radius: 15px;
        height: 30px;
        font-size: var(--defaultFontSize);
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        border: 1px solid #fff;
        -webkit-box-shadow: 5px 5px 0px 0px #380000;
        -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 5px 5px 0px 0px #380000;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &:hover {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }

  &-player-name {
  //  position: absolute;
  //  width: 100%;

    text-align: center;
    text-transform: uppercase;
    font-size: var(--largeFontSize);
    font-weight: 600;
    cursor: default;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  //  display: inline-block;
  }

  &-player-cards {
    text-align: center;
    font-size: var(--largeFontSize);
    font-weight: 600;
    cursor: default;
  //  display: inline-block;
  }

  &-player-tricks {
    text-align: center;
    font-size: var(--largeFontSize);
    font-weight: 600;
    cursor: default;
  //  display: inline-block;
  }

  &-player1 {
    border-bottom: 1px solid #fff;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &-player2 {
    border-bottom: 1px solid #fff;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &-player3 {
    border-bottom: 1px solid #fff;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &-player4 {
    border-bottom: 1px solid #fff;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &-tableCards {
    border-bottom: 1px solid #fff;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &-played-tricks {
    padding-right: 15px;
  }

  &-taken-cards {
    float: right;
    position: relative;
    width: 55px;
    height: 75px;
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
  }

  &-burried-cards {
    float: right;
    position: relative;
    width: 17px;
    height: 75px;
    margin-left: 0px;
    margin-right: 12px;
    display: inline-block;
  }

  &-taken-card {
    width: 27px;
    height: 75px;
    background-color: transparent;
    border: none;
    transition: 0.3s ease;
    cursor: default;
    position: absolute;
    z-index: 9;

  //  background-size: 50px 75px;
  //  background-position: top center;
    background-repeat: no-repeat;

    background-size: cover;

    &-0 {
      left: 0px;
    }
    &-1 {
      left: 15px;
    }
    &-2 {
      left: 30px;
    }
  }

  &-cards {
    position: relative;
    width: 35%;
    height: 75px;
    margin-left: 15px;
  //  margin-right: auto;
    display: block;
  }

  &-table-cards {
    position: relative;
    width: 10%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    // transform: translate(-50%, 0);
    // left: 50%;
  }

  &-card {
    width: 50px;
    height: 75px;
    background-color: transparent;
    border: none;
    transition: 0.3s ease;
    cursor: default;
    position: absolute;
    z-index: 9;

    background-size: 50px 75px;
    background-position: top center;
    background-repeat: no-repeat;

    &-0 {
      left: 0px;
    }
    &-1 {
      left: 20px;
    }
    &-2 {
      left: 40px;
    }
    &-3 {
      left: 60px;
    }
    &-4 {
      left: 80px;
    }
    &-5 {
      left: 100px;
    }
    &-6 {
      left: 120px;
    }
    &-7 {
      left: 140px;
    }

    &-♠︎-9 {
    /*  @include card-2x(
        '../../images/cards-white-fill/♣︎-K',
        'png',
        50px,
        75px,
        top center,
        no-repeat
      ); */
      background-image: url('../../../images/cards-white-fill/♠︎-9.png');
    }
    &-♠︎-10 {
      background-image: url('../../../images/cards-white-fill/♠︎-10.png');
    }
    &-♠︎-A {
      background-image: url('../../../images/cards-white-fill/♠︎-A.png');
    }
    &-♠︎-J {
      background-image: url('../../../images/cards-white-fill/♠︎-J.png');
    }
    &-♠︎-Q {
      background-image: url('../../../images/cards-white-fill/♠︎-Q.png');
    }
    &-♠︎-K {
      background-image: url('../../../images/cards-white-fill/♠︎-K.png');
    }

    &-♣︎-9 {
      background-image: url('../../../images/cards-white-fill/♣︎-9.png');
    }
    &-♣︎-10 {
      background-image: url('../../../images/cards-white-fill/♣︎-10.png');
    }
    &-♣︎-A {
      background-image: url('../../../images/cards-white-fill/♣︎-A.png');
    }
    &-♣︎-J {
      background-image: url('../../../images/cards-white-fill/♣︎-J.png');
    }
    &-♣︎-Q {
      background-image: url('../../../images/cards-white-fill/♣︎-Q.png');
    }
    &-♣︎-K {
      background-image: url('../../../images/cards-white-fill/♣︎-K.png');
    }

    &-♥-9 {
      background-image: url('../../../images/cards-white-fill/♥-9.png');
    }
    &-♥-10 {
      background-image: url('../../../images/cards-white-fill/♥-10.png');
    }
    &-♥-A {
      background-image: url('../../../images/cards-white-fill/♥-A.png');
    }
    &-♥-J {
      background-image: url('../../../images/cards-white-fill/♥-J.png');
    }
    &-♥-Q {
      background-image: url('../../../images/cards-white-fill/♥-Q.png');
    }
    &-♥-K {
      background-image: url('../../../images/cards-white-fill/♥-K.png');
    }

    &-♦︎-7 {
      background-image: url('../../../images/cards-white-fill/♦︎-7.png');
    }
    &-♦︎-8 {
      background-image: url('../../../images/cards-white-fill/♦︎-8.png');
    }
    &-♦︎-9 {
      background-image: url('../../../images/cards-white-fill/♦︎-9.png');
    }
    &-♦︎-10 {
      background-image: url('../../../images/cards-white-fill/♦︎-10.png');
    }
    &-♦︎-A {
      background-image: url('../../../images/cards-white-fill/♦︎-A.png');
    }
    &-♦︎-J {
      background-image: url('../../../images/cards-white-fill/♦︎-J.png');
    }
    &-♦︎-Q {
      background-image: url('../../../images/cards-white-fill/♦︎-Q.png');
    }
    &-♦︎-K {
      background-image: url('../../../images/cards-white-fill/♦︎-K.png');
    }
  }
}

@media (max-height: 800px) {
  .previous-round {
    bottom: 465px;
  }
}