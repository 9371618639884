$mobileLandScapeWidth: 1280px;

.root-class-design20 {

  .quit-round-icon {
    position: absolute;
    top: -18.2vh;
    left: -13vw;
    background: url('../../../../../../images/redesign/game/Give up.png');
    background-size: 100%;
    background-repeat: no-repeat;

    width: 10vmin;
    height: 10vmin;
    cursor: pointer;
    pointer-events: all;

    &.disabled {
      filter: grayscale(1);
      pointer-events: none;
    }
  }

  @media screen and (orientation : portrait) {
    .quit-round-icon {
      left: 5px;
      top: 60vh;
      position: fixed;
    }
  }

  @media screen and (max-width: $mobileLandScapeWidth) and (orientation: landscape) {
    .quit-round-icon {
      left: 33%;
      top: unset;
      bottom: calc(100px + 8vmin);
      position: fixed;
    }
  }

  .last-round-icon {
    pointer-events: none;
    position: absolute;
    top: -18.2vh;
    right: -12.3vw;
    background: url('../../../../../../images/redesign/game/Call bell.png');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 10vmin;
    height: 10vmin;

    &-click-area {
      pointer-events: all;
      margin: 28% 48% 30% 8%;
      height: 42%;
      border-radius: 41% 41% 10% 10%;
    }

    &.disabled {
      filter: grayscale(1);
      //  pointer-events: none;
    }
  }

  .hurry-icon {
    pointer-events: none;
    position: absolute;
    top: -15.7vh;
    right: -15.8vw;
    background: url('../../../../../../images/redesign/game/Hurry up clock.png');
    background-size: 100%;
    background-repeat: no-repeat;

    width: 8.5vmin;
    height: 8.5vmin;

    &-click-area {
      pointer-events: all;
      margin: 12% 39% 20% 4%;
      height: 68%;
      border-radius: 45%;
    }

    &.disabled {
      filter: grayscale(1) opacity(50%);
      //  pointer-events: none;
    }
  }

  /* .player-icon-gift {
    width: 90%;
    padding: 7%;
    height: 84%;
    margin: 0;
  } */

  .player-icon-gift {
    top: 63%;
    right: 4%;
    left: unset;
    border-radius: 50px;
    pointer-events: all;

    z-index: 50;
    padding: 4px;
    padding-top: 2px;
    padding-left: 6px;
    width: 5.6vmin;
    height: 5.6vmin;
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    cursor: pointer;
    //  background: linear-gradient(180deg, #333333 0%, #121212 100%);
    //  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

    &-img {
      width: 90%;
      padding: 7%;
      height: 84%;
      margin: 0;
    }
  }

  .block-icon {
    top: 36%;
    right: -4%;
    left: unset;
    border-radius: 50px;
    pointer-events: all;

    z-index: 50;
    padding: 4px;
    padding-top: 4px;
    padding-left: 6px;
    width: 5.6vmin;
    height: 5.6vmin;
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    cursor: pointer;
    //  background: linear-gradient(180deg, #333333 0%, #121212 100%);
    //  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

    &-image {
      width: auto;
      //  padding: 7%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .unblock-icon {
    top: 36%;
    right: -4%;
    left: unset;
    border-radius: 50px;
    pointer-events: all;

    z-index: 50;
    padding: 4px;
    padding-top: 4px;
    padding-left: 6px;
    width: 5.6vmin;
    height: 5.6vmin;
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    cursor: pointer;
    //  background: #D2110A;
    //  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

    &-image {
      width: auto;
      //  padding: 7%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .player-info-icon {
    top: 10%;
    right: 3%;
    left: unset;
    border-radius: 50px;
    pointer-events: all;

    z-index: 50;
    padding: 4px;
    //  padding-top: 4px;
    //  padding-left: 6px;
    width: 5.6vmin;
    height: 5.6vmin;
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    cursor: pointer;
    //  background: linear-gradient(180deg, #333333 0%, #121212 100%);
    //  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

    &-image {
      width: auto;
      //  padding: 7%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .hurry-icon {
      position: fixed;
      right: 10vw;
      bottom: 35vh;
      top: unset;
    }
  }
  @media screen and (orientation : portrait) {
    .hurry-icon {
      position: fixed;
      right: 5px;
      bottom: 35vh;
      top: unset;
    }
  }
}

.report-player-icon {
  top: 40px;
  // right: 5%;
  left: -5px;
  border-radius: 50px;
  pointer-events: all;

  z-index: 50;
  // padding: 4px;
  // padding-top: 2px;
  // padding-left: 6px;
  // width: 7.5vmin;
  // height: 7.5vmin;
  min-width: 24px;
  min-height: 24px;
  position: absolute;
  cursor: pointer;
  //  background: linear-gradient(180deg, #333333 0%, #121212 100%);
  //  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

  & img {
    width: 32px;
    // padding: 7%;
    // height: 84%;
    margin: 0;
  }
}