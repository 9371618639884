.power-rankings {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  border-radius: 15px;
  color: #fff;
  padding: 10px 5px 5px 10px;
  // height: 100%;;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-scrollarea {
    height: 450px;
    max-height: 450px;

    &-body {

    }
  }

  &-join {
    &-message {
      display: inline-block;
      font-size: var(--largeFontSize);

      &-image {
        display: inline-block;
        margin-left: 4px;
        width: 15px;
        height: 15px;
      }
    }
  }

  &-tutorial-table {
    max-width: 100%;
    table-layout: fixed;
    width: 100%;

    &-scrollarea {
      margin-top: 10px;
      height: 500px;
    }

    &-col {
      overflow: hidden;
      color: #fff;

      font-size: var(--mSmallFontSize);
      line-height: 15px;
      text-align: center;
    }

    &-row {
      height: 34px;
      max-width: 100%;
      overflow: hidden;

      &-user {
        height: 34px;
        max-width: 100%;
        overflow: hidden;
        background: rgba(247, 180, 90, 0.3);
      }
    }

    &-header {
      width: 100%;
      height: 24px;
      max-width: 100%;
      overflow: hidden;

      border-bottom: 1px solid #fff;

      font-weight: bold;
      font-size: var(--defaultFontSize);

      &-col {
        color: #FFF;
      //  border-bottom: 1px solid #fff;
        width: 15%;

        font-weight: bold;
        font-size: var(--defaultFontSize);
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
      }
    }
  }

  &-list-part {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    position: relative;
  
    & .virtual-power-rankings-list {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
  &-loading-part {
    flex: 1;
    width: 100%;
  }

  &-leaderboard {
    font-size: var(--headerFontSize);
    width: 100%;

    &-table {
      &-header {
      //  border-bottom: 1px solid #fff;
        text-align: center;
      }

      &-row {
        height: 34px;
        max-width: 100%;
        overflow: hidden;

        &-user {
          height: 34px;
          max-width: 100%;
          overflow: hidden;
          background: rgba(247, 180, 90, 0.3);
        }
      }

      &-col {
        text-align: center;
      }
    }

    &-user-image {
      width: 25px;
      height: 25px;
      margin-right: 5px;

      &-wrapper {
        display: inline-block;
      }
    }
  }

  &-user-image {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #fff;
    background-color: #847753;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    padding: 0;
    z-index: 2;
    img{
      width: 100%;
      height: 100%;
    }
  }

  &-header {
    margin-bottom: 10px;
    font-weight: 600;

    &-switch-section {
      display: flex;
      flex-direction: row;

      &-iframe {
        margin-top: 0px !important;

        &-left-section {
          display: inline-flex;
          width: 50%;
          height: 100%;
          flex-direction: column;
        }

        &-right-section {
          display: inline-flex;
          width: 50%;
          height: 100%;
          align-items: center;
        }
      }
    }

    &-image {
      display: inline-block;
      margin-right: 10px;
    }

    &-text {
      text-transform: uppercase;
      font-size: var(--largeFontSize);
      color: #fff;
      margin-top: 5px;
      display: inline-block;
    }

    &-button {
      width: auto;
      margin-left: 10px;
      margin-right: auto;

      padding-left: 0;
      padding-right: 0;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &-active {
        color: #fff !important;
        background-color: #380000 !important;
        border-color: #fff !important;
        box-shadow: none !important;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        // color: #FFD34E;
        // border-color: #FFD34E;
        // background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &-tutorial {
      width: 32px;
      height: 32px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  &-body {
    width: 100%;
    // margin-top: 20px;
    display: flex;
    flex-flow: wrap;
    word-break: break-word;
    padding: 0px 30px 20px 30px;
    &-item {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-top: 10px;
      @media (max-width: 1080px) {
        width: 25%;
      }

      &-label {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #FED75E;
        color: #2D5A55;
        font-size: var(--xxLargeFontSize);
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &-photo {
        position: relative;
        width: 94%;
        height: auto;
        aspect-ratio: 1 / 1;
        
        display: flex;
        justify-content: center;

        & img {
          position: absolute;
          width: 100%;
          border-radius: 50%;
          top: 10px;
          border: 1px solid #FFFFFF;
          height: 100%;
          background-image: url('../../../../images/Game/defaultImage.png');
          background-position: center center;
        }

        & label {
          width: auto;
          height: 21px;
          border-radius: 30px;
          background-color: #fff;
          top: -5px;
          position: absolute;
          font-size: var(--largeFontSize);
          // font-size: 15px;
          color: #000;
          text-align: center;
          padding: 0 10px;
        }
      }

      &-name {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
      }

      &-score {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;

        &-zole-coins {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;

          & img {
            width: 17px;
          }
          
          & label {

          }
        }

        &-zole-points {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          
          & img {
            width: 17px;
          }

          & label {
            
          }
        }
      }
    }
  }

  &-table {
    width: 100%;

    &-header {

      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      text-align: center;
      border-bottom: 1px solid #fff;

      &-col {
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 5px;
        text-transform: capitalize;
      }
    }

    &-body {

      font-size: var(--defaultFontSize);
      // font-size: 13px;
      text-align: center;
    }

    &-button {
    //  width: 100%;
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;
      margin-bottom: 5px;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--defaultFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      white-space: nowrap;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &-col {
      //  width: 45%;
        display: inline-block;
      //  margin: auto;
        margin-left: 2.5%;
        margin-right: 2.5%;
      }
    }

    &-waiting {

    }

    &-status {
      &-text {
        display: inline-block;
      }

      &-spinner {
        display: inline-block;
        margin-left: 2px;
        width: 17px;
        height: 17px;
      }
    }
  }


  &-modal {

    & .modal-content .modal-body {
      font-size: var(--largeFontSize);
    }

    &-close {
      width: 21px;
      height: 21px;
      color: #FFD34E;
      cursor: pointer;
    }
  }

  
}

.tournament-icon {
  display: inline-block;
  padding-left: 2px;
}
.switch-wrapper > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper > input[type="checkbox"] + .switch {
  transform: translateX(5px);
}
.switch-wrapper > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper.large > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-35px);
}
.switch-wrapper > input[type="checkbox"]:disabled + .switch {
  background: #ccc;
}

.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
  border: 1px solid #fff;
}
.switch-wrapper.large {
  height: 40px;
  width: 80px;
}
.switch-wrapper > .switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper > .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 17px;
  left: 48%;
  position: relative;
  top: 1px;
  width: 17px;
  z-index: 3;
}
.switch-wrapper.large > .switch > .switch-handle {
  height: 30px;
  width: 30px;
}
.switch-label {
  float: left;
  line-height: 2.5rem;
  margin-left: 10px;
}
.switch-wrapper.large + .switch-label {
  line-height: 4rem;
  margin-left: 15px;
}
