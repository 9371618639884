$mobileSmSize: 574px;
$mobileLandScapeWidth: 991px;

@media screen and (max-width: $mobileSmSize) {
    .root-class-design20 {
        .landing-mobile-blog-header {
            display: none !important;
        }
    }
}

@media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    .root-class-design20 {
        .landing-mobile-blog-header {
            display: none !important;
        }
    }
}
