// @import "../../redesignVariables";

//$titleBackground: #FFF;
//$titleColor: #000;

//$questionTitleBackground: #D2110A;
//$questionTitleColor: #FFF;

// $bodyBackground: #000;
// $bodyColor: #FFF;

/*
$footerButtonBackground: linear-gradient(180deg, #FF7A00 0%, #FFC900 100%);
$footerButtonColor: #000;

$approveButtonBackground: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
$declineButtonBackground: rgba(0, 0, 0, 0.4);
$approveFooterButtonColor: #FFF;
$declineFooterButtonColor: #FFF; */

.root-class-design20 {

  // default modal settings
  .modal {
    overflow-y: hidden !important;
    //  border: 1px solid red;
    min-height: 300px;

    &-vertically-centered {
      display: flex !important;
      align-items: center;
      justify-content: center;
      top: unset !important;
      margin: auto;
      height: 100vh;
    }

    &-dialog {
      //  border: 1px solid red;
      //  top: 55px;
      top: 7vmin;
      @media screen and (max-height: 800px) {
        top: 1vmin;
      }
    }

    &-content {
      background: transparent;
      opacity: 1;
      box-shadow: none;
      border-radius: 0;
      border: 0;
      min-height: 310px;

      & .modal-header {
        //  border: 1px solid red;
        position: relative;
        top: 25px;
        font-size: var(--largeFontSize);

        & img {
          position: relative;
          top: -8px;
          right: -37px;
          cursor: pointer;
        }

        & .modal-title {
          //  border: 1px solid red;
          font-size: var(--largeFontSize);
          width: auto;
          background: $modalTitleBackground;
          color: $modalTitleColor;
          border-radius: 50px;
          border: 0;
          margin-left: auto;
          margin-right: auto;
          z-index: 10500;
          // padding: 2px 12px;
          cursor: default;

          & .tournament-end-modal-title {
            height: unset !important;
            max-width: 650px !important;
          }

          & .default-modal-title {
            position: relative;
            padding: 2px 12px;
            width: auto;
            height: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: var(--headerFontSize);
            font-weight: bold;

            &:before {
              position: absolute;
              left: -70px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
              pointer-events: none;
            }

            &:after {
              position: absolute;
              right: -70px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              pointer-events: none;
            }
          }

          & .default-modal-title-warning {
            position: relative;
            background: red;
            color: white;
            border-radius: 50px;
            padding: 0px;
            padding: 2px 12px;
            width: auto;
            height: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: var(--headerFontSize);
            font-weight: bold;

            &:before {
              fill: black;
              position: absolute;
              left: -70px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars_red.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
            }

            &:after {
              fill: black;
              position: absolute;
              right: -70px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars_red.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            }
          }

          /*  & .question-modal-title {
            position: relative;

            background: $modalTitleBackground;
            color: $modalTitleColor;

            &:before {
              position: absolute;
              left: -70px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
            }

            &:after {
              position: absolute;
              right: -70px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            }
          }  */
        }
      }

      & .modal-body {
        //  border: 1px solid red;
        padding-top: 45px;
        background: $modalBodyBackground;
        color: $modalBodyColor;
        opacity: 1;
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
        border-radius: 17px 17px 0 0;
        border: 0;
        //  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 0;
        z-index: 10400;
        font-size: var(--defaultFontSize);
      }

      & .new-design-alert {
        width: 100%;
        padding-bottom: 0px;
        border-radius: 17px;
      }

      & .btn {
        justify-content: center;
        align-items: center;
        display: inline-block;
        vertical-align: middle;

        height: 36px;

        background: $footerButtonBackground !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4),
          0px 4px 20px rgba(0, 0, 0, 0.25),
          inset 0px 1px 0px rgba(255, 255, 255, 0.4);
        border-radius: 99px;

        font-family: Open Sans;
        font-style: normal;
        font-weight: 900;
        font-size: var(--defaultFontSize);
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;

        padding: 3px 8px;

        min-width: 15%;
        color: black;

        &:before {
          display: inline-block;
          content: ' ';
          background-image: url('../../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          margin-right: 12px;
          margin-left: 6px;
          vertical-align: middle;
          margin-bottom: 3px;
        }

        &:after {
          display: inline-block;
          content: ' ';
          background-image: url('../../../../images/redesign/common/karavs.svg');
          background-size: 7px 7px;
          height: 7px;
          width: 7px;
          margin-right: 6px;
          margin-left: 12px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }

      & .web-close {
        display: flex;
      }

      & .mobile-detector-close {
        top: -8px !important;
        right: -5px !important;
      }

      & .mobile-close {
        display: none;
      }


      @media only screen and (max-width: 580px) {
        & .web-close {
          display: none;
        }

        & .mobile-close {
          display: flex;
          width: 56px;
          height: 56px;
          position: absolute;
          bottom: -76px;
          left: calc(100%/2 - 28px);
        }
      }

      & .default-modal-body {
        &-noFooter {
          border-bottom: 0;

          & .modal-footer {
            margin-top: 5px;
          }
        }
        &-disableFlex {
          flex: none !important;
        }
      }

      & .modal-large-text {
        font-size: var(--mLargeFontSize) !important;
      }
      /*  & .question-modal-body {

        &-noFooter {
          border-bottom: 0;

          & .modal-footer {
            margin-top: 5px;
          }
        }
      }  */

      & .modal-footer {
        background: #000;
        opacity: 1;
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
        border-radius: 0 0 17px 17px;
        border: 0;
        // z-index: 500;
        z-index: 9999;
        border-top: 1px solid rgba(255, 255, 255, 0.2);

        // default modal footer button
        & .btn {
          justify-content: center;
          align-items: center;
          display: inline-block;
          vertical-align: middle;

          height: auto;
          width: auto;
          background: $footerButtonBackground;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4),
            0px 4px 20px rgba(0, 0, 0, 0.25),
            inset 0px 1px 0px rgba(255, 255, 255, 0.4);
          border-radius: 99px;

          color: $footerButtonColor;

          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: var(--defaultFontSize);
          line-height: 100%;
          text-align: center;
          text-transform: uppercase;
          border: none;
          padding: 12px 18px;

          min-width: 15%;
          margin-top: 3px !important;
          margin-bottom: 3px !important;
          margin-left: 5px !important;
          margin-right: 5px !important;


          &:before {
            display: inline-block;
            content: ' ';
            //  background-image: url('../../../../images/redesign/karavs.svg');
            //  background-image: "url('../../../../images/redesign/common/karavs.svg')";
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 15px;
            margin-left: 0px;
            vertical-align: middle;
            margin-bottom: 3px;
          }

          &:after {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 0px;
            margin-left: 15px;
            vertical-align: middle;
            margin-bottom: 3px;
          }
        }

        .delete-user-question-modal-approve-btn {
          background: linear-gradient(180deg, rgba(255, 0, 0, 0.8) 0%, rgba(132, 0, 0, 0.8) 100%);
        }

        .delete-user-question-modal-approve-btn::before,
        .delete-user-question-modal-approve-btn::after {
          background-image: url('../../../../images/redesign/common/card-icon.svg');
        }

        .delete-user-question-modal-decline-btn {
          background: rgba(0, 0, 0, 0.4);
        }


        /*  & .question-modal-approve-btn {
          justify-content: center;
          align-items: center;
          display: inline-block;
          vertical-align: middle;

          height: 36px;

          background: $approveButtonBackground;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
          border-radius: 99px;

          color: $footerButtonColor;

          font-family: Open Sans;
          font-style: normal;
          font-weight: 900;
          font-size: 17px;
          line-height: 23px;
          text-align: center;
          text-transform: uppercase;


          padding: 3px 8px;

          min-width: 15%;

          &:before {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/kreists.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 12px;
            margin-left: 6px;
            vertical-align: middle;

          }

          &:after {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/kreists.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 6px;
            margin-left: 12px;
            vertical-align: middle;
          }
        }

        & .question-modal-decline-btn {
          justify-content: center;
          align-items: center;
          display: inline-block;
          vertical-align: middle;

          height: 36px;

          background: $declineButtonBackground;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4), 0px 4px 20px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
          border-radius: 99px;

          color: $footerButtonColor;

          font-family: Open Sans;
          font-style: normal;
          font-weight: 900;
          font-size: 17px;
          line-height: 23px;
          text-align: center;
          text-transform: uppercase;


          padding: 3px 8px;

          min-width: 15%;

          &:before {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 12px;
            margin-left: 6px;
            vertical-align: middle;

          }

          &:after {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 6px;
            margin-left: 12px;
            vertical-align: middle;
          }
        }  */
      }
    }
  }

  .question-modal {
    max-width: 380px;

    &-dialog {
      top: 55px;
    }

    & .modal-content {
      background: transparent;
      opacity: 1;
      box-shadow: none;
      border-radius: 0;
      border: 0;

      & .modal-header {
        //  border: 1px solid red;
        position: relative;
        top: 25px;

        & img {
          position: relative;
          top: -8px;
          right: -37px;
          cursor: pointer;
        }

        & .modal-title {
          //  border: 1px solid red;

          width: auto;
          background: $questionTitleBackground;
          color: $questionTitleColor;
          border-radius: 50px;
          border: 0;
          margin-left: unset;
          margin-right: unset;
          z-index: 10500;
          padding: 7px 20px;
          cursor: default;
          position: relative;
          left: 50%;
          transform: translateX(-50%);

          & .question-modal-title {
            position: relative;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: var(--headerFontSize);
            line-height: 140%;

            &:before {
              position: absolute;
              left: -85px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars_red.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
            }

            &:after {
              position: absolute;
              right: -85px;
              top: -17px;
              content: ' ';
              background-image: url('../../../../images/redesign/components/modal/modal_stars_red.png');
              background-size: 65px 65px;
              height: 65px;
              width: 65px;
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            }
          }
        }
      }

      & .modal-body {
        padding-top: 45px;
        background: $modalBodyBackground;
        color: $modalBodyColor;
        opacity: 1;
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
        border-radius: 17px 17px 0 0;
        border: 0;
        border-bottom: 0;
        z-index: 400;
        font-family: 'Futura PT';
        font-style: normal;
        font-weight: 450;
        font-size: var(--largeFontSize);
        line-height: 1.26;
      }

      & .question-modal-body {
        font-size: var(--mLargeFontSize);
        text-align: center;

        &-noFooter {
          border-bottom: 0;

          & .modal-footer {
            margin-top: 5px;
          }
        }
      }

      & .modal-footer {
        background: #000;
        opacity: 1;
        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.7);
        border-radius: 0 0 17px 17px;
        border: 0;
        z-index: 10500;
        border-top: 1px solid rgba(255, 255, 255, 0.1);

        & .question-modal-approve-btn {
          justify-content: center;
          align-items: center;
          display: inline-block;
          vertical-align: middle;

          height: 36px;

          background: $approveButtonBackground;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4),
            0px 4px 20px rgba(0, 0, 0, 0.25),
            inset 0px 1px 0px rgba(255, 255, 255, 0.4);
          border-radius: 99px;

          // color: $approveButtonColor;

          font-family: Open Sans;
          font-style: normal;
          font-weight: 900;
          font-size: var(--defaultFontSize);
          line-height: 23px;
          text-align: center;
          text-transform: uppercase;

          padding: 3px 8px;

          min-width: 15%;

          &:before {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/kreists.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 12px;
            margin-left: 6px;
            vertical-align: middle;
          }

          &:after {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/kreists.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 6px;
            margin-left: 12px;
            vertical-align: middle;
          }
        }

        & .question-modal-decline-btn {
          justify-content: center;
          align-items: center;
          display: inline-block;
          vertical-align: middle;

          height: 36px;

          background: $declineButtonBackground;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4),
            0px 4px 20px rgba(0, 0, 0, 0.25),
            inset 0px 1px 0px rgba(255, 255, 255, 0.4);
          border-radius: 99px;

          // color: $declineButtonColor;

          font-family: Open Sans;
          font-style: normal;
          font-weight: 900;
          font-size: var(--defaultFontSize);
          line-height: 23px;
          text-align: center;
          text-transform: uppercase;

          padding: 3px 8px;

          min-width: 15%;

          &:before {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 12px;
            margin-left: 6px;
            vertical-align: middle;
          }

          &:after {
            display: inline-block;
            content: ' ';
            background-image: url('../../../../images/redesign/common/karavs.svg');
            background-size: 7px 7px;
            height: 7px;
            width: 7px;
            margin-right: 6px;
            margin-left: 12px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  & .filter-body-section {
    padding: 0px 0px 10px 0px;
    padding-bottom: 0;

    &-row {
      padding: 1px 0 20px 0;
      @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        padding: 0px;

        .switch-select {
          height: unset !important;
        }
      }

      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &-search-filter-col {
        padding-left: 59px;
        padding-right: 51px;
        position: relative;
        display: flex;
        align-items: center;

        &::after {
          content: ' ';
          background-image: url('../../../../images/icons/search.svg');
          background-size: 16px 16px;
          background-repeat: no-repeat;
          height: 16px;
          width: 16px;
          position: absolute;
          right: 66px;
        }

        .filter-body-section-row-search-filter {
          height: 49px;
          border-radius: 30px;
          padding-left: 26px;
          padding-right: 26px;
        }
      }

      &-label {
        margin-bottom: 8px;
        margin-top: 8px;
        font-size: var(--mLargeFontSize);
        font-family: 'Futura PT';
        font-weight: 450;
        padding-left: 61px;
        padding-right: 51px;

        @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }

      &-player-label {
        margin-top: 26px;
        margin-bottom: 9px;

        @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
          margin-top: 10px;
          margin-bottom: 1px;
        }
      }

      &-text>div>div>div {
        font-size: var(--largeFontSize);
        font-weight: 600;
        font-family: Open sans-serif;
      }

      &-text {
        padding-left: 59px;
        padding-right: 51px;

        .switch-select-option {
          padding: 9px 15px;

          &-name {
            font-weight: 700;
            font-family: 'Open Sans';
          }
        }
      }

    }

    &-row-action {
      .filter-body-section-row-label {
        margin-top: 41px;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 4px;

        @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
          margin-top: 18px;
          margin-bottom: 24px;
        }

        &:first-of-type {
          padding-left: 59px;
        }

        & .switch {
          input:checked+span.slider {
            background-color: rgba(0, 156, 16, 0.2);

            // background-color: #021204;
            &::before {
              background-color: #009C10;
            }
          }

          .switch-text {
            font-family: 'Open Sans';
            font-size: var(--headerFontSize);
            font-weight: 400;
          }
        }
      }
    }

    .filter-body-section-search-row-visible-columns {
      padding-bottom: 8px;
      @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
        padding-bottom: 5px;
      }

      .filter-body-section-row-visible-columns {
        padding-left: 59px;

        .filter-body-section-row-label-visible-column {
          padding-right: 32px;
          padding-bottom: 21px;

          @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
            padding-bottom: 14px;
          }
        }
      }

      .filter-body-section-row-label {
        margin-bottom: 26px;
        @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
          margin-bottom: 2px;
        }
      }
    }

    .modal-footer {
      border-top: none;
      margin-top: 0 !important;
      padding-bottom: 0;

      .btn {
        margin-top: 1px;
        margin-bottom: 0;
      }
    }
  }

  .modal-backdrop {
    background-color: #333;
  }

  .modal-backdrop.show {
    opacity: .8;
    filter: blur(5px);
  }


  .about-us-modal-wrapper {
    .modal-content {
      .modal-body {

        padding: 56px 0px 0px 0px;

        .about-us-modal {
          margin-left: 0;
          margin-right: 0;

          .about-us-modal-first-row {
            padding-bottom: 30px;
            margin-bottom: 0;

            p {
              margin-bottom: 0;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: var(--mLargeFontSize);
              line-height: 160%;
            }
          }

          .about-us-modal-second-row {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding: 36px 0 34px 43px;
            margin-bottom: 0;
          }

          .about-us-modal-third-row {
            margin-top: 35px;
            margin-bottom: 35px;
          }

          .about-us-modal-footer-row {
            margin-bottom: 40px;
          }
        }
      }
      @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) and (max-height: 699px) {
        .modal-body {
          padding-top: 28px !important;
          flex: none !important;

          .about-us-modal {
            .about-us-modal-first-row {
                padding-bottom: 5px;
            }

            .about-us-modal-second-row {
              padding: 5px 25px 5px 25px;
              height: 25vh;
              overflow-y: scroll;

              &-column-section {
                margin-bottom: 3px;
              }
            }
            .about-us-modal-third-row {
              margin-top: 5px;
              margin-bottom: 0px;
            }
  
            .about-us-modal-footer-row {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.room-create-modal {
  .modal-body {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    .new-game > div {
      margin-left: 46px;
      margin-right: 1rem;
    }

    .new-game > div.modal-footer {
      margin-left: 0;
      margin-right: 0;
      margin-top: 6px;
    }

    .new-game-info {
      &-image {
        width: 36px;
        height: 36px;
        top: -6px;
        position: relative;
        z-index: 9;
      }

      &-imageWrapper {
        position: absolute;
        width: 36px;
        height: 36px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
        display: inline-block;
      }

      &-hover {
        background-color: #FFF;
        border-radius: 1.5vmin 1.5vmin 1.5vmin 1.5vmin;
        width: 15vw;
        min-width: 100px;
        left: 40px;
        top: -25px;
        position: relative;
        min-height: 30px;
        box-shadow: none;
        font-size: var(--defaultFontSize);
        border: 0;
        color: #000;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        vertical-align: middle;
      }
    }
  }
}

.giftsHistory-tab-new-row-scrollarea {
  .col {
    width: 11%;
  }
}

.gameinfo-modal {
  .notification-footer-button.btn {
    width: auto !important;
  }

  & .modal-content {
    & .default-modal-body {
      & .gameinfo-modal-content {
        min-height: 200px;
        height: calc(100vh - 350px);
        overflow-y: auto;

        @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
          min-height: 100px;
          height: calc(100vh - 240px);
        }

        @media screen and (orientation : portrait) {
          min-height: 200px;
          height: calc(100vh - 400px);
        }
      }
    }
  }
}

.root-class-design20.root-class-design20-light {
  .about-us-modal {
    .about-us-modal-second-row {
      border-top: 1px solid rgba(12, 12, 12, 0.1) !important;
      border-bottom: 1px solid rgba(12, 12, 12, 0.1) !important;
    }

    .about-us-modal-link {
      a {
        color: #FF8A1A;
      }
    }
  }
}

.bonus-page-modal {
  .modal-content {
    .modal-body {
      padding-top: 50px;
      padding-left: 23px;
      padding-right: 18px;

      .money-history-table-header {
        border-bottom: none;
        height: auto;
        margin-bottom: 5px;

        .money-history-table-header-col {
          text-align: left;
          opacity: 0.5;
          padding-left: 0px;
        }

        .money-history-table-header-col-time {
          padding-left: 10px;
        }
      }

      .player-history-table-scrollarea {
        margin-top: 0;
        &-in-app-frame {
          height: 420px !important;
        }
        .player-history-table-row-table-row {
          margin: 0;
          border-radius: 44px;
          background-color: rgba($color: #fff, $alpha: 0.03);
          margin-bottom: 5px;

          .player-history-table-col {
            padding-top: 14px;
            padding-bottom: 14px;
            line-height: initial;
            padding-left: 9px;
            &-in-app-frame {
              padding-top: 5px;
              padding-bottom: 5px;
            }
            span {
              line-height: initial;
            }
          }

          .player-history-table-col-time {
            padding-left: 18px;
          }

        }
      }
    }
  }
}

.user-settings-modal {

  .language-image {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  .sound-button {
    top: 8px !important;
    left: 0px !important;
  }
  .switch-select {
    height: 30px;
  }
  .switch-select-option {
    &-dark {
      background: rgba(0,0,0,0.2) !important;
    }
    &-normal {
      background: rgba(0,0,0,0.2) !important;
    }
    &-light {
      background: rgba(0,0,0,0.25) !important;
    }
  }

  @media screen and (orientation : landscape) and (max-width: $mobileLandScapeWidth) {
    &-in-app-frame {
      width: 84vw !important;
      max-width: 84vw !important;
    }
  }

  .default-modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .user-settings {
    .user-settings-toggles {
      .user-settings-row {
        padding-left: 0;
        padding-right: 0;
      }

      .user-settings-card-play-switch-wrapper {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .user-settings-row-wrapper {
      padding-left: 0px !important;
      //padding-left: 51px;

      .user-settings-row {
        padding-left: 0;
        padding-right: 0;
      }

      .user-settings-header {
        padding-left: 0;
        padding-right: 0;
      }


    }
  }

  .modal-footer {
    background: none !important;
  }
}
