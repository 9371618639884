.chat {
//  top: 435px;
  bottom: 70px;
  left: 25px;
  width: 250px;
//  height: 50%;
  position: absolute;
  background-color: #2c5955;
  border: 1px solid #fff;
  -webkit-box-shadow: 5px 5px 0px 0px #380000;
  -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 0px 0px #380000;



  &-open {
    height: 35%;
    max-height: 258px;
    border-radius: 0;
    display: inline;
  }

  &-closed {
  //  height: 5%;
  //  border-radius: 0;

  //  display: none;

    display: none;
    box-shadow: none;
    border: 0;

    & .chat-header {
      height: 0%;
      border: 0;
    }
  }

  &-filter {

    &-name {
      padding-right: 0;
    }
  }

  &-header {
     height: 25%;
     margin: 0;
     border-bottom: 1px solid #fff;
     // border-bottom: 1px solid #fcffb6;
  //   background-image: linear-gradient(180deg,transparent,#383838);

     &-closed {
    //   cursor: pointer;
       display: none;
     }

    &-filteroption {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--largeFontSize);
      color: #fff;
      margin-left: 0px;
    }

    &-filteroption-xLarge {
      flex-direction: column;
      justify-content: flex-start;
    }

    &-image {
      padding-left: 5px;
      padding-top:2px;
    }

    &-name {
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      font-weight: 600;
      padding-right: 5px;
      padding-left: 8px;
      padding-top: 5px;
      color: #fff;
    }

    &-button {
      width: 100%;
      color: #fff;
      background-color: transparent;
      border: 0;
      padding: 0;
      cursor: default;
      font-size: var(--mLargeFontSize);
      font-weight: 400;
      margin-right: 8px;

      &-row {
        float: right;
        margin-right: -20px;
      }

      &-col {
        padding: 0;
      }

      &:hover {
        color: #fff!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:active {
        color: #fff!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:focus {
        color: #fff!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .chat {
      &-open {
        max-height: 350px;
      }
      &-header {
        height: 35%;
  
        &-filteroption {
          flex-direction: column;
        }
      }
      &-filter {
        &-name {
          max-width: 100%;
        }
      }
      &-body {
        height: 45%;
      }
    }
  }

  &-body {
     height: 60%;
     margin: 0;
    // overflow-y: scroll;
    // overflow-x: hidden;
     margin-top: 5px;
     margin-bottom: 5px;

    &-open {
      display: block;
    }

    &-closed {
      display: none;
    }

     &::-webkit-scrollbar {
       width: 10px;
     }

     &::-webkit-scrollbar-track {
       background: transparent;
       border-radius: 0px;
       border-left: 3px solid #2c5955;
       border-right: 3px solid #2c5955;
     }

     &::-webkit-scrollbar-thumb {
       background: #ddd;
       border-radius: 0px;
       border-left: 4px solid #2c5955;
       border-right: 4px solid #2c5955;
     }

     &-wrapper {
       padding: 0;
       height: 100%;
      // top: 1px;
     }

     &-scroll {

       &-area {
         height: 100%;
      //   margin-top: -5px;
         margin-right: 2px;
        // margin-bottom: 14px;
        // bottom: 3px;

        & .scrollbar {
        //  height: 25px!important;
        }
       }

       &-content {
         margin-right: 15px;
       }
     }
  }

  &-footer {
     height: 20%;
     margin: 0;
     border-top: 1px solid #fff;

     &-open {
       display: block;
       height: 10%;
     }

     &-open + .input-message-remain-number {
      font-size: 10px;
      margin-top: 4px;
      margin-left: 8px;
      color: #fff;
     }

     &-closed {
       display: none;
     }

    &-input {
      background-color: transparent;
      padding: 2px;
      color: #fed65f;
      border: 0;
      height: 100%;
      font-size: var(--smallFontSize);
      resize: none;

      &-admin {
        font-size: 12px;
      }
      
      &-wrapper {
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
      }

      &-form {
        height: 100%;
      }

      &:focus {
        background-color: transparent;
        color: #fed65f;
        border: 0;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fed65f;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #fed65f;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #fed65f;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0px;
        border-left: 3px solid #2c5955;
        border-right: 3px solid #2c5955;
      }

      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 0px;
        border-left: 4px solid #2c5955;
        border-right: 4px solid #2c5955;
      }
    }

    &-button {
      width: 100%;
      color: #007b00;
      background-color: transparent;
      border: 0;
      padding: 0;
      cursor: default;

      &:hover {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:active {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }

      &:focus {
        color: #007b00!important;
        background-color: transparent!important;
        border: 0!important;
        box-shadow: none!important;
      }
    }
  }

  &-bar {
    bottom: 25px;
  //  top: 640px;
    left: 25px;
    width: 254px;
    position: absolute;
    background-color: transparent;
    border: 0;
    margin: 0;

    .unread-messages {
      position: relative;
      display: inline;
      float: left;
      //padding-right: 4px;
    }

    .unread-messages::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #C54D4D;
      position: absolute;
      border-radius: 50%;
      top: -5px;
      right: 4px;
      z-index: 999;
    }

    .support-reply {
      position: relative;
      display: inline;
      float: left;
      //padding-right: 4px;
    }

    .support-reply::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #C54D4D;
      position: absolute;
      border-radius: 50%;
      top: -5px;
      right: -2px;
      z-index: 999;
    }

    &-right {
      float: right;
      padding-right: 0;
      padding-top: 3px;

      &-button {
        float: right;
        width: 60%;
        margin:auto;

        background-color: #2c5955;
        border-radius: 10px;
        height: 30px;

        font-style: normal;
        font-weight: 400;
        font-size: var(--largeFontSize);
        // font-size: 15px;
        line-height: 18px;

        padding-right: 4px;
        padding-left: 4px;

        text-align: center;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: 2px 2px 0px 0px #380000;
        -moz-box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.75);
        box-shadow: 2px 2px 0px 0px #380000;

        &-ru {
          font-size: var(--mSmallFontSize);
          padding-right: 2px;
          padding-left: 2px;
        }

        &-closed {
          display: none;
        }

        &:hover {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }

    &-left {
      float: left;
      padding-left: 0;
      padding-top: 5px;
      padding-right: 5px;

      &-button {
        float: left;
        height: 30px;
        padding-right: 4px;
      //  width: 40%;
        margin:auto;
        cursor: pointer;

      //  background-color: #2c5955;
      //  border-radius: 10px;
      //  height: 30px;
        font-size: var(--defaultFontSize);
        text-align: center;
      //  text-transform: uppercase;
      //  color: #fff;
      //  border: 1px solid #fff;
      //  -webkit-box-shadow: 5px 5px 0px 0px #380000;
      //  -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      //  box-shadow: 5px 5px 0px 0px #380000;

      /*  &:hover {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:focus {
          color: #fed65f;
          border-color: #fed65f;
          background-color: #2c5955;
          text-decoration: none;
        }

        &:active {
          color: #fff;
          background-color: #380000;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        } */
      }


    }
  }

  &-message {

    &-col {
  //    padding-right: 10px;
    //  padding-left: 10px;

      & a {
        background-color: transparent;
        color: #fed65f;
      }
    }

    &-user {
      background-color: transparent;
      color: #fed65f;
      float: right;
      min-width: 10%;
      max-width: 75%;
      border: 1px solid #fed65f;
      border-radius: 8px;
      margin-left: 5px;
    //  margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 2px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: var(--mSmallFontSize);
      word-wrap: break-word;

      &-time {
        background-color: transparent;
        color: #fed65f;
        float: right;
        min-width: 10%;
        max-width: 50%;
        border: 0px;
        margin-left: 5px;
      //  margin-right: 5px;
        margin-top: 0;
        margin-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: var(--xSmallFontSize);
      }
    }

    &-other {
      background-color: transparent;
      color: #fff;
      float: left;
      min-width: 10%;
      max-width: 90%;
      border: 1px solid #fff;
      border-radius: 8px;
      margin-left: 5px;
    //  margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 2px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: var(--mSmallFontSize);
      word-wrap: break-word;

      &-time {
        background-color: transparent;
        color: #fff;
        float: left;
        min-width: 10%;
        max-width: 50%;
        border: 0px;
        margin-left: 2px;
      //  margin-right: 5px;
        margin-top: 0;
        margin-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: var(--xSmallFontSize);

        &-game {
          display: flex;
        }
      }

      &-image {
        border-radius: 50px;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        margin-right: 0;
        margin-top: 5px;
      }
    }


    &-game {
      background-color: transparent;
      color: #fff;
      float: left;
      min-width: 20%;
      max-width: 95%;
      border: 1px solid #fff;
      border-radius: 8px;
      margin-left: 4px;
      margin-top: 7px;
      margin-bottom: 2px;
      padding: 2px 5px;
      font-size: var(--mSmallFontSize);

      &-time {
        background-color: transparent;
        color: #fff;
        float: left;
        min-width: 10%;
        max-width: 50%;
        border: 0px;
        margin-left: 5px;
        margin-top: 0;
        margin-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: var(--xSmallFontSize);
        white-space: nowrap;
      }

      &-label {
        color: #fed65f;
        float: left;
        min-width: 20%;
        max-width: 95%;
        border-radius: 8px;
        margin-left: 5px;
      //  margin-right: 5px;
        margin-top: 5px;
        margin-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: var(--mSmallFontSize);
      }
    }
  }
}
