.my-info {
  //  margin-top: 45px;
  //  height: 100%;
  //  padding-top: 30px;
  //  color: #fff;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.02) 100%);
  border-radius: 15px;
  color: #fff;
  padding: 10px 5px 5px 10px;
  // height: 390px;
  height: 100%;

  &-wrapper {
    height: 100%;
  }

  &-header {
    font-weight: 600;

    &-col-ru {
      padding-right: 5px;
    }

    &-image {
      display: inline-block;
      margin-right: 10px;
      width: 28px;
      height: 28px;

      &-ru {
        margin-right: 2px;
      }
    }

    &-text {
      text-transform: uppercase;
      font-size: var(--headerFontSize);
      color: #fff;
      display: inline-block;

      &-ru {
        font-size: var(--defaultFontSize);
        // font-size: 13px;
      }
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      background-color: #2c5955;
      border-radius: 15px;
      // height: 30px;
      height: auto;
      font-size: var(--smallFontSize);
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      padding: 7px 3px !important;
      word-break: break-word;
      &-ru {
        font-size: var(--xSmallFontSize);
        // font-size: 9px;
      //  padding-top: 1px;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        // color: #FFD34E;
        // border-color: #FFD34E;
        // background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &-notification::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #C54D4D;
        position: absolute;
        border-radius: 50%;
        margin-top: 3px;
        margin-left: 5px;
        top: 12px;
        right: 10px;
      }
    }
  }

  &-scores {
  //  margin-top: 5px;
  //  border: 1px solid #fff;

    &-table {
      border: 1px solid #fff;
      padding: 5px 10px;
    }

    & .text {
      font-size: var(--defaultFontSize);
      font-weight: 600;
      text-align: center;
      color: #FFF;
      padding-left: 10px;
      padding-right: 10px;
    }

    & .textData {
      font-size: var(--defaultFontSize);
      text-align: center;
      color: #FFF;
      padding-left: 10px;
      padding-right: 10px;
    }

  }

  &-tournaments {
      width: 100%;
    //  border: 1px solid #fff;

    &-table {
      border: 1px solid #fff;
    }

    &-header {
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      text-align: center;

      &-col {
      //  border-bottom: 1px solid #fff;
        margin-bottom: 3px;
        padding-bottom: 3px;
        padding-top: 5px;
      }
    }

    &-body {
      font-size: var(--defaultFontSize);
      // font-size: 13px;
      text-align: center;
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;
      margin-bottom: 5px;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--mSmallFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  & .coin {
    position: absolute;
    right: -5px;
    top: 60%;
    width: 60px;
    height: 60px;
    z-index: 5;

    &-image {
      width: 100%;
      height: 100%;
    }

    &-text {
      position: absolute;
      top: 14px;
      left: 22px;
      font-size: var(--xLargeFontSize);
      // font-size: 22px;
      font-weight: 600;
      color: #DFA11B;
    }
  }

/*
  &-player-info {
    padding: 0;

    .player {
      &-name{

        font-weight: bold;
        font-size: 20px;
      //  line-height: 14px;

        text-align: left;
        color: #fff;

        margin-top: 20px;
      }
      &-balance{
        width: 62px;
        height: 26px;


        font-size: 20px;
      //  line-height: 26px;

        color: #EDF75A;

        margin-top: 40px;
        margin-left: -5px;
      }
    }
  } */

}

.game-history {
  margin: 0;

  &-header-scroll-area {
    height: 10%;
    margin-right: 2px;
  }

  &-body-scroll-area {
    height: 90%;
    margin-right: 2px;
  }

  &-table {
    height: 352px;
    color: #FFF;
    margin-top: 30px;
    text-align: center;

    .user-game-history-table-row-odd,
    .user-game-history-table-row-even {
      &:hover {
        background: rgba(255,255,255,0.25) !important;
      }
    }
    & .scrollArea-buttonUp {
      top: 50px;
    }
    & .game-history-table-dates {

      & .rs-table {
        border-right: 0;
      }

      & .rs-table-scrollbar-horizontal {

        &:hover {
          box-shadow: none;
        }

        & .rs-table-scrollbar-handle {
          background: rgb(255, 255, 255);
          border-radius: 0px;
          height: 10px;
          margin-left: 0px;
        }
      }

      & .rs-table-scrollbar-vertical {

        &:hover {
          box-shadow: none;
        }

        & .rs-table-scrollbar-handle {
          border-radius: 0px;
          width: 0px;
          margin-top: 0px;
        }
      }
    }

    & .rs-table-row {
      border: 0;
      border-color: transparent;

      &:hover {
        font-weight: bold;
        // background: rgba(255, 255, 255, 0.3);
      //  background-color: rgba(255, 255, 255, 0.3);

        & .rs-table-cell-group {
          // background: rgba(255, 255, 255, 0.0);
        //  background-color: rgba(255, 255, 255, 0.3);
        }

        & .rs-table-cell {
          // background: rgba(255, 255, 255, 0.3);
        //  background-color: rgba(255, 255, 255, 0.3);
        }
      }

      & .rs-table-cell {
        border-right: 1px solid #FFF;
      }

      & .rs-table-cell-last {
        border-right: 0;
      }
    }

    & .rs-table-column-group-cell {
      & .rs-table-cell {
        background: rgba(255, 255, 255, 0.0)!important;
        border: 0;
      }

      & .rs-table-cell-content {
      //  border-top: 1px solid #FFF;
      }
    }

    & .rs-table-row-header {
    //  background-color: rgba(255, 255, 255, 0.2);
      background: transparent;
      border: 0;
      border-bottom: 1px solid #FFF!important;
      border-color: transparent;
      font-weight: bold;

      &:hover {
        //background: rgba(255, 255, 255, 0.0);
      //  background-color: rgba(255, 255, 255, 0.3);

        & .rs-table-cell-group {
          //background: rgba(255, 255, 255, 0.0);
        //  background-color: rgba(255, 255, 255, 0.3);
        }

        & .rs-table-cell {
          //background: rgba(255, 255, 255, 0.0);
        //  background-color: rgba(255, 255, 255, 0.3);
        }

        & .rs-table-cell-group-fixed-left {
          //background: rgba(255, 255, 255, 1);
          //color: #333;
        }
      }

      & .rs-table-cell-content {
        padding-top: 0px;
        padding-bottom: 0px;
        white-space: normal;
        text-align: center;
      //  border-right: 1px solid #FFF;
      }


    }

    .rs-table-cell-content {
      padding-top: 4px;
      padding-bottom: 8px;
    }

    & .rs-table-cell {
      background: transparent;
      border: 0;
      border-color: transparent;

    }

    & .rs-table-cell-group-fixed-left {
    //  background: transparent;
      background: rgba(255, 255, 255, 1)!important;
      color: #333;
      font-weight: bold;
    //  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    & .rs-table-column-group-header {
      border: 0;
      border-color: transparent;
    }

  //  & .rs-table-cell-group

    & .rs-table-column-group-header-content {
      padding: 0px;
      text-align: center;
    }

    &-row {
      &-test {
        background: rgba(255, 255, 255, 0.3) !important;
      }
      &-odd {
        background: rgba(255, 255, 255, 0.1);
      }

      &-even {
        background: rgba(255, 255, 255, 0.0);
      }
      &-last {
        color: transparent;
      }
    }

    & .rs-table-scrollbar-horizontal {
      bottom: 0;
      height: 10px;

      &:hover {
        box-shadow: none;
      }

      & .rs-table-scrollbar-handle {
        background: rgb(255, 255, 255);
        border-radius: 1px;
        height: 10px;
        margin-left: 0px;
      }
    }

    & .rs-table-scrollbar-vertical {
      width: 10px;
      &:hover {
        box-shadow: none;
      }

      & .rs-table-scrollbar-handle {
        background: rgb(255, 255, 255);
        border-radius: 1px;
        width: 10px;
        margin-top: 0px;
      }
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;
      margin-bottom: 5px;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--smallFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &-header {
      height: 34px;
      max-width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #fff;
      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;

      &-col {
        color: #FFF;
      //  border-bottom: 1px solid #fff;
        width: 15%;
        font-weight: bold;
        font-size: var(--defaultFontSize);
        // font-size: 13px;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;

        &-text {
          padding-top: 10px;
        }
      }


    }

    &-row {
      height: 42px;
      max-width: 100%;
      overflow: hidden;

      &-col {
        overflow: hidden;
        color: #fff;
        font-size: var(--mSmallFontSize);
        line-height: 15px;
        text-align: center;
        margin: auto;
        padding: 0;
      }
    }
  }
}


.ignored-users {
  margin: 0;

  &-table {
    height: 300px;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      margin-top: 3px;
      margin-bottom: 3px;
      padding-left: 4px;
      padding-right: 4px;

      background-color: #2c5955;
      border-radius: 15px;
      height: 34px;
      line-height: 1;
      font-size: var(--mSmallFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &-header {
      height: 34px;
      max-width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #fff;
      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;

      &-col {
        color: #FFF;
      //  border-bottom: 1px solid #fff;
        width: 15%;
        font-weight: bold;
        font-size: var(--defaultFontSize);
        // font-size: 13px;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;

        &-text {
          padding-top: 10px;
        }
      }


    }

    &-row {
      height: 42px;
      max-width: 100%;
      overflow: hidden;

      &-col {
        overflow: hidden;
        color: #fff;
        font-size: var(--mSmallFontSize);
        line-height: 15px;
        text-align: center;
        margin: auto;
      }
    }
  }

  &-mobile {
    &-header {
      width: auto;
      margin-left: 59px;
      margin-right: 71px;
      margin-top: 8px;
      font-weight: 900;
      font-size: var(--defaultFontSize);

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .rating {
        display: inline-block;
        width: 80px;
      }
      .balance {
        display: inline-block;
        width: 100px;
      }
      .date {
        display: inline-block;
        width: 138px;
      }
    }
    &-body {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      // height: 46px;
      margin-top: 8px;
      font-size: var(--defaultFontSize);

      .center-section {
        // height: 46px;
        width: calc(100% - 95px);
        display: inline-block;
        vertical-align: middle;

        .top-part {
          height: 23px;
          display: block;
          .name {
            display: inline-block;
            width: 95px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .rating {
            display: inline-block;
            vertical-align: top;
            margin-left: 3px;
          }
        }

        .bottom-part {
          height: 23px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .rating {
            display: inline-block;
            width: 80px;

          }
          .balance {
            display: inline-block;
            width: 100px;

          }
          .date {
            display: inline-block;
            width: 138px;
          }
        }
      }
      .unblock-section {
        // height: 46px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
      }
    }
  }

  &-switch-section {
    display: flex;
    flex-direction: row;

    &-iframe {
      margin-top: 0px !important;

      &-left-section {
        display: inline-flex;
        width: calc(100% - 45px);
        height: 100%;
        flex-direction: column;
      }

      &-right-section {
        display: inline-flex;
        width: 45px;
        height: 100%;
        align-items: center;
      }
    }
  }
}

.weekly-statistics {
  margin: 0;

  &-table {
    height: 300px;

    &-home {
      height: 370px;

      &-modal {
        height: calc(100% - 70px) !important;

        &-loading-spineer {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          height: 370px;
        }
      }
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 4px;
      padding-right: 4px;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--mSmallFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &-header {
      max-width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #fff;
      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;

      &-col {
        color: #FFF;
      //  border-bottom: 1px solid #fff;
        width: 15%;
        font-weight: bold;
        font-size: var(--defaultFontSize);
        // font-size: 13px;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;

        &-in-modal {
          font-size: var(--largeFontSize);
        }

        &-text {
          padding-top: 10px;
        }
      }


    }

    &-row {
      height: 42px;
      max-width: 100%;
      overflow: hidden;

      &-in-modal {
        height: auto;
        margin-top: 13px;
        margin-bottom: 13px;
      }

      &-col {
        overflow: hidden;
        color: #fff;
        font-size: var(--mSmallFontSize);
        line-height: 15px;
        text-align: center;
        margin: auto;

        &-in-modal {
          font-size: var(--defaultFontSize);
        }

        &-text-color {
          color: #FED75F;
        }
      }
    }
  }
}

.friends {
  margin: 0;
  // height: calc(100% - 139px);

  &-table {
    margin-bottom: 10px;

    &-iframe {
      // height: calc(100vh - 390px);
      min-height: 360px;
      max-height: 360px;
      overflow: auto;
    }

    &-button {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      margin-top: 5px;
      margin-bottom: 5px;

      background-color: #2c5955;
      border-radius: 15px;
      // height: 30px;
      font-size: var(--mSmallFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;
      cursor: pointer;
      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &-header {
      min-height: 34px;
      max-width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #fff;
      font-weight: bold;
      font-size: var(--defaultFontSize);
      // font-size: 13px;

      &-col {
        color: #FFF;
      //  width: 15%;
        font-weight: bold;
        font-size: var(--defaultFontSize);
        // font-size: 13px;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &-row {
      // height: 38px;
      max-width: 100%;
      overflow: hidden;

      &-col {
        overflow: hidden;
        color: #fff;
        font-size: var(--mSmallFontSize);
        line-height: 15px;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

// Achievements

.ach {
  &-table-scrollarea {
  //  padding-right: 8px;
  //  padding: 10px;
    padding-left: 6px;
    height: 335px;

    &-wrapper {
      padding-left: 0;
      padding-right: 11px;
    }
  }

  &-table-row {
    margin-right: 0;
    margin-left: -10px;
  }

  &-column {
    text-align: center;

    &-div {
      min-height: 85px;
      height: 85px;
      position: relative;
    }

    &-img {
      width: 100%;
      height: 85px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      object-fit: contain;
    }

    span {
      margin-top: 4px;
      font-style: normal;
      font-weight: bold;
      font-size: var(--defaultFontSize);
      line-height: 16px;
      display: block;
    }
  }


  &-list {
    &-wrapper {
      padding-left: 0;
      padding-right: 5px;
    }

    &-button {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      margin-bottom: 10px;
      position: relative;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
    //  min-height: 30px;
      font-size: var(--mSmallFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &-active {
        color: #fff;
        background-color: #380000!important;
        border-color: #fff;
        box-shadow: none;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

    /*  &-claim {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #C54D4D;
        position: absolute;
        border-radius: 50%;
        margin-top: 3px;
        margin-left: 5px;
      } */

      &-claim::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #C54D4D;
        position: absolute;
        border-radius: 50%;
        margin-top: 3px;
        margin-left: 5px;
      }

    }
  }

  &-collect {
    &-button {
    //  width: 50%;
      margin-bottom: 20px;
      margin-top: 5px;
    //  margin-left: 25%;
    //  margin-right:25%;

      width: 170px;
      margin-left: auto;
      margin-right: auto;
      display: block;

      padding-top: 3px;
      padding-left: 30px;
      padding-right: 30px;

      background-color: #2c5955;
      border-radius: 15px;
      height: 30px;
      font-size: var(--smallFontSize);
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border: 1px solid #fff;
      -webkit-box-shadow: 5px 5px 0px 0px #380000;
      -moz-box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.75);
      box-shadow: 5px 5px 0px 0px #380000;

      &-single {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;

        padding-left: 5px;
        padding-right: 5px;
      }

      &:hover {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:focus {
        color: #FFD34E;
        border-color: #FFD34E;
        background-color: #2c5955;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        background-color: #380000;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }


      &-coin {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-bottom: 2px;
      }

      &-text {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}

.giftsHistory-row {
  max-height: 49px;
}

.giftsHistory-new {
  position: relative;
  width: 151px;
  height: 151px;
}

.giftsHistory {
  &-new {
    position: relative;
    width: 151px;
    height: 151px;

    &-bottom {
      position: relative;
      width: 90px;
      height: 90px;
      margin-top: -37px;
      margin-left: -33px;
    }
  }
  &-wrapper {
    width: 60px;
    height: 50px;
    padding: 0;
  }

  position: relative;
  width: 60px;
  height: 50px;

  &-image {
    position: absolute;
    width: auto;
    max-width: 60px;
    max-height: 50px;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-remove {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }

  &-tooltip {
    color: #fff;
    background-color: #2c5955;

    &-from {
      text-align: center;
      font-size: var(--largeFontSize);
    }

    &-comment {
      font-size: var(--defaultFontSize);
      text-align: justify;
    }
  }

  &-disabled-button {
    opacity: 0.4;
    pointer-events: none;
  }

  &-header {
    color: #fff;
    font-size: var(--largeFontSize);
    font-weight: 600;
    text-align: left;
    margin-bottom: 5px;
  }
}

.game-logs {
  width: 100%;
  height: 100%;

  &-scrollarea {
    max-height: 60vh;
    height: 60vh;
  }
  &-table {
    width: 100%;
    border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 0 5px;
    &-header {
      & th {
        padding: 7px;
      }
      & th:first-child {
        padding-left: 10px;
      }
    }
    &-row {
      & td {
        background-color: rgba(255, 255, 255, 0.1);
        padding: 7px;
      }
      & td:first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        padding-left: 10px;
      }
      & td:last-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        padding-left: 10px;
      }
      &-info {
        & img {
          width: 18px;
          cursor: pointer;
        }
      }
    }
  }
}

.user-party-modal {
  max-height: 350px;
  font-size: var(--defaultFontSize) !important;
  & .scrollArea-buttonUp {
    right: 12px !important;
    top: 16px !important;
  }
  & .scrollArea-buttonDown {
    right: 12px !important;
    bottom: 16px !important;
  }
}

.my-info-user-party-table-scrollarea {
  height: 320px;
}

.game-logs-info {
  width: 22px;
  cursor: pointer;
}

.weekly-statistics-scrollarea {
  height: 80% !important;
}
